import { useState } from 'react'
import { styled } from '@mui/system'

import { ReactComponent as ArrowRight } from '../../../icons/arrowRight.svg'

import FormFieldError from '../../common/FormFieldError'

import { humanizeError } from '../../../utils/errorFormatter'

import Api from '../../../services/api'

import {
  FormContainer,
  NavActionButton,
  NavActionContainer,
  StyledTextField,
  LinkButton,
  FullFormSection,
  HeaderSubtextContainer,
  CharacterCounter,
} from '../../common/FormComponents'

const LoadingContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
})

const MAX_PROMPT_CHARACTERS = 7500

const SpeechTopicPromptParent = ({
  speechTopic,
  setSpeechTopic,
  setCurrentStep,
  setGenericError
}) => {
  const [promptContent, setPromptContent] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [promptError, setPromptError] = useState('')

  const validatePromptInput = () => {
    if (promptContent?.length > MAX_PROMPT_CHARACTERS) {
      setPromptError(`The maximum number of characters you can use is ${MAX_PROMPT_CHARACTERS}. Please shorten your content and try again.`)
      return false
    }

    setPromptError('')
    return true
  }

  const handlePromptChange = (value) => {
    setPromptError('')
    setPromptContent(value)
  }

  const submitPrompt = async () => {
    if (!validatePromptInput()) {
      return
    }

    // Don't run the AI system if there's no content
    if (!promptContent.trim()) {
      return setCurrentStep(2)
    }

    setIsLoading(true)
    try {
      const params = {
        signature_talk: {
          prompt: promptContent,
        },
      }
      const res = await Api.generateSignatureTalkFromPrompt(params)
      if (!res.errors) {
        setSpeechTopic({
          ...speechTopic,
          description: res.description,
          title: res.title,
          outcomes: Array.isArray(res.outcomes) ? res.outcomes : [],
        })
        setCurrentStep(2)
      } else {
        throw res.errors
      }
    } catch (error) {
      setGenericError(humanizeError(error))
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <>
      <FormContainer>
        <HeaderSubtextContainer headerSubtext={'Speech topic set up'} />
        <FullFormSection
          title="Let us import your speech topic for you"
          stepNumber="1">
          {promptError && <FormFieldError errorMsg={promptError} />}
          <StyledTextField
            required
            variant="outlined"
            fullWidth
            multiline
            value={promptContent}
            onChange={(e) => handlePromptChange(e.target.value)}
            minRows={10}
            maxRows={16}
            placeholder="Paste text and let us try and format it for you"
            disabled={isLoading}
            InputProps={{
              endAdornment: (
                <CharacterCounter
                  isError={promptContent.length > MAX_PROMPT_CHARACTERS}>
                  {promptContent.length}/{MAX_PROMPT_CHARACTERS}
                </CharacterCounter>
              ),
            }}
          />
        </FullFormSection>
      </FormContainer>
      <NavActionContainer>
        {
          <LinkButton
            variant="body2"
            onClick={() => {
              !isLoading && setCurrentStep(2)
            }}>
            I want to configure everything manually. Skip this step.
          </LinkButton>
        }
        <LoadingContainer>
          <NavActionButton
            variant="contained"
            onClick={submitPrompt}
            loading={isLoading}>
            <>
              <span className="button-text">Next Step</span>
              <ArrowRight />
            </>
          </NavActionButton>
        </LoadingContainer>
      </NavActionContainer>
    </>
  )
}

export default SpeechTopicPromptParent
