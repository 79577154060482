import { useHistory } from 'react-router-dom'
import { TableRow, Tooltip } from '@mui/material'
import { styled } from '@mui/system'

import TableCellStyled from '../common/TableCellStyled'
import TableChip from '../common/TableChip'
import {
  TableCellContent,
  TableCellPrimaryContent,
  TableCellSecondaryContent,
} from '../common/TableComponents'

import { ReactComponent as LockIcon } from '../../icons/lockIcon_16x16.svg'

import { formatDate } from '../common/helpers'

const StyledTableRow = styled(TableRow)({
  cursor: 'pointer',
})

const ProfileEventListRow = ({ event, shortlink }) => {
  const history = useHistory()

  const eventCodeToDisplay = event.lockedOfferCode
    ? event.lockedOfferCode
    : event.eventCode

  const goToEventReport = () => {
    history.push(`/s/${shortlink}/event-report/${event.shareKey}`)
  }

  const handleClick = ({ value, allowEditDetails = false }) => {
    if (allowEditDetails && !value) {
      // open event details dialog
      return null
    } else {
      goToEventReport()
    }
  }

  const renderStandardRow = () => (
    <StyledTableRow key={event.id}>
      <TableCellStyled
        align="left"
        paddingLeft={2}
        minWidth="90px"
        // NOTE: Set height explicitly in case event is missing eventCode and eventName
        height="70.25px"
        onClick={() =>
          handleClick({
            value: event.date,
            allowEditDetails: false,
          })
        }>
        {formatDate(event.date, event.utcOffset)}
      </TableCellStyled>
      <TableCellStyled
        align="left"
        displayBreakpoint="md"
        onClick={() =>
          handleClick({
            value: event.eventOrganization,
            allowEditDetails: false,
          })
        }>
        <TableCellContent>
          <TableCellPrimaryContent boldPrimary={true}>
            {event.eventOrganization}
          </TableCellPrimaryContent>
          {event.eventName && (
            <TableCellSecondaryContent>
              {event.eventName}
            </TableCellSecondaryContent>
          )}
        </TableCellContent>
      </TableCellStyled>
      <TableCellStyled
        align="left"
        onClick={() =>
          handleClick({
            value: event.talkTitle,
            allowEditDetails: false,
          })
        }>
        {event.talkTitle}
      </TableCellStyled>
      <TableCellStyled
        align="center"
        displayBreakpoint="lg"
        onClick={goToEventReport}>
        {event.rating}%
      </TableCellStyled>
      <TableCellStyled
        align="center"
        displayBreakpoint="sm"
        onClick={goToEventReport}>
        {event.responses}
      </TableCellStyled>
    </StyledTableRow>
  )

  return renderStandardRow()
}
export default ProfileEventListRow
