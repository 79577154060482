import TableHeaderSortable from '../common/TableHeaderSortable'

const CodesTableHeader = ({ order, orderBy, onRequestSort, loading }) => {
  const headCells = [
    { id: 'createdAt', label: 'Created', paddingLeft: 2 },
    { id: 'code', label: 'Code' },
    { id: 'name', label: 'Description', displayBreakpoint: 'md' },
    {
      id: 'code settings',
      label: 'Code Settings',
      align: 'center',
      displayBreakpoint: 'sm',
    },
    { id: 'actions', label: 'Actions', align: 'center' },
  ]

  return (
    <TableHeaderSortable
      headCells={headCells}
      order={order}
      orderBy={orderBy}
      onRequestSort={onRequestSort}
      loading={loading}
    />
  )
}

export default CodesTableHeader
