import { styled } from '@mui/system'
import { Skeleton, Typography } from '@mui/material'
import { Chart as ChartJS, ArcElement, Tooltip } from 'chart.js'
import { Doughnut } from 'react-chartjs-2'

import { useTheme } from '@mui/material/styles'

ChartJS.register(ArcElement, Tooltip)

const StatWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(2),
}))

const StatPercentage = styled(Typography)(({ theme }) => ({
  fontWeight: theme.fontWeights.bold,
}))

const CircleChartWrapper = styled('div')({
  width: '80px',
  height: '80px',
})

export const ValuableStatContent = ({ ratingsBreakdown, isLoading }) => {
  const theme = useTheme()
  const { positives, negatives, total } = ratingsBreakdown
  const rating = parseInt((positives / total) * 100) || 0

  const chartData = {
    labels: ['Yes', 'No'],
    datasets: [
      {
        data: [positives, negatives],
        backgroundColor: [
          theme.palette.secondary.main,
          theme.palette.neutral.extraLight,
        ],
        borderWidth: 0,
      },
    ],
  }

  const options = {
    plugins: {
      legend: {
        display: false,
      },
    },
  }

  return isLoading ? (
    <Skeleton width={200} height={30} />
  ) : (
    <>
      {rating > 0 && (
        <StatWrapper>
          <StatPercentage variant="h3">{rating}%</StatPercentage>
          <CircleChartWrapper>
            <Doughnut data={chartData} options={options} />
          </CircleChartWrapper>
        </StatWrapper>
      )}
    </>
  )
}
