import { useState, useContext } from 'react'
import { styled } from '@mui/system'
import { TextField, Typography, IconButton } from '@mui/material'
import { ClickAwayListener } from '@mui/base'

import { ReactComponent as EditIcon } from '../../icons/editIcon_16x16.svg'

import Api from '../../services/api'
import { AuthenticationContext } from '../authentication/authenticationContext'

import { ProfileSectionHeader } from './ProfileSectionHeader'

const BiographySectionContainer = styled('div')(({ theme }) => ({
  position: 'relative',
  background: theme.palette.neutral.lightest,
  padding: theme.spacing(3),
  maxWidth: '800px',
  marginBottom: theme.spacing(6),
}))

const BiographyWrapper = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(2),
}))

const PlaceHolderContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  background: theme.palette.secondary.extraLight,
  padding: '26px',
  cursor: 'pointer',
}))

const PlaceHolderHeader = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.main,
  marginBottom: theme.spacing(2),
}))

const BiographyTextWrapper = styled(Typography)(({ theme }) => ({
  maxHeight: '510px',
  overflowY: 'auto',
  whiteSpace: 'pre-line',
}))

const IconWrapper = styled('div')({
  position: 'absolute',
  top: '20px',
  right: '20px',
})

const MAX_BIOGRAPHY_LENGTH = 700
// const CHARACTERS_PER_LINE = 100
// const MAX_LINES = 11

const ProfileBiography = ({ authUser, speaker }) => {
  const { setNotification } = useContext(AuthenticationContext)
  const [isEditing, setIsEditing] = useState(false)
  const [biography, setBiography] = useState(speaker?.biography || '')

  const isProfileOwner = authUser?.id === speaker?.id && !authUser?.isAssociate

  const handleClickAway = async () => {
    const params = {
      profile: { biography },
    }

    try {
      const res = await Api.updateProfile(params)
      if (!res.errors) {
        setIsEditing(false)
      } else {
        throw res.errors
      }
    } catch (err) {
      setNotification(err)
    }
  }

  const handleTextClick = () => {
    if (isProfileOwner) {
      setIsEditing(true)
    }

    return
  }

  // const linesRemaining = () => {
  //   const newLineSplit = biography.split('\n')

  //   let numberOfLines = 0

  //   newLineSplit.forEach((line) => {
  //     numberOfLines += Math.ceil(line.length / CHARACTERS_PER_LINE)

  //     if (line.length === 0) {
  //       numberOfLines += 1
  //     }
  //   })

  //   return MAX_LINES - numberOfLines + 1
  // }

  // const lastLineLengthRemaining = () => {
  //   const newLineSplit = biography.split('\n')
  //   const lastLine = newLineSplit[newLineSplit.length - 1]

  //   return CHARACTERS_PER_LINE - lastLine.length
  // }

  // const isDeleting = (e) => {
  //   if (e.target.value.length < biography.length) {
  //     return true
  //   }
  // }

  // const handleBiographyChange = (e) => {
  //   if (
  //     linesRemaining(e) > 0 ||
  //     (linesRemaining() === 0 && lastLineLengthRemaining() >= 0) ||
  //     isDeleting(e)
  //   ) {
  //     setBiography(e.target.value)
  //   }
  // }

  let charactersRemaining =
    MAX_BIOGRAPHY_LENGTH - biography.length > 0
      ? MAX_BIOGRAPHY_LENGTH - biography.length
      : 0

  const renderPlaceholder = () => (
    <PlaceHolderContainer onClick={() => setIsEditing(true)}>
      <PlaceHolderHeader variant="h6">
        Add your bio, or more information about yourself here!
      </PlaceHolderHeader>
      <Typography variant="body2">Tap anywhere to start.</Typography>
    </PlaceHolderContainer>
  )

  const renderBiographyText = () => {
    if (!biography && isProfileOwner) {
      return renderPlaceholder()
    }
    return (
      <BiographyTextWrapper onClick={handleTextClick}>
        {biography}
      </BiographyTextWrapper>
    )
  }

  const renderBiographyTextArea = () => {
    if (isEditing) {
      return (
        <ClickAwayListener onClickAway={handleClickAway}>
          {/* TODO: Set character limit? Gets complicated with handling line breaks. */}
          <TextField
            value={biography}
            color="secondary"
            helperText={`${charactersRemaining} characters remaining`}
            // helperText={`${linesRemaining()} lines remaining`}
            fullWidth
            multiline
            minRows={8}
            maxRows={15}
            placeholder="Add your bio, or more information about you here!"
            onChange={(e) => setBiography(e.target.value)}
            // onChange={(e) => handleBiographyChange(e)}
            onBlur={handleClickAway}
            inputProps={{ maxLength: MAX_BIOGRAPHY_LENGTH }}
          />
        </ClickAwayListener>
      )
    }
    return renderBiographyText()
  }

  const renderBiographyContent = () => {
    if (isProfileOwner) {
      return renderBiographyTextArea()
    }
    return renderBiographyText()
  }

  const headerText = `About ${speaker?.name}`

  return !isProfileOwner && !speaker?.biography ? (
    <></>
  ) : (
    <BiographySectionContainer>
      <ProfileSectionHeader headerText={headerText} />
      {isProfileOwner && (
        <IconWrapper>
          <IconButton onClick={handleTextClick}>
            <EditIcon />
          </IconButton>
        </IconWrapper>
      )}
      <BiographyWrapper>{renderBiographyContent()}</BiographyWrapper>
    </BiographySectionContainer>
  )
}

export default ProfileBiography
