import ReactPlayer from 'react-player/vimeo'
import { styled } from '@mui/system'

const VideoContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
})

const VideoPlayerComponent = ({ videoId, autoplay, listId }) => {
  return (
    <VideoContainer>
      <ReactPlayer
        url={`https://vimeo.com/${videoId}`}
        width="640px"
        height="360px"
        controls={true}
      />
    </VideoContainer>
  )
}

export default VideoPlayerComponent
