import { styled } from '@mui/system'

import CloseIcon from '@mui/icons-material/Close'

// Note:
// Use this component as a close icon for MUI Dialogs
const StyledCloseIcon = styled(CloseIcon)(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(2),
  right: theme.spacing(2),
  cursor: 'pointer',
  color: theme.palette.neutral.main,
  '&:hover': {
    color: theme.palette.neutral.dark,
  },
}))

const ModalCloseIcon = ({ onClick }) => {
  return <StyledCloseIcon onClick={onClick} />
}

export default ModalCloseIcon