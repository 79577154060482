import { Avatar, Typography } from '@mui/material'
import { styled } from '@mui/system'

import SpeakerHeader from '../speakerProfile/SpeakerHeader'
import { formatDate } from '../common/helpers'
import talkadotLogo from '../../icons/tdot-social-logo.png'

const SocialShareBanner = styled('div')({
  display: 'flex',
  paddingTop: '0px',
  marginBottom: '15px',
})

const SocialShareAvatar = styled(Avatar)({
  // Note: the image width and height here is a solution to
  // prevent a small black border to show up on the left and top
  // of the image in certain occassions.
  marginRight: '10px',
  marginTop: '-40px',
  marginLeft: '-35px',
  img: {
    width: '102%',
    height: '102%',
  },
})

const SocialShareImage = styled('div')({
  // Note: using min/max width/height here to ensure borders
  // show up properly in image download
  minWidth: '844px',
  maxWidth: '844px',
  minHeight: '869px',
  maxHeight: '869px',
  position: 'relative',
  borderWidth: '72px',
  borderStyle: 'solid',
})

const InnerContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'customQuestion',
})(({ customQuestion }) => ({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  padding: '0 30px',
  paddingBottom: customQuestion ? '60px' : '0',
  paddingTop: customQuestion ? '24px' : '0',
}))

const TalkTitleWrapper = styled('div')({
  paddingLeft: '30px',
  paddingTop: '5px',
  paddingBottom: '10px',
  textAlign: 'left',
})
const TalkOrganization = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'orgFontScale',
})(({ orgFontScale }) => ({
  fontWeight: '900',
  fontSize: `${orgFontScale * 24}px`,
  minHeight: '30px',
}))
const TalkTitle = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'titleFontScale',
})(({ titleFontScale }) => ({
  fontSize: `${titleFontScale * 20}px`,
  marginTop: '-5px',
  fontWeight: '300',
  minHeight: '24px',
}))

const MAX_ORG_CHARACTER_LENGTH = 50
const MAX_ORG_FONT_SCALE = 1.25
const MAX_TITLE_CHARACTER_LENGTH = 75
const MAX_TITLE_FONT_SCALE = 1.2

const Header = ({ reportData, shareKey }) => {
  const orgRatio =
    reportData.organization?.length / MAX_ORG_CHARACTER_LENGTH > 1
      ? 1
      : reportData.organization?.length / MAX_ORG_CHARACTER_LENGTH
  const titleRatio =
    reportData.talkTitle?.length / MAX_TITLE_CHARACTER_LENGTH > 1
      ? 1
      : reportData.talkTitle?.length / MAX_TITLE_CHARACTER_LENGTH

  const orgFontScale =
    1 / orgRatio > MAX_ORG_FONT_SCALE ? MAX_ORG_FONT_SCALE : 1 / orgRatio
  const titleFontScale =
    1 / titleRatio > MAX_TITLE_FONT_SCALE
      ? MAX_TITLE_FONT_SCALE
      : 1 / titleRatio

  return (
    <div>
      <SocialShareBanner>
        <SocialShareAvatar
          sx={{ width: 168, height: 168 }}
          alt={reportData?.speaker?.name}
          src={reportData?.speaker?.avatar}
        />
        {reportData?.isUserEvent && (
          <SpeakerHeader id={null} shareKey={shareKey} socialShare={true} />
        )}
      </SocialShareBanner>
      <Typography
        component="div"
        variant="body1"
        className="audience-feedback-header">
        Audience Feedback On{' '}
        {reportData.date
          ? formatDate(reportData.date, reportData.utcOffset)
          : ''}
      </Typography>
      <TalkTitleWrapper>
        {reportData.organization && (
          <TalkOrganization
            component="div"
            variant="body1"
            noWrap={true}
            orgFontScale={orgFontScale}>
            {reportData.organization}
          </TalkOrganization>
        )}
        {reportData.talkTitle && (
          <TalkTitle
            component="div"
            variant="body1"
            noWrap={true}
            titleFontScale={titleFontScale}>
            {reportData.talkTitle}
          </TalkTitle>
        )}
      </TalkTitleWrapper>
    </div>
  )
}

const Footer = () => (
  <img className="talkadot-logo" src={talkadotLogo} alt="Talkadot!" />
)

const SocialShareTemplate = ({
  reportData,
  shareKey,
  downloadRef,
  color,
  children,
  downloadId,
}) => {
  const showTalkadotBranding = reportData?.showBranding

  return (
    <SocialShareImage
      id={downloadId}
      className={`event-report-card social-share-image ${color} ${
        !showTalkadotBranding ? 'no-logo' : ''
      }`}
      ref={downloadRef}
      color={color}>
      <Header reportData={reportData} shareKey={shareKey} />
      <InnerContainer
        customQuestion={downloadId === 'custom-question-share-image'}>
        {children}
      </InnerContainer>
      {showTalkadotBranding && <Footer />}
    </SocialShareImage>
  )
}

export default SocialShareTemplate
