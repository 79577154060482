import { useState, useContext } from 'react'
import { styled } from '@mui/system'
import {
  Typography,
  IconButton,
  Button,
  Menu,
  MenuItem,
  ListItemIcon,
  Avatar,
  useTheme,
  useMediaQuery,
} from '@mui/material'

import { ReactComponent as ShareIcon } from '../../icons/shareIcon_16x16.svg'
import { ReactComponent as CopyIcon } from '../../icons/copyIcon_16x16.svg'
import { ReactComponent as MailIcon } from '../../icons/mailIcon_16x16.svg'

import { AuthenticationContext } from '../authentication/authenticationContext'

import { publicProfileLink } from '../common/helpers'

const ProfileHeaderContainer = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr auto',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: theme.spacing(5),
  marginTop: theme.spacing(2),
}))

const ProfileHeaderLeft = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  minWidth: 0,
  gap: theme.spacing(2),
}))

const ProfileHeaderRight = styled('div')({
  display: 'flex',
  alignItems: 'center',
})

const StyledAvatar = styled(Avatar)({
  // Note: the image width and height here is a solution to
  // prevent a small black border to show up on the left and top
  // of the image in certain occassions.
  width: '100px',
  height: '100px',
  img: {
    width: '102%',
    height: '102%',
  },
})

const SpeakerName = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.navText,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}))

const ProfileActions = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
}))

const ProfileActionButton = styled(Button)(({ theme }) => ({
  color: theme.palette.text.navText,
  fontWeight: theme.fontWeights.regular,
}))

const ProfileHeader = ({ speaker }) => {
  const [anchorEl, setAnchorEl] = useState(null)

  const theme = useTheme()
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'))

  const {
    setNotification,
    authData: { user },
  } = useContext(AuthenticationContext)
  const { shortlink } = user

  const shareMenuOpen = Boolean(anchorEl)

  const handleClick = (e) => {
    setAnchorEl(e.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleCopyLink = () => {
    window.navigator.clipboard.writeText(publicProfileLink(shortlink))
    setNotification('Link copied to clipboard', 'success')
  }

  const handleMailLink = () => {
    const email = ''
    const subject = 'Talkadot Speaker Profile'
    const body = `Check out my profile on Talkadot: ${publicProfileLink(
      shortlink
    )}`
    const mailData = `mailto:${email}?subject=${
      encodeURIComponent(subject) || ''
    }&body=${encodeURIComponent(body) || ''}`
    window.open(mailData)
  }

  const menuItems = [
    {
      id: 'copy-link',
      text: 'Copy link',
      icon: <CopyIcon />,
      onClick: handleCopyLink,
    },
    {
      id: 'email-link',
      text: 'Email link',
      icon: <MailIcon />,
      onClick: handleMailLink,
    },
    // TODO: Add social share options
    // {
    //   id: 'share-on-social',
    //   text: 'Share on social',
    //   icon: <ChatBubbleOutlineIcon />,
    // },
  ]

  return (
    <ProfileHeaderContainer>
      <ProfileHeaderLeft>
        <StyledAvatar alt={speaker.name} src={speaker.avatar} />
        <SpeakerName variant="h1">{speaker.fullName}</SpeakerName>
      </ProfileHeaderLeft>
      <ProfileHeaderRight>
        <ProfileActions>
          {isMobileScreen ? (
            <IconButton
              id="share-profile-button"
              color="primary"
              aria-controls={shareMenuOpen ? 'share-profile-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={shareMenuOpen ? 'true' : undefined}
              onClick={handleClick}>
              <ShareIcon />
            </IconButton>
          ) : (
            <ProfileActionButton
              id="share-profile-button"
              startIcon={<ShareIcon />}
              variant="text"
              color="tertiary"
              aria-controls={shareMenuOpen ? 'share-profile-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={shareMenuOpen ? 'true' : undefined}
              onClick={handleClick}>
              Share
            </ProfileActionButton>
          )}
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={shareMenuOpen}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'share-profile-button',
            }}>
            {menuItems.map((item) => (
              <MenuItem key={item.id} onClick={item.onClick}>
                <ListItemIcon>{item.icon}</ListItemIcon>
                {item.text}
              </MenuItem>
            ))}
          </Menu>
          {/* TODO: Favorite button goes here */}
        </ProfileActions>
      </ProfileHeaderRight>
    </ProfileHeaderContainer>
  )
}

export default ProfileHeader
