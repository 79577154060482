import Api from '../../../services/api'

export const addMediaToSignatureTalk = async (
  signatureTalkId,
  mediaUploadObjs
) => {
  const mediaUploadsNotAddedToSignatureTalk = mediaUploadObjs.filter(
    (mediaUploadObj) => !mediaUploadObj.signatureTalkMediaUploadId
  )
  const mediaUplodadsIds = mediaUploadsNotAddedToSignatureTalk.map(
    (mediaUploadObj) => {
      return { id: mediaUploadObj.id }
    }
  )

  return Api.addMediaToSingatureTalk(signatureTalkId, {
    signature_talk: { signature_talk_media_uploads: mediaUplodadsIds },
  })
}
