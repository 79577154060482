import { useState } from 'react'

import { styled } from '@mui/system'

import { StyledTextField } from '../../common/FormComponents'
import Autocomplete from '@mui/material/Autocomplete'

import { Chip } from '@mui/material'

const SuggestionAutoCompleteInputContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1.5),
}))

const DEMOGRAPHICS = [
  {
    id: 'k-12-education',
    title: 'K-12 Education',
  },
  {
    id: 'higher-education-colleges-universities-trade-schools-',
    title: 'Higher Education (Colleges, Universities, Trade Schools)',
  },
  {
    id: 'not-for-profits-and-ngos',
    title: 'Not for profits and NGOs',
  },
  {
    id: 'professional-associations',
    title: 'Professional Associations',
  },
  {
    id: 'professional-networks',
    title: 'Professional Networks',
  },
  {
    id: 'industry-groups',
    title: 'Industry Groups',
  },
  {
    id: 'associations',
    title: 'Associations',
  },
  {
    id: 'trade-groups',
    title: 'Trade Groups',
  },
  {
    id: 'industry-alliances',
    title: 'Industry Alliances',
  },
  {
    id: 'corporate-and-business-sectors-large-corporations',
    title: 'Large Corporations',
  },
  {
    id: 'corporate-and-business-sectors-sme-small-and-medium-enterprises-',
    title: 'Small and Medium Enterprises',
  },
  {
    id: 'corporate-and-business-sectors-startups',
    title: 'Startups',
  },
  {
    id: 'community-and-civic-organizations',
    title: 'Community and Civic Organizations',
  },
  {
    id: 'faith-based-groups',
    title: 'Faith-Based Groups',
  },
  {
    id: 'government-and-public-sector',
    title: 'Government and Public Sector',
  },
  {
    id: 'specialty-events-health-and-wellness',
    title: 'Specialty Events: Health and wellness',
  },
  {
    id: 'specialty-events-tech-and-innovation',
    title: 'Specialty Events: Tech and Innovation',
  },
  {
    id: 'specialty-events-arts-culture',
    title: 'Specialty Events: Arts & Culture',
  },
  { id: 'other', title: 'Other' },
]

function titleize(str) {
  return str.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase())
}

const TalkAutocomplete = ({
  speechTopic,
  setSpeechTopic,
  errors,
  setErrors,
  validateDemographics,
}) => {
  const isRemovingFromArray = (updatedArray, currentArray) => {
    return currentArray.length - updatedArray.length === 1
  }

  const handleChangeSuccess = (newValue) => {
    setErrors({ ...errors, demographics: null })
    setSpeechTopic((prev) => ({
      ...prev,
      demographics: newValue.map((value) =>
        typeof value === 'string' ? value.trim() : value.title.trim()
      ),
    }))
  }

  return (
    <Autocomplete
      multiple
      id="tags-filled"
      options={DEMOGRAPHICS.map((option) => option.title)}
      value={speechTopic.demographics}
      onChange={(event, newValue) => {
        if (isRemovingFromArray(newValue, speechTopic.demographics)) {
          handleChangeSuccess(newValue)
          return
        }

        const processedValues = newValue.map((value) =>
          typeof value === 'string'
            ? value?.trim().toLowerCase()
            : value?.title?.trim().toLowerCase()
        )

        if (!validateDemographics(processedValues, errors, setErrors)) {
          return
        }

        handleChangeSuccess(newValue)
      }}
      freeSolo
      renderTags={(value, getTagProps) =>
        value.map((option, index) => {
          const { key, ...tagProps } = getTagProps({ index })
          return (
            <Chip
              variant="outlined"
              label={titleize(option)}
              key={key}
              {...tagProps}
            />
          )
        })
      }
      renderInput={(params) => (
        <SuggestionAutoCompleteInputContainer>
          <StyledTextField
            {...params}
            variant="outlined"
            helperText={errors.demographics}
            error={!!errors.demographics}
            onChange={() => setErrors({ ...errors, demographics: null })}
            placeholder="Add appropriate audience demographics for your talk"
          />
        </SuggestionAutoCompleteInputContainer>
      )}
    />
  )
}

export default TalkAutocomplete
