export const ValuableSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none">
      <path
        d="M14.3846 4.90764C15.88 4.90764 17.0923 6.11992 17.0923 7.61534M19.8 7.61534C19.8 10.6062 17.3755 13.0307 14.3846 13.0307C14.0681 13.0307 13.7578 13.0036 13.4562 12.9514C12.948 12.8636 12.4098 12.9747 12.0452 13.3394L9.64617 15.7384H7.6154V17.7692H5.58463V19.8H2.20001V17.2565C2.20001 16.7179 2.41397 16.2014 2.79481 15.8205L8.66057 9.95478C9.02522 9.59013 9.13633 9.05197 9.04853 8.54381C8.99641 8.24213 8.96924 7.9319 8.96924 7.61534C8.96924 4.6245 11.3938 2.19995 14.3846 2.19995C17.3755 2.19995 19.8 4.6245 19.8 7.61534Z"
        stroke="#4B5768"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
