import { styled } from '@mui/system'
import { Typography } from '@mui/material'
import LinearProgress, {
  linearProgressClasses,
} from '@mui/material/LinearProgress'

import { ProfileSectionHeader } from './ProfileSectionHeader'

const AttributesContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  maxWidth: '340px',
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(6),
}))

const AttributeWrapper = styled('div')({
  display: 'grid',
  gridTemplateColumns: '92px 1fr 50px',
  alignItems: 'center',
  marginBottom: '1rem',
})

const AttributeLabel = styled(Typography)({
  textTransform: 'capitalize',
})

const AttributeProgressBar = styled(LinearProgress)(({ theme }) => ({
  height: '10px',
  borderRadius: '100px',
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.neutral.extraLight,
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: '10px',
    backgroundColor: theme.palette.secondary.main,
  },
  marginRight: '10px',
}))

const PlaceHolderContainer = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(2.5),
  paddingBottom: theme.spacing(6),
}))

export const ProfileAttributes = ({ speaker, engagementBreakdown }) => {
  const ACTIONABLE = 'actionable'
  const ENGAGING = 'engaging'
  const INSPIRING = 'inspiring'
  const RELEVANT = 'relevant'
  const INTERACTIVE = 'interactive'

  const headerText = `Attendees say ${speaker?.name}'s talks are`

  const isValidLabel = (label) => {
    return !label?.includes('{{') && label?.trim().split(/\s+/).length <= 2
  }

  const formatLabelFromLegacyKeywords = (content) => {
    if (content.includes(ACTIONABLE)) {
      return ACTIONABLE
    }

    if (content.includes(ENGAGING)) {
      return ENGAGING
    }

    if (content.includes(INSPIRING)) {
      return INSPIRING
    }

    if (content.includes(RELEVANT)) {
      return RELEVANT
    }

    if (content.includes(INTERACTIVE)) {
      return INTERACTIVE
    }

    return
  }

  const formatLabel = (label) => {
    let formattedLabel = formatLabelFromLegacyKeywords(label)

    if (!formattedLabel) {
      return label
    }

    return formattedLabel
  }

  const sortedEngagementBreakdown = engagementBreakdown
    .map((engagementStat, i) => {
      if (engagementStat.label) {
        return {
          ...engagementStat,
          label: formatLabel(engagementStat.label),
        }
      } else {
        return null
      }
    })
    .filter((engagementStat) =>
      isValidLabel(engagementStat?.label) ? { ...engagementStat } : null
    )
    .sort((a, b) => {
      const scoreA = a.answer_count * a.avg_value
      const scoreB = b.answer_count * b.avg_value

      return scoreB - scoreA // Sort in descending order based on the multiplied score
    })

  const makeBarValue = (engagementStatValue) => {
    if (!engagementStatValue) {
      return
    } else {
      // All the numbers are on a scale of 1-5, so need to adjust this for a scale of 100%
      return Number(parseFloat(engagementStatValue * 20).toFixed(1))
    }
  }

  const renderEngagementRow = (engagementType, index) => {
    const barValue = makeBarValue(engagementType.avg_value)
    return (
      <AttributeWrapper key={index}>
        <AttributeLabel variant="body2">{engagementType.label}</AttributeLabel>
        <AttributeProgressBar variant="determinate" value={barValue} />
        <AttributeLabel variant="body2">{Math.round(barValue)}%</AttributeLabel>
      </AttributeWrapper>
    )
  }

  const renderPlaceholder = () => {
    return (
      <Typography variant="subtitle1">
        We're still gathering feedback for this profile!
      </Typography>
    )
  }

  return (
    <>
      <ProfileSectionHeader headerText={headerText} />
      {sortedEngagementBreakdown?.length > 0 ? (
        <AttributesContainer>
          {sortedEngagementBreakdown?.slice(0, 5)?.map((engagementType, i) => {
            return renderEngagementRow(engagementType, i)
          })}
        </AttributesContainer>
      ) : (
        <PlaceHolderContainer>{renderPlaceholder()}</PlaceHolderContainer>
      )}
    </>
  )
}
