export const CHARACTER_LIMITS = {
  DESCRIPTION: 5000,
  TITLE: 250,
  OUTCOME: 250,
  DEMOGRAPHIC: 150,
}

export const MAXIMUM_DEMOGRAPHICS = 10
export const MAXIMUM_OUTCOMES = 6

export const validateSpeechTopic = (speechTopic, errors, setErrors) => {
  const errorUpdate = {
    title: getTitleError(speechTopic.title),
    description: getDescriptionError(speechTopic.description),
    outcomes: getOutcomesError(speechTopic.outcomes),
    demographics: getDemographicError(speechTopic.demographics),
  }

  setErrors({
    ...errors,
    ...errorUpdate,
  })

  return validErrors(errorUpdate)
}

export const validateDemographics = (demographics, errors, setErrors) => {
  const demographicErrors = getDemographicError(demographics)

  setErrors({
    ...errors,
    demographics: demographicErrors,
  })

  return !demographicErrors
}

export const validateOutcomes = (speechTopic, errors, setErrors) => {
  const outcomesError = getOutcomesError(speechTopic.outcomes)
  setErrors({
    ...errors,
    outcomes: outcomesError,
  })

  return !outcomesError
}

export const validateTitle = (speechTopic, errors, setErrors) => {
  const titleError = getTitleError(speechTopic.title)
  setErrors({
    ...errors,
    title: titleError,
  })

  return !titleError
}

export const validateDescription = (speechTopic, errors, setErrors) => {
  const descriptionError = getDescriptionError(speechTopic.description)
  setErrors({
    ...errors,
    description: descriptionError,
  })
}

// private

const getDemographicError = (demographics) => {
  if (!validateMaxLength(demographics, MAXIMUM_DEMOGRAPHICS)) {
    return `You can only add up to ${MAXIMUM_DEMOGRAPHICS} audience demographics.`
  }

  if (
    demographics.some(
      (value) =>
        !validateExists(value) ||
        !validateMaxLength(value, CHARACTER_LIMITS.DEMOGRAPHIC)
    )
  ) {
    return `Demographic must be between 1 and ${CHARACTER_LIMITS.DEMOGRAPHIC} characters.`
  }

  if (!validateArrayUniqueness(demographics)) {
    return 'You have already added this audience demographic!'
  }

  return ''
}

const getTitleError = (title) => {
  if (!validateExists(title)) {
    return 'Title is required'
  }

  if (!validateMaxLength(title, CHARACTER_LIMITS.TITLE)) {
    return `Title must be between 1 and ${CHARACTER_LIMITS.TITLE} characters.`
  }

  return ''
}

const getDescriptionError = (description) => {
  if (!validateExists(description)) {
    return 'Description is required'
  }

  if (!validateMaxLength(description, CHARACTER_LIMITS.DESCRIPTION)) {
    return `Description must be between 1 and ${CHARACTER_LIMITS.DESCRIPTION} characters.`
  }

  return ''
}

const getOutcomesError = (outcomes) => {
  const outcomeErrors = outcomes.map((outcome, index) => {
    return validateOutcome(outcome, outcomes, index)
  })

  const hasErrors = outcomeErrors.some((error) => error !== '')

  if (hasErrors) {
    return outcomeErrors
  }

  return []
}

const validateOutcome = (outcome, allOutcomes, currentIndex) => {
  if (!validateExists(outcome)) {
    return `Outcome cannot be blank`
  }

  if (!validateMaxLength(outcome, CHARACTER_LIMITS.OUTCOME)) {
    return `Outcome is too long (max ${CHARACTER_LIMITS.OUTCOME} characters)`
  }

  const normalizedCurrentOutcome = outcome.trim().toLowerCase()

  // Check for duplicates, excluding the current outcome's index
  const duplicateIndex = allOutcomes.findIndex((otherOutcome, index) => 
    index !== currentIndex && 
    otherOutcome.trim().toLowerCase() === normalizedCurrentOutcome
  )

  if (duplicateIndex !== -1) {
    return `This outcome is a duplicate of outcome ${duplicateIndex + 1}`
  }

  return ''
}

const validateExists = (value) => {
  return value?.trim()
}

// This is for both array and content values at the moment
// Do not add .trim() to the statement of value
const validateMaxLength = (value, maxLength) => {
  return value?.length <= maxLength
}

const validateArrayUniqueness = (array) => {
  return new Set(array).size === array.length
}

const validErrors = (errorObject) => {
  return !Object.entries(errorObject).reduce((hasError, [key, value]) => {
    if (Array.isArray(value)) {
      return hasError || value.some((item) => item !== '')
    }
    return hasError || value !== ''
  }, false)
}
