import axios from 'axios'
import Api from '../services/api'

export const uploadToS3 = async ({
  presignedUrl,
  file,
  setUploading,
  setCancelTokenSource,
  onUploadProgress,
}) => {
  try {
    setUploading(true)

    const cancelToken = axios.CancelToken.source()
    setCancelTokenSource(cancelToken)

    const res = await axios.put(presignedUrl, file, {
      headers: {
        'Content-Type': file.type,
      },
      cancelToken: cancelToken.token,
      onUploadProgress: (progressEvent) => {
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        )
        if (onUploadProgress) {
          onUploadProgress(percentCompleted) // Call the progress callback function
        }
      },
    })

    if (res.status !== 200) {
      throw new Error('Sorry, something went wrong uploading your file')
    }

    setUploading(false)
    return res.data
  } catch (error) {
    setUploading(false)
    throw new Error(error.message)
  }
}

export const cancelUpload = (cancelTokenSource) => {
  if (cancelTokenSource) {
    cancelTokenSource.cancel('File upload cancelled')
  }
}

export const presignVideoUploadUrl = async ({ fileName, fileType }) => {
  const params = {
    video_upload_presign_url: {
      file_name: fileName,
      file_type: fileType,
    },
  }

  const res = await Api.videoUploadPresignedUrls(params)

  if (res.errors) {
    throw res.errors
  }

  return res
}

export const presignUrl = async ({ fileName, fileType }) => {
  const params = {
    presign_url: {
      file_name: fileName,
      file_type: fileType,
    },
  }

  const res = await Api.presignUrl(params)

  if (res.errors) {
    throw res.errors
  }

  return res
}
