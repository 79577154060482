import { useContext, useEffect, useState } from 'react'
import { styled } from '@mui/system'
import { isMobileOnly } from 'react-device-detect'

import CustomQuestionCard from './CustomQuestionCard'
import CustomQuestionSocialShareContent from './CustomQuestionSocialShareContent'

import { AuthenticationContext } from '../authentication/authenticationContext'

import Api from '../../services/api'
import usePolling from '../../hooks/usePolling'
import { renderCustomQuestionContent } from './customQuestionRenderer'

const MobileImg = styled('img')({
  width: '100%',
  height: 'auto',
  display: 'block',
})

const CustomQuestion = ({
  question,
  isEventOwner,
  handleOpenSocialShare,
  reportData,
  shareKey,
  handleToggleVisibility,
}) => {
  const [mobileImgSrc, setMobileImgSrc] = useState('')

  const { setNotification, showFullPageLoader, hideFullPageLoader, authData } =
    useContext(AuthenticationContext)

  const { data, startPolling } = usePolling({
    apiMethod: Api.pollSocialShareImageStatus,
    shouldStopPolling: (data) => data.status === 'completed',
    maxTries: 20,
  })

  useEffect(() => {
    const fetchImageAsBase64 = async (url) => {
      try {
        const response = await fetch(url)
        const blob = await response.blob()
        const reader = new FileReader()

        reader.onloadend = () => {
          setMobileImgSrc(reader.result) // Set Base64 Data URL
        }

        reader.readAsDataURL(blob) // Convert Blob to Base64
      } catch (error) {
        setNotification(
          'Oops, looks like there was an issue downloading your image. Please try again. If the problem persists, contact us at hello@talkadot.com!',
          'error'
        )
      } finally {
        hideFullPageLoader()
      }
    }

    if (data?.status === 'completed') {
      fetchImageAsBase64(data.image_url)
    } else if (data?.status === 'failed') {
      setNotification(
        'Oops, looks like there was an issue downloading your image. Please try again. If the problem persists, contact us at hello@talkadot.com!',
        'error'
      )
      hideFullPageLoader()
    }
  }, [data])

  useEffect(() => {
    if (mobileImgSrc) {
      handleOpenSocialShare(<MobileImg src={mobileImgSrc} />)
    }
  }, [mobileImgSrc])

  const handleMobileImageDownload = async ({ selector, question }) => {
    const params = {
      social_share_download: {
        share_key: shareKey,
        element_id: selector,
        custom_question_id: question?.id,
      },
    }

    showFullPageLoader()

    const res = await Api.getSocialShareImage(params)

    const taskId = res.task_id

    if (taskId) {
      startPolling({ task_id: taskId })
    } else {
      console.log('No task id')
      hideFullPageLoader()
    }
  }

  const handleSocialShareDownload = () => {
    if (isMobileOnly || window.innerWidth < 960) {
      handleMobileImageDownload({
        selector: 'custom-question-share-image',
        question,
      })
    } else {
      handleOpenSocialShare(
        <CustomQuestionSocialShareContent
          question={question}
          reportData={reportData}
          shareKey={shareKey}
          isEventOwner={isEventOwner}
          handleOpenSocialShare={handleOpenSocialShare}
        />
      )
    }
  }

  return (
    <CustomQuestionCard
      question={question}
      isEventOwner={isEventOwner}
      handleSocialShareDownload={handleSocialShareDownload}
      handleToggleVisibility={handleToggleVisibility}>
      {renderCustomQuestionContent(question, false)}
    </CustomQuestionCard>
  )
}

export default CustomQuestion
