export const ResponsesSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="19"
      viewBox="0 0 24 19"
      fill="none">
      <path
        d="M19.3846 17.8173L21.4114 17.7982C21.8525 17.7963 22.275 17.6202 22.5869 17.3082C22.8989 16.9963 23.075 16.5738 23.0769 16.1327V2.84844C23.075 2.40731 22.8989 1.98481 22.5869 1.67288C22.275 1.36095 21.8525 1.18483 21.4114 1.18286L19.3846 1.2019M4.6154 1.18286L2.49233 1.2019C1.57617 1.2019 0.923096 1.9519 0.923096 2.86748V16.1523C0.923096 17.0679 1.57617 17.8173 2.49233 17.8173L4.6154 17.7982M19.3846 5.79825V13.1829M15.6923 3.95209V15.029M12 4.87517V14.1059M8.30771 3.95209V15.029M4.6154 5.79825V13.1829"
        stroke="#4B5768"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
