import { styled } from '@mui/system'
import { Typography, Button } from '@mui/material'

export const FormFieldContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  margin: '10px 0px',
  alignItems: 'center',
  p: {
    minWidth: '100px',
  },
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
}))

export const FormLabel = ({ label }) => (
  <Typography>
    <b>{label}</b>
  </Typography>
)

export const SendTestEmailButton = styled(Button)(({ theme }) => ({
  display: 'flex',
  padding: '12px 30px 12px 26px',
  color: theme.palette.base.white,
}))
