import { useEffect, useState } from 'react'

import Api from '../../services/api'

import { QuestionHeaderActionButton } from '../codes/builder/CodeBuilderQuestionHeader'

import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import { ReactComponent as CircleArrowRight } from '../../icons/circledArrowRight.svg'
import { ReactComponent as HeadphoneIcon } from '../../icons/headphoneIcon.svg'

import { styled } from '@mui/system'

import { Typography, CircularProgress } from '@mui/material'

import moment from 'moment-timezone'

export const WEBINAR_TYPES = {
  AMA: 'AMA',
  DEMO: 'DEMO',
}

const RecordedDemoContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginTop: theme.spacing(3),
  padding: theme.spacing(1.5),
  borderRadius: theme.shape.borderRadius.xs,
  backgroundColor: theme.palette.neutral.extraLight,
}))

const WebinarRow = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '10px',
  borderBottom: `1px solid ${theme.palette.neutral.extraLight}`,
}))

const ContentWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(3.5, 6),
  backgroundColor: theme.palette.base.white,
  minWidth: '280px'
}))

const Header = styled('div')(({ theme }) => ({
  marginBottom: theme.spacing(3),
}))

const WebinarTitle = styled(Typography)(({ theme }) => ({
  fontWeight: theme.fontWeights.semiBold,
  [theme.breakpoints.down('sm')]: {
    fontSize: '0.875rem',
  },
}))

const StyledIconWrapper = styled('div')(({ theme }) => ({
  marginBottom: theme.spacing(3),
  svg: {
    height: '35px',
    width: '35px',
  },
}))

const WebinarList = styled('div')(({ theme }) => ({
  border: `1px solid ${theme.palette.neutral.extraLight}`,
}))

const WEBINAR_DATA = [
  {
    id: 0,
    webinarType: WEBINAR_TYPES.DEMO,
    title: 'Join us at one of our FREE upcoming live trainings!',
    description: (
      <>
        During this session, we'll walk you through the essential steps and key
        features of Talkadot. We cover everything from basic steps and best
        practices to various tips & tricks to best optimize your ROI. You'll be
        ready to go with confidence by the end of the session!
        <br />
        <br />
        No matter your level of familiarity with Talkadot, our goal is to
        empower you with the knowledge and skills to make the most out of your
        speaking engagements.
        <br />
        <br />
      </>
    ),
    icon: <HeadphoneIcon />,
  },
  {
    id: 1,
    webinarType: WEBINAR_TYPES.AMA,
    title: "Join us at one of our FREE upcoming live AMA's!",
    description: (
      <>
        In these sessions, we're here to provide you with personalized
        assistance on any topic you need help with. Consider this session as a
        fantastic opportunity to receive one-on-one support tailored to your
        specific needs.
        <br />
        <br />
        The Talkadot team is committed to ensuring your satisfaction and
        answering any questions you might have. We're here to make your
        experience the best, so please don't hesitate to attend!
        <br />
        <br />
        We look forward to helping you out! 😊
        <br />
        <br />
      </>
    ),
    icon: <HelpOutlineIcon />,
  },
]

const UpcomingWebinarRow = ({ webinar }) => {
  // Display the webinar time in current users timezone
  const formatWebinarTime = () => {
    const start = moment.utc(webinar.start_time).tz(webinar.timezone)
    const end = start.clone().add(Number(webinar.duration), 'minutes')

    const localStart = start.clone().local()
    const localEnd = end.clone().local()

    const date = localStart.format('ddd, MMM Do')
    const time = `${localStart.format('h:mm')} - ${localEnd.format('h:mm A')}`

    let tzAbbr = localStart.format('z')

    if (!tzAbbr || tzAbbr === 'UTC') {
      tzAbbr = new Intl.DateTimeFormat(undefined, { timeZoneName: 'short' })
        .formatToParts(localStart.toDate())
        .find((part) => part.type === 'timeZoneName')?.value
    }

    return { date, time, tzAbbr }
  }

  const { date, time, tzAbbr } = formatWebinarTime()

  return (
    <WebinarRow>
      <WebinarTitle variant="body2">
        {date} @ {time} {tzAbbr}
      </WebinarTitle>
      <QuestionHeaderActionButton
        variant="outlined"
        startIcon={<CircleArrowRight />}
        color="tertiary"
        onClick={() => window.open(webinar.registration_link)}>
        <span className="button-text">Sign Up</span>
      </QuestionHeaderActionButton>
    </WebinarRow>
  )
}

const UpcomingWebinars = ({ webinarType }) => {
  const [webinars, setWebinars] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const webinarData = WEBINAR_DATA.find(
    (webinarDatum) => webinarDatum.webinarType === webinarType
  )

  // If we want to show other types of webinars, or a list of all of them
  // we'll need to change this logic.  For now, only show Demo's & AMA's
  const invalidWebinarType =
    !webinarType ||
    (webinarType !== WEBINAR_TYPES.AMA && webinarType !== WEBINAR_TYPES.DEMO)

  const handleSetWebinars = (webinars) => {
    if (invalidWebinarType) {
      return
    }

    return setWebinars(
      webinars.filter((webinar) => webinar.meeting_type === webinarType)
    )
  }

  useEffect(() => {
    const fetchUpcomingWebinars = async () => {
      try {
        setIsLoading(true)
        const res = await Api.getUpcomingWebinars()

        if (!res.errors) {
          handleSetWebinars(res)
          setIsLoading(false)
        } else {
          throw res.errors
        }
      } catch (err) {
        setIsLoading(false)
      }
    }

    fetchUpcomingWebinars()
  }, [])

  const renderLoader = () => (
    <div>
      <CircularProgress />
    </div>
  )

  const renderWebinars = () => {
    if (!webinars || webinars.length === 0) {
      return (
        <Typography>
          There are no upcoming webinars. If you need help with anything, please
          contact support at hello@talkadot.com.
        </Typography>
      )
    }

    return (
      <>
        <Typography>{webinarData?.description}</Typography>
        <WebinarList>
          {webinars.map((webinar) => (
            <UpcomingWebinarRow key={webinar?.id} webinar={webinar} />
          ))}
        </WebinarList>
      </>
    )
  }

  const renderRecordedDemo = () => (
    <RecordedDemoContainer>
      <Typography>
        None of these times work for you? Watch our recorded demo instead:
      </Typography>
      <QuestionHeaderActionButton
        variant="outlined"
        startIcon={<CircleArrowRight />}
        color="tertiary"
        onClick={() => window.open('https://vimeo.com/1046486721', '_blank')}>
        <span className="button-text">Watch now</span>
      </QuestionHeaderActionButton>
    </RecordedDemoContainer>
  )

  return (
    <ContentWrapper>
      <StyledIconWrapper>{webinarData?.icon}</StyledIconWrapper>
      <Header>
        <Typography variant="h5">{webinarData?.title}</Typography>
      </Header>
      {isLoading ? renderLoader() : renderWebinars()}
      {webinarType === WEBINAR_TYPES.DEMO && renderRecordedDemo()}
    </ContentWrapper>
  )
}

export default UpcomingWebinars
