export const ReattendSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="22"
      viewBox="0 0 26 22"
      fill="none">
      <path
        d="M18.5921 0.846191C14.8467 0.846191 13.0006 4.5385 13.0006 4.5385C13.0006 4.5385 11.1544 0.846191 7.40905 0.846191C4.36521 0.846191 1.95482 3.39273 1.92367 6.43138C1.8602 12.7389 6.92732 17.2245 12.4814 20.9941C12.6345 21.0983 12.8154 21.154 13.0006 21.154C13.1858 21.154 13.3667 21.0983 13.5198 20.9941C19.0733 17.2245 24.1404 12.7389 24.0775 6.43138C24.0464 3.39273 21.636 0.846191 18.5921 0.846191Z"
        stroke="#4B5768"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1 11.0001H7.46154L10.2308 5.46167L13 14.6924L15.7692 9.15398L17.6154 12.8463H25"
        stroke="#4B5768"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
