import { useContext } from 'react'

import { styled } from '@mui/system'
import { Typography, Button } from '@mui/material'

import { ReactComponent as QuickModeIcon } from '../../../icons/codeBuilder/quickModeIcon.svg'
import { ReactComponent as AdvancedModeIcon } from '../../../icons/codeBuilder/advancedModeIcon.svg'

import { Notification } from '../../common/Notification'
import { buildModes } from './CodeBuilderParent'

import {
  usePermissionHelper,
  OFFER_CODE_LIMIT,
} from '../../../utils/permission_helper'

import { AuthenticationContext } from '../../authentication/authenticationContext'
import WebinarAlert from '../../helpCenter/WebinarAlert'

const Card = styled('div')(({ theme }) => ({
  padding: theme.spacing(2.5),
  paddingBottom: theme.spacing(6),
  background: theme.palette.base.white,
  borderRadius: theme.shape.borderRadius.xxs,
}))

const StepsContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(2.5),
  alignItems: 'flex-start',
  justifyContent: 'center',
  width: '100%',
  marginTop: theme.spacing(6),
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    alignItems: 'center',
    gap: theme.spacing(0),
  },
}))

const StepContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  maxWidth: '160px',
  [theme.breakpoints.down('sm')]: {
    width: '160px',
  },
}))

const StepNumber = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontWeight: theme.fontWeights.semiBold,
  marginBottom: theme.spacing(0.5),
}))

const StepHeader = styled(Typography)(({ theme }) => ({
  color: theme.palette.neutral.semiDark,
  fontWeight: theme.fontWeights.semiBold,
  marginBottom: theme.spacing(0.25),
}))

const StepText = styled(Typography)(({ theme }) => ({
  color: theme.palette.neutral.main,
  minHeight: '30px',
  marginBottom: theme.spacing(2),
  [theme.breakpoints.down('sm')]: {
    minHeight: '0px',
  },
}))

const StepGraphicContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}))

const StepGraphic = styled('img')({
  height: 'auto',
  maxWidth: '400px',
})

const StyledHelpLink = styled('a')(({ theme }) => ({
  color: theme.palette.secondary.dark,
}))

const ModeSelectionContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  marginTop: theme.spacing(6),
}))

const ModeSelectionHeader = styled(Typography)(({ theme }) => ({
  color: theme.palette.neutral.semiDark,
}))

const ModeSelectionButtonContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(2.5),
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    alignItems: 'center',
  },
}))

const StyledButton = styled(Button)(({ theme }) => ({
  maxWidth: '275px',
  '&.MuiButton-outlined': {
    borderWidth: '2px',
    boxShadow: theme.shape.boxShadow.xs,
    '&:hover': {
      boxShadow: theme.shape.boxShadow.md,
    },
  },
}))

const ButtonContent = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: theme.spacing(1),
}))

const ButtonMainText = styled(Typography)(({ theme, color }) => ({
  color: theme.palette[color].main,
  fontWeight: theme.fontWeights.semiBold,
}))

const ButtonSubText = styled(Typography)(({ theme }) => ({
  color: theme.palette.neutral.semiDark,
}))

const UpgradeNotice = styled(Typography)(({ theme }) => ({
  color: theme.palette.error.semiDark,
  fontWeight: theme.fontWeights.semiBold,
  span: {
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
}))

const CodeBuilderAddCodeOnboarding = ({ setBuildMode }) => {
  const { canCreateSurveyOffer, findPermission } = usePermissionHelper()
  const { limit, consumed } = findPermission(OFFER_CODE_LIMIT)
  const { toggleUpgradeModal } = useContext(AuthenticationContext)

  const handleBuildModeOptionSelect = (buildMode) => {
    if (!canCreateSurveyOffer(consumed)) {
      return toggleUpgradeModal(
        true,
        'Upgrade to add more codes!',
        OFFER_CODE_LIMIT,
        { granularControls: { custom_question_limit: consumed } }
      )
    }

    setBuildMode(buildMode)
  }

  return (
    <Card>
      {!canCreateSurveyOffer(consumed) && (
        <Notification variant="redWarning" hideClose={true}>
          <UpgradeNotice>
            You have reached your plan limit of {limit} codes.{' '}
            <span
              onClick={() =>
                toggleUpgradeModal(true, 'Upgrade to add more codes!', {
                  permissionType: OFFER_CODE_LIMIT,
                })
              }>
              <u>Upgrade to create more.</u>
            </span>
          </UpgradeNotice>
        </Notification>
      )}

      <WebinarAlert />

      <Notification hideClose={true} variant="subtleWarning" hideIcon={true}>
        <Typography>
          <b>Talkadot codes are used in order to:</b>
        </Typography>
        <Typography>1. Configure the questions your audience sees</Typography>
        <Typography>
          2. Set up any bonuses, offers or downloads for your audience
        </Typography>
        <br />
        <Typography>
          <StyledHelpLink
            href="https://talk.talkadot.com/c/resources/new-release-custom-codes"
            target="_blank"
            rel="noreferrer">
            Learn more about everything you can do with your codes here.
          </StyledHelpLink>
        </Typography>
      </Notification>
      <ModeSelectionContainer>
        <ModeSelectionHeader variant="h4">
          👇 choose a mode to start
        </ModeSelectionHeader>
        <ModeSelectionButtonContainer>
          <StyledButton
            variant="outlined"
            color="primary"
            onClick={() => handleBuildModeOptionSelect(buildModes.QUICK)}>
            <ButtonContent>
              <QuickModeIcon />
              <ButtonMainText variant="paragraph03" color="primary">
                Quick Mode
              </ButtonMainText>
              <ButtonSubText variant="body1">
                For your first time setup, or if you are fine with the Talkadot
                defaults
              </ButtonSubText>
            </ButtonContent>
          </StyledButton>
          <StyledButton
            variant="text"
            color="secondary"
            onClick={() => handleBuildModeOptionSelect(buildModes.ADVANCED)}>
            <ButtonContent>
              <AdvancedModeIcon />
              <ButtonMainText variant="paragraph03" color="secondary">
                Advanced Mode
              </ButtonMainText>
              <ButtonSubText variant="body1">
                For when you need to add, change, or hide questions
              </ButtonSubText>
            </ButtonContent>
          </StyledButton>
        </ModeSelectionButtonContainer>
      </ModeSelectionContainer>
      <StepsContainer>
        <StepContainer>
          <StepNumber variant="paragraph00">STEP 1</StepNumber>
          <StepHeader variant="body2">Configure your code</StepHeader>
          <StepText variant="paragraph00">
            This is like creating a survey
          </StepText>
          <StepGraphicContainer>
            <StepGraphic src="https://d3qvkz8495pjoq.cloudfront.net/add-code-step-1.svg" />
          </StepGraphicContainer>
        </StepContainer>
        <StepContainer>
          <StepNumber variant="paragraph00">STEP 2</StepNumber>
          <StepHeader variant="body2">Add your bonus</StepHeader>
          <StepText variant="paragraph00">
            Incentivize your audience to participate
          </StepText>
          <StepGraphicContainer>
            <StepGraphic src="https://d3qvkz8495pjoq.cloudfront.net/add-code-step-2.svg" />
          </StepGraphicContainer>
        </StepContainer>
        <StepContainer>
          <StepNumber variant="paragraph00">STEP 3</StepNumber>
          <StepHeader variant="body2">Add slide to your talk</StepHeader>
          <StepText variant="paragraph00">
            Have your audience scan your QR code
          </StepText>
          <StepGraphicContainer>
            <StepGraphic src="https://d3qvkz8495pjoq.cloudfront.net/add-code-step-3.svg" />
          </StepGraphicContainer>
        </StepContainer>
      </StepsContainer>
    </Card>
  )
}

export default CodeBuilderAddCodeOnboarding
