import React from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { styled } from '@mui/system'
import PageHeader from '../../common/PageHeader'
import PageContainer from '../../common/PageContainer'
import PageDescription from '../../common/PageDescription'
import SpeechTopicForm from './SpeechTopicForm'

const FormContainer = styled('div')({
  background: 'white',
})

const SpeechTopicParent = () => {
  const { id } = useParams()
  const history = useHistory()

  const handleSuccess = () => {
    history.push('/talks/speech-topics')
  }

  return (
    <PageContainer>
      <PageHeader
        header={id ? 'Edit Your Speech Topic' : 'Your Speech Topic Setup'}
      />
      <PageDescription>
        Your speech topic is the core message you’re known for—the one event
        planners consider when booking you. Most speakers have multiple speech
        topics (also known as a signature talk) , each representing a distinct
        topic. Setting up your speech topics lets you see grouped insights and
        helps us cross-promote your talks to event hosts and clients.
      </PageDescription>
      <FormContainer>
        <SpeechTopicForm signatureTalkId={id} onSuccess={handleSuccess} />
      </FormContainer>
    </PageContainer>
  )
}

export default SpeechTopicParent
