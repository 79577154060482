import { useContext } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { Menu, MenuItem, ListItemIcon, Divider, Avatar } from '@mui/material'
import { styled } from '@mui/system'
import { ReactComponent as ChaptersIcon } from '../../icons/navIcons/chaptersIcon.svg'
import { ReactComponent as LogOutIcon } from '../../icons/navIcons/logoutIcon.svg'
import { ReactComponent as SettingsIcon } from '../../icons/navIcons/settingsIcon.svg'
import { ReactComponent as UpgradeIcon } from '../../icons/upgradeIcon_white.svg'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'

import AdminPanelSettings from '@mui/icons-material/AdminPanelSettings'

import envConfig from '../../envConfig'

import { AuthenticationContext } from '../authentication/authenticationContext'
import { GroupContext } from '../groups/groupContext'
import { usePermissionHelper } from '../../utils/permission_helper'

const StyledMenu = styled(Menu)(({ theme }) => ({
  '& .MuiPaper-root': {
    filter: `drop-shadow(${theme.shape.boxShadow.xs})`,
    top: '100px !important',
    borderRadius: `0 0 ${theme.shape.borderRadius.md} ${theme.shape.borderRadius.md}`,
    minWidth: '258px',
    '&:before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: 0,
      right: 14,
      width: 18,
      height: 18,
      backgroundColor: 'white',
      transform: 'translateY(-50%) rotate(45deg)',
      zIndex: 0,
    },
    [theme.breakpoints.down('sm')]: {
      top: '80px !important',
    },
  },
}))

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  fontFamily: 'Figtree, sans-serif',
  minHeight: '52px !important',
  '& .MuiListItemIcon-root': {
    minWidth: '50px',
  },
  '& :not(.MuiAvatar-root, .upgrade-icon) > svg': {
    width: '2rem',
    height: '2rem',
    paddingLeft: theme.spacing(1),
  },
}))

const UpgradeIconContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '40px',
  height: '40px',
  borderRadius: theme.shape.borderRadius.sm,
  backgroundColor: theme.palette.tertiary.main,
  svg: {
    width: '18px',
    height: '22px',
  },
}))

const GroupsBadge = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: theme.palette.secondary.extraLight,
  color: theme.palette.secondary.main,
  borderRadius: theme.shape.borderRadius.sm,
  padding: '4px 6px',
  marginLeft: '8px',
  fontWeight: '600',
}))

const SettingsMenuAvatar = styled(Avatar)({
  marginRight: '10px',
  // Note: the image width and height here is a solution to
  // prevent a small black border to show up on the left and top
  // of the image in certain occassions.
  img: {
    width: '102%',
    height: '102%',
  },
})

const NewSettingsMenu = ({ dropDownOpen, handleDropDownClose, anchorEl }) => {
  const {
    logoutUser,
    authData: { user },
  } = useContext(AuthenticationContext)
  const { isAdmin, isAssociate, first_name, shortlink } = user
  const {
    groupState: { groups },
    getPrimaryGroup,
  } = useContext(GroupContext)
  const history = useHistory()
  const location = useLocation()
  const { onPaidPlan } = usePermissionHelper()
  const primaryGroup = getPrimaryGroup()

  const showPublicProfileLink = !location.pathname.includes('/groups')

  const handleSelectGroup = (groupId) => {
    const newPath = `/groups/${groupId}/events`

    history.push(newPath)
    handleDropDownClose()
  }

  const handleSpeakerAvatarClick = () => {
    handleDropDownClose()

    if (showPublicProfileLink) {
      window.location.href = `${envConfig.REACT_APP_PUBLIC_PAGE_ENDPOINT}/s/${shortlink}`
    } else {
      history.push('/')
    }
  }

  const renderSpeakerAvatarText = () => {
    if (showPublicProfileLink) {
      return <b>{isAssociate ? first_name + "'s" : 'Your'} public profile</b>
    } else {
      return <b>{user?.first_name + ' ' + user?.last_name}</b>
    }
  }

  const renderPrimaryGroupMenuItem = () => {
    if (groups?.length > 0 && primaryGroup) {
      return (
        <StyledMenuItem
          key={primaryGroup.id}
          onClick={() => handleSelectGroup(primaryGroup.id)}>
          <ListItemIcon>
            <SettingsMenuAvatar
              src={primaryGroup.avatar}
              alt={primaryGroup.name}
            />
          </ListItemIcon>
          {primaryGroup.name}
        </StyledMenuItem>
      )
    }
  }

  const renderAllGroupsMenuItem = () => {
    if (groups?.length > 0) {
      return (
        <StyledMenuItem
          onClick={() => {
            handleDropDownClose()
            history.push('/groups')
          }}>
          <ListItemIcon>
            <ChaptersIcon />
          </ListItemIcon>
          <span>See all your chapters</span>
          <GroupsBadge>{groups.length}</GroupsBadge>
        </StyledMenuItem>
      )
    }
  }

  const renderUpgradeMenuItem = () => {
    return (
      !onPaidPlan() && (
        <StyledMenuItem onClick={() => history.push('/account/billing')}>
          <ListItemIcon>
            <UpgradeIconContainer className="upgrade-icon">
              <UpgradeIcon />
            </UpgradeIconContainer>
          </ListItemIcon>
          Upgrade to Pro!
        </StyledMenuItem>
      )
    )
  }

  return (
    <StyledMenu
      id="upper-nav-menu"
      anchorEl={anchorEl}
      keepMounted
      open={dropDownOpen}
      onClose={handleDropDownClose}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      aria-labelledby="upper-nav-menu-button">
      <StyledMenuItem onClick={handleSpeakerAvatarClick}>
        <ListItemIcon>
          <SettingsMenuAvatar src={user.avatar} alt={user.email} />
        </ListItemIcon>
        {renderSpeakerAvatarText()}
      </StyledMenuItem>
      <Divider />
      {renderUpgradeMenuItem()}
      {renderPrimaryGroupMenuItem()}
      {renderAllGroupsMenuItem()}
      <StyledMenuItem
        onClick={() => {
          handleDropDownClose()
          history.push('/account')
        }}>
        <ListItemIcon>
          <SettingsIcon />
        </ListItemIcon>
        Account Settings
      </StyledMenuItem>
      <StyledMenuItem
        onClick={() => {
          handleDropDownClose()
          history.push('/help')
        }}>
        <ListItemIcon>
          <HelpOutlineIcon />
        </ListItemIcon>
        Help
      </StyledMenuItem>

      <StyledMenuItem onClick={logoutUser}>
        <ListItemIcon>
          <LogOutIcon />
        </ListItemIcon>
        Log Out
      </StyledMenuItem>
      {isAdmin && <Divider />}
      {isAdmin && (
        <StyledMenuItem
          onClick={() => {
            handleDropDownClose()
            history.push('/admin')
          }}>
          <ListItemIcon>
            <AdminPanelSettings />
          </ListItemIcon>
          Admin Panel
        </StyledMenuItem>
      )}
    </StyledMenu>
  )
}

export default NewSettingsMenu
