import { useEffect, useState, useRef, useContext } from 'react'
import { isMobileOnly } from 'react-device-detect'
import { motion } from 'framer-motion/dist/framer-motion'
import { styled } from '@mui/system'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Skeleton,
  Button,
  IconButton,
  Tooltip,
  useTheme,
} from '@mui/material'

import CloseIcon from '@mui/icons-material/Close'
import { ReactComponent as EyeIcon } from '../../icons/eyeIcon_16x16.svg'
import { ReactComponent as EyeOffIcon } from '../../icons/eyeOffIcon_16x16.svg'

import Api from '../../services/api'
import { AuthenticationContext } from '../authentication/authenticationContext'
import usePolling from '../../hooks/usePolling'

import TestimonialStatImage from './TestimonialStatImage'
import FeedbackAnswersParent from './FeedbackAnswersParent'
import { Testimonial } from './Testimonial'

const FeedbackItem = styled(motion.div)({
  position: 'relative',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-start',
})

const MobileImg = styled('img')({
  width: '100%',
  height: 'auto',
  display: 'block',
})

const FeedbackListHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
  alignItems: 'center',
  marginBottom: theme.spacing(2.5),
}))

const FeedbackList = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fill, minmax(406px, 0.5fr))',
  gap: theme.spacing(2.5),
  [theme.breakpoints.down('lg')]: {
    gridTemplateColumns: 'repeat(auto-fill, minmax(406px, 1fr))',
  },
  [theme.breakpoints.down('sm')]: {
    gridTemplateColumns: 'repeat(auto-fill, minmax(100%, 1fr))',
  },
}))

const ModalFeedbackList = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2.5),
}))

const ViewAllButton = styled(Button)(({ theme }) => ({
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
}))

const QuestionPublicIcon = styled(IconButton, {
  shouldForwardProp: (prop) => prop !== 'isQuestionPublic',
})(({ theme, isQuestionPublic }) => ({
  display: 'flex',
  alignItems: 'center',
  svg: {
    path: {
      stroke: isQuestionPublic
        ? theme.palette.neutral.veryDark
        : theme.palette.error.semiDark,
    },
  },
}))

const ReportCardFeedbackList = ({
  sortedFeedback,
  title,
  isLoading,
  subTitle,
  reportData,
  shareKey,
  canShareReport,
  canUseFeedbackActions,
  canFavourite = false,
  feedbackType = null,
  setReportData,
  isCustomQuestion,
  isQuestionPublic,
  question,
  isEventOwner,
  leftNavOpen,
  handleOpenSocialShare,
  customQuestionId,
  handleToggleVisibility = () => {},
}) => {
  const downloadRef = useRef()
  const testimonialContainerRef = useRef()

  const [open, setOpen] = useState(false)
  const [feedbackDataOpen, setFeedbackDataOpen] = useState(false)
  const [favLoadingId, setFavLoadingId] = useState('')
  const [selectedTestimonial, setSelectedTestimonial] = useState(null)
  const [mobileImgSrc, setMobileImgSrc] = useState('')

  const { showFullPageLoader, hideFullPageLoader, setNotification } =
    useContext(AuthenticationContext)

  const { data, startPolling } = usePolling({
    apiMethod: Api.pollSocialShareImageStatus,
    shouldStopPolling: (data) => data.status === 'completed',
    maxTries: 20,
  })

  const theme = useTheme()

  const [itemsPerRow, setItemsPerRow] = useState(1)

  useEffect(() => {
    const fetchImageAsBase64 = async (url) => {
      try {
        const response = await fetch(url)
        const blob = await response.blob()
        const reader = new FileReader()

        reader.onloadend = () => {
          setMobileImgSrc(reader.result) // Set Base64 Data URL
        }

        reader.readAsDataURL(blob) // Convert Blob to Base64
      } catch (error) {
        setNotification(
          'Oops, looks like there was an issue downloading your image. Please try again. If the problem persists, contact us at hello@talkadot.com!',
          'error'
        )
      } finally {
        hideFullPageLoader()
      }
    }

    if (data?.status === 'completed') {
      fetchImageAsBase64(data.image_url)
      // setMobileShareOpen(true)
    } else if (data?.status === 'failed') {
      setNotification(
        'Oops, looks like there was an issue downloading your image. Please try again. If the problem persists, contact us at hello@talkadot.com!',
        'error'
      )
      hideFullPageLoader()
    }
  }, [data])

  useEffect(() => {
    if (mobileImgSrc) {
      handleOpenSocialShare(<MobileImg src={mobileImgSrc} />)
    }
  }, [mobileImgSrc])

  useEffect(() => {
    const calculateItemsPerRow = () => {
      if (testimonialContainerRef.current) {
        const containerWidth = testimonialContainerRef.current.offsetWidth
        const itemWidth = 406 // Minimum item width from minmax(406px, ...)
        const gap = 20 // Gap size (theme.spacing(2.5))

        // Account for gaps in the calculation
        const effectiveItemWidth = itemWidth + gap
        setItemsPerRow(
          Math.max(1, Math.floor((containerWidth + gap) / effectiveItemWidth))
        )
      }
    }

    // Delay calculation based on the transition duration
    const transitionDuration = leftNavOpen
      ? theme.transitions.duration.enteringScreen
      : theme.transitions.duration.leavingScreen

    const timeoutId = setTimeout(() => {
      calculateItemsPerRow()
    }, transitionDuration)

    // Also calculate immediately on resize
    window.addEventListener('resize', calculateItemsPerRow)

    return () => {
      clearTimeout(timeoutId)
      window.removeEventListener('resize', calculateItemsPerRow)
    }
  }, [
    leftNavOpen,
    theme.transitions.duration.enteringScreen,
    theme.transitions.duration.leavingScreen,
  ])

  const topFeedback = sortedFeedback?.slice(0, 9)

  const handleViewTestimonialData = (feedback) => {
    setSelectedTestimonial(feedback)
    setFeedbackDataOpen(true)
  }

  const handleMobileImageDownload = async ({ selector, feedback }) => {
    const params = {
      social_share_download: {
        share_key: shareKey,
        element_id: selector,
        answer_id: feedback.answer_id,
        custom_question_id: customQuestionId,
      },
    }

    showFullPageLoader()

    const res = await Api.getSocialShareImage(params)

    const taskId = res.task_id

    if (taskId) {
      startPolling({ task_id: taskId })
    } else {
      console.log('No task id')
      hideFullPageLoader()
    }
  }

  const handleDownloadTestimonial = async (feedback) => {
    if (isMobileOnly || window.innerWidth < 960) {
      handleMobileImageDownload({
        selector: 'testimonials-share-image',
        feedback,
      })
    } else {
      setSelectedTestimonial(feedback)
      handleOpenSocialShare(
        <TestimonialStatImage
          reportData={reportData}
          shareKey={shareKey}
          customPickedFeedback={[feedback]}
          ref={downloadRef}
        />
      )
    }
  }

  const handleToggleFavourite = async (feedback) => {
    try {
      setFavLoadingId(feedback.answer_id)

      const params = {
        answer: {
          id: feedback.answer_id,
          submission_id: feedback.submission_id,
          favourite: feedback.favourite ? !feedback.favourite : true,
        },
      }

      const res = await Api.updateAnswer(params)

      if (!res.errors) {
        setFavLoadingId('')
        setReportData(res)
      } else {
        throw res.errors
      }
    } catch (err) {
      setFavLoadingId('')
      setNotification(err)
    }
  }

  const renderInnerFeedback = ({ feedbackType, feedback, oddRow }) => {
    return (
      <Testimonial
        feedback={feedback}
        feedbackType={feedbackType}
        canShareReport={canShareReport}
        canUseFeedbackActions={canUseFeedbackActions}
        canFavourite={canFavourite}
        handleDownloadTestimonial={handleDownloadTestimonial}
        handleViewTestimonialData={handleViewTestimonialData}
        handleToggleFavourite={handleToggleFavourite}
        favLoading={favLoadingId === feedback.answer_id}
        oddRow={oddRow}
      />
    )
  }

  const spring = {
    type: 'spring',
    stiffness: 400,
    damping: 30,
  }

  const renderList = ({ list, singleColumn = false }) => {
    if (list.length === 0) {
      return <Typography variant="body1">No responses available</Typography>
    }

    return list.map((feedback, i) => {
      const rowIndex = Math.floor(i / itemsPerRow)
      const oddRow = singleColumn ? i % 2 === 0 : rowIndex % 2 === 0

      return (
        <FeedbackItem
          key={feedback.submission_id}
          layout
          feedbackType={feedbackType}
          transition={spring}>
          {renderInnerFeedback({ feedbackType, feedback, oddRow })}
        </FeedbackItem>
      )
    })
  }

  const showPublicIcon =
    isEventOwner && (isCustomQuestion || feedbackType === 'improvement')

  const canToggleVisibility = !!(isEventOwner && question && isCustomQuestion)

  return (
    <div>
      <FeedbackListHeader>
        <Typography variant="h6">{title}</Typography>
        {showPublicIcon && (
          <Tooltip
            title={
              isQuestionPublic ? 'Visible to everyone' : 'Only visible to you'
            }
            enterTouchDelay={0}
            leaveTouchDelay={3000}>
            <span
              onClick={() => {
                handleToggleVisibility(question)
              }}>
              <QuestionPublicIcon
                isQuestionPublic={isQuestionPublic}
                onClick={() => {
                  handleToggleVisibility(question)
                }}
                disabled={!canToggleVisibility}>
                {isQuestionPublic ? <EyeIcon /> : <EyeOffIcon />}
              </QuestionPublicIcon>
            </span>
          </Tooltip>
        )}
        {!isLoading && sortedFeedback?.length > topFeedback?.length && (
          <ViewAllButton
            variant="text"
            color="secondary"
            onClick={() => setOpen(true)}>
            (View All)
          </ViewAllButton>
        )}
      </FeedbackListHeader>
      {subTitle && (
        <Typography variant="h6" sx={{ marginBottom: '20px' }}>
          "{subTitle}"
        </Typography>
      )}
      <FeedbackList ref={testimonialContainerRef}>
        {isLoading ? (
          <Skeleton variant="rectangular" width={'50%'} height={50} />
        ) : (
          renderList({ list: topFeedback })
        )}
      </FeedbackList>
      <Dialog
        open={open}
        fullWidth
        maxWidth="md"
        onClose={() => setOpen(false)}>
        <IconButton
          onClick={() => setOpen(false)}
          sx={{ position: 'absolute', right: 0, top: 0 }}>
          <CloseIcon />
        </IconButton>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <ModalFeedbackList>
            {renderList({ list: sortedFeedback, singleColumn: true })}
          </ModalFeedbackList>
        </DialogContent>
      </Dialog>
      {/* This shows the metadata for the feedback */}
      <Dialog
        open={feedbackDataOpen}
        maxWidth="md"
        onClose={() => setFeedbackDataOpen(false)}>
        <IconButton
          onClick={() => setFeedbackDataOpen(false)}
          sx={{ position: 'absolute', right: 0, top: 0 }}>
          <CloseIcon />
        </IconButton>
        <FeedbackAnswersParent feedback={selectedTestimonial} />
      </Dialog>
    </div>
  )
}

export default ReportCardFeedbackList
