import { styled } from '@mui/system'
import { Skeleton, Typography } from '@mui/material'

import { ReactComponent as AttendeesIcon } from '../../icons/attendeesIcon.svg'

const StatWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
}))

const ResponseCount = styled(Typography)(({ theme }) => ({
  fontWeight: theme.fontWeights.bold,
}))

export const ResponsesStatContent = ({ totalResponses, isLoading }) => {
  return isLoading ? (
    <Skeleton width={200} height={30} />
  ) : (
    <>
      {totalResponses > 0 && (
        <StatWrapper>
          <AttendeesIcon />
          <ResponseCount variant="h3">
            {totalResponses?.toLocaleString()}
          </ResponseCount>
        </StatWrapper>
      )}
    </>
  )
}
