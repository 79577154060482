import { styled } from '@mui/system'
import { Typography } from '@mui/material'
import ReactPlayer from 'react-player/vimeo'

const StyledHeader = styled(Typography)(({ theme }) => ({
  fontWeight: theme.fontWeights.semiBold,
}))

const StyledHeaderWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(2),
}))

const CommunityLink = styled('a')(({ theme }) => ({
  color: theme.palette.secondary.main,
  fontWeight: '600',
  cursor: 'pointer',
  textDecoration: 'none',
}))

const VideoPlayerWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
})

const StyledReactPlayer = styled(ReactPlayer)(({ theme }) => ({
  paddingBottom: theme.spacing(2),
}))

const IframeMaxWidth = styled('div')({
  maxWidth: '640px',
})
const IframeWrapper = styled('div')({
  position: 'relative',
  overflow: 'hidden',
  width: '100%',
  paddingTop: '56.25%', // keep 16:9 aspect ratio
})
const StyledIframe = styled('iframe')({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  border: 'none',
})

const HeaderComponent = ({ title }) => {
  return (
    <StyledHeaderWrapper>
      <StyledHeader variant="body2">{title}</StyledHeader>
      <Typography variant="body1">
        Want more ideas?
        <CommunityLink href="https://talk.talkadot.com/sign_in" target="_blank">
          {' '}
          Head to our Talkadot Community{' '}
        </CommunityLink>
        to hear from fellow unicorn speakers.
      </Typography>
    </StyledHeaderWrapper>
  )
}

export const VideoPlayerComponent = ({ title, videoId }) => {
  return (
    <VideoPlayerWrapper>
      <HeaderComponent title={title} />
      <StyledReactPlayer
        url={`https://vimeo.com/${videoId}`}
        controls={true}
        width="640px"
      />
    </VideoPlayerWrapper>
  )
}

export const ResizableVideoPlayerComponent = ({ title, videoId }) => {
  return (
    <>
      <HeaderComponent title={title} />
      <IframeMaxWidth>
        <IframeWrapper>
          <StyledIframe
            src={`https://www.youtube.com/embed/${videoId}`}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          />
        </IframeWrapper>
      </IframeMaxWidth>
    </>
  )
}
