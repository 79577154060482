import { styled } from '@mui/system'
import { Alert, AlertTitle } from '@mui/material'

const ProfileAlert = styled(Alert)(({ theme }) => ({
  marginBottom: '20px',
  textAlign: 'left',
  '.MuiAlert-message': {
    fontWeight: theme.fontWeights.regular,
  },
  a: {
    textDecoration: 'none',
    color: theme.palette.secondary.main,
  },
}))

const ProfileSettingsAlert = ({ title, severity, children }) => {
  return (
    <ProfileAlert severity={severity}>
      <AlertTitle>{title}</AlertTitle>
      {children}
    </ProfileAlert>
  )
}

export default ProfileSettingsAlert
