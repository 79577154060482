import { styled } from '@mui/system'
import { Checkbox, FormControlLabel } from '@mui/material'

const UncheckedIcon = styled('span')(({ theme }) => ({
  borderRadius: theme.shape.borderRadius.xxs,
  width: 24,
  height: 24,
  boxShadow: `inset 0 0 0 1px ${theme.palette.border.main}, inset 0 -1px 0 ${theme.palette.border.main}`,
  backgroundColor: theme.palette.base.white,
  '.Mui-focusVisible &': {
    outline: '2px auto rgba(19,124,189,.6)',
    outlineOffset: 2,
  },
  'input:hover ~ &': {
    backgroundColor: theme.palette.surface.subtle,
  },
  // 'input:disabled ~ &': {
  //   boxShadow: 'none',
  //   background:
  //     theme.palette.mode === 'dark'
  //       ? 'rgba(57,75,89,.5)'
  //       : 'rgba(206,217,224,.5)',
  // },
}))

const CheckedIcon = styled(UncheckedIcon)({
  '&:before': {
    display: 'block',
    width: 24,
    height: 24,
    backgroundImage:
      'url(https://d3qvkz8495pjoq.cloudfront.net/checkMarkIcon_16x16.svg)',
    backgroundSize: '1rem 1rem',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    content: '""',
  },
})

const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  marginLeft: `-${theme.spacing(1)}`,
}))

const CheckBoxStyled = ({
  sx,
  checked,
  onChange,
  inputProps,
  disabled,
  label,
}) => {
  const renderCheckbox = () => {
    return (
      <Checkbox
        sx={sx}
        disableRipple
        color="default"
        checked={checked}
        disabled={disabled}
        onChange={onChange}
        inputProps={inputProps}
        icon={<UncheckedIcon />}
        checkedIcon={<CheckedIcon />}
      />
    )
  }

  if (label) {
    return (
      <StyledFormControlLabel
        disabled={disabled}
        control={renderCheckbox()}
        label={label}
      />
    )
  }

  return renderCheckbox()
}

export default CheckBoxStyled
