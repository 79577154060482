import { styled } from '@mui/system'

import { TextField, Typography } from '@mui/material'

// Note:
// If we upgrade material-ui, this functionality is built into the standard Button
import LoadingButton from '@mui/lab/LoadingButton'

export const StyledTextField = styled(TextField, {
  shouldForwardProp: (prop) => prop !== 'withAdornment',
})(({ theme, withAdornment }) => ({
  '& .MuiOutlinedInput-root': {
    borderRadius: theme.shape.borderRadius.sm,
    paddingLeft: withAdornment && '0px'
  },
  marginTop: theme.spacing(1.5),
  marginBottom: theme.spacing(1.5),
  '& .MuiInputAdornment-root': {
    backgroundColor: theme.palette.neutral.extraLight,
    // Make sure this matches the textfield padding
    padding: '28px 14px',
    borderTopLeftRadius: theme.shape.borderRadius.sm,
    borderBottomLeftRadius: theme.shape.borderRadius.sm,
  },
  '& .Mui-focused': {
    '& .MuiInputAdornment-root': {
      transition: '200ms all ease-in-out',
      backgroundColor: theme.palette.primary.extraLight,
      color: theme.palette.primary.main,
    },
  },
}))

export const MainContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(5),
  [theme.breakpoints.down('md')]: {
    gap: theme.spacing(0),
  },
  [theme.breakpoints.down('sm')]: { 
    margin: theme.spacing(0, -3),
  },
}))

export const SecondaryContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  height: 'min-content',
  padding: theme.spacing(5, 2.5),
  backgroundColor: theme.palette.base.white,
}))

export const HeaderContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  paddingLeft: theme.spacing(2.5),
  paddingRight: theme.spacing(0.75),
  minHeight: theme.spacing(5.5),
  background: theme.palette.surface.subtle,
  borderTopLeftRadius: theme.shape.borderRadius.xxs,
  borderTopRightRadius: theme.shape.borderRadius.xxs,
}))

export const FormContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  border: `1px solid ${theme.palette.border.main}`,
  borderRadius: theme.shape.borderRadius.xxs,
}))

export const FormContainerInner = styled('div')(({ theme }) => ({
  display: 'flex',
  padding: '1.25rem',
  marginBottom: theme.spacing(2),
  position: 'relative',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
}))

export const HeaderSubtext = styled(Typography)(({ theme }) => ({
  flex: 1,
  color: theme.palette.text.hint,
  fontWeight: theme.fontWeights.semiBold,
  marginRight: theme.spacing(1),
  span: {
    color: theme.palette.text.link,
  },
  svg: {
    path: {
      stroke: theme.palette.neutral.darkest,
    },
  },
}))

export const NavActionContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: theme.spacing(3, 0),
}))

export const NavActionButton = styled(LoadingButton)(({ theme, disabled }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: theme.spacing(1),
  color: theme.palette.base.white,
  svg: {
    minWidth: '16px',
    path: {
      stroke: disabled
        ? theme.palette.action.disabled
        : theme.palette.base.white,
    },
  },
  [theme.breakpoints.down('md')]: {
    '& .button-text': {
      display: 'none',
    },
  },
}))

export const StepNumber = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '42px',
  height: '42px',
  padding: '0.625rem',
  marginRight: theme.spacing(3),
  marginBottom: theme.spacing(3),
  backgroundColor: theme.palette.primary.main,
  borderRadius: theme.shape.borderRadius.xs,
  h5: {
    color: theme.palette.base.white,
  },
}))

export const FormContentContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
})

export const LinkButton = styled(Typography)(({ theme }) => ({
  cursor: 'pointer',
  color: theme.palette.secondary.main,
}))

export const CharacterCounter = styled(Typography)(({ theme, isError }) => ({
  color: isError ? theme.palette.error.main : theme.palette.text.secondary,
  position: 'absolute',
  bottom: -20,
  right: 0,
}))

const TitleContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  minHeight: '39px',
}))

const Title = styled(Typography)(({ theme }) => ({
  fontWeight: theme.fontWeights.semiBold,
}))

const Caption = styled(Typography)(({ theme }) => ({
  color: theme.palette.neutral.main
}))

const FormSectionTitle = ({ title, subTitle, caption }) => {
  return (
    <TitleContainer>
      <Title variant="body2">{title}</Title>
      {subTitle && <Typography variant="body1">{subTitle}</Typography>}
      {caption && <Caption variant="paragraph00">{caption}</Caption>}
    </TitleContainer>
  )
}

export const FullFormSection = ({ stepNumber, title, subTitle, caption, children }) => (
  <FormContainerInner>
    <StepNumber>
      <Typography variant="h5">{stepNumber}</Typography>
    </StepNumber>
    <FormContentContainer>
      <FormSectionTitle title={title} subTitle={subTitle} caption={caption} />
      {children}
    </FormContentContainer>
  </FormContainerInner>
)

export const HeaderSubtextContainer = ({ headerSubtext }) => (
  <HeaderContainer>
    <HeaderSubtext variant="body1">{headerSubtext}</HeaderSubtext>
  </HeaderContainer>
)