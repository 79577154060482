import { styled } from '@mui/system'

import { IconButton, Tooltip, Button, InputAdornment } from '@mui/material'

import { FullFormSection, StyledTextField } from '../../common/FormComponents'
import { ReactComponent as CloseIcon } from '../../../icons/closeIcon.svg'
import { ReactComponent as PlusIcon } from '../../../icons/plusIcon.svg'

import { MAXIMUM_OUTCOMES } from './speechTopicValidators'

const OutcomesContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
  width: '100%',
  padding: theme.spacing(2, 0),
}))

const OutcomeField = styled('div')(({ theme }) => ({
  display: 'flex',
  minHeight: '42px',
  alignItems: 'center',
  gap: theme.spacing(1.5),
  width: '100%',
}))

const OutcomeFieldContent = styled('div')(({ theme }) => ({
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  flex: 1,
}))

const AppendLearningOutcomeButton = styled(Button)(({ theme }) => ({
  height: '42px',
  maxWidth: '200px',
  backgroundColor: theme.palette.base.white,
  borderRadius: theme.shape.borderRadius.xs,
  color: theme.palette.neutral.darkest,
  marginTop: theme.spacing(2),
  svg: {
    path: {
      stroke: theme.palette.neutral.darkest,
    },
  },
  [theme.breakpoints.down('md')]: {
    marginRight: theme.spacing(1),
    minWidth: '32px',
    paddingLeft: '8px',
    paddingRight: '8px',
    '& .button-text': {
      display: 'none',
    },
    '& .MuiButton-startIcon': { margin: 0 },
  },
}))

const SpeechTopicOutcomeRow = ({ outcome, index, handleOutcomeChange, handleRemoveOutcome, errors, validateOutcomes, speechTopic, setErrors }) => {
  return (
    <OutcomeField key={index}>
      <OutcomeFieldContent>
        <StyledTextField
          fullWidth
          variant="outlined"
          withAdornment={true}
          value={outcome}
          onChange={(e) => handleOutcomeChange(index, e.target.value)}
          placeholder={`Outcome ${index + 1}`}
          error={!!errors.outcomes[index]}
          helperText={errors.outcomes[index]}
          onBlur={() => validateOutcomes(speechTopic, errors, setErrors)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <div>Outcome {index + 1}</div>
              </InputAdornment>
            ),
          }}
        />
      </OutcomeFieldContent>
      {
          // Don't allow deleting if there's only 1 outcome
          speechTopic.outcomes.length > 1 && (
          <Tooltip title="Remove this outcome" disableInteractive>
            <IconButton onClick={() => handleRemoveOutcome(index)}>
              <CloseIcon />
            </IconButton>
          </Tooltip>
        )
      }
    </OutcomeField>
  )
}

const SpeechTopicOutcomesForm = ({ speechTopic, setSpeechTopic, errors, setErrors, validateOutcomes }) => {
  const handleAddOutcome = () => {
    if (speechTopic.outcomes.length === MAXIMUM_OUTCOMES) return

    setSpeechTopic((prev) => ({
      ...prev,
      outcomes: [...prev.outcomes, ''],
    }))
  }

  const handleRemoveOutcome = (index) => {
    setSpeechTopic((prev) => ({
      ...prev,
      outcomes: prev.outcomes.filter((_, i) => i !== index),
    }))
  }

  const handleOutcomeChange = (index, value) => {
    setErrors(prev => ({
      ...prev,
      outcomes: prev.outcomes.map((error, i) => 
        i === index ? '' : error
      )
    }));

    setSpeechTopic((prev) => ({
      ...prev,
      outcomes: prev.outcomes.map((outcome, i) =>
        i === index ? value : outcome
      ),
    }))
  }

  return (
    <FullFormSection
      title="What are the key outcomes?"
      stepNumber="4"
      caption="You can add up to 6 key outcomes">
      <OutcomesContainer>
        {speechTopic.outcomes.map((outcome, index) => (
          <SpeechTopicOutcomeRow
            key={index}
            outcome={outcome}
            index={index}
            handleOutcomeChange={handleOutcomeChange}
            handleRemoveOutcome={handleRemoveOutcome}
            errors={errors}
            validateOutcomes={validateOutcomes}
            speechTopic={speechTopic}
            setErrors={setErrors}
          />
        ))}
        {speechTopic.outcomes.length < MAXIMUM_OUTCOMES && (
          <AppendLearningOutcomeButton
            variant="outlined"
            color="tertiary"
            startIcon={<PlusIcon />}
            onClick={handleAddOutcome}>
            <span className="button-text">Add an outcome</span>
          </AppendLearningOutcomeButton>
        )}
      </OutcomesContainer>
    </FullFormSection>
  )
}

export default SpeechTopicOutcomesForm