import CustomQuestionMultipleChoice from './CustomQuestionMultipleChoice'
import CustomQuestionEmojiScale from './CustomQuestionEmojiScale'
import CustomQuestionBoolean from './CustomQuestionBoolean'
import CustomQuestionNps from './CustomQuestionNps'

export const renderCustomQuestionContent = (question, socialShare = false) => {
  switch (question.question_type) {
    case 'MULTIPLE_CHOICE':
      return <CustomQuestionMultipleChoice question={question} />
    case 'EMOJI_SCALE':
      return (
        <CustomQuestionEmojiScale
          question={question}
          socialShare={socialShare}
        />
      )
    case 'BOOLEAN':
      return <CustomQuestionBoolean question={question} />
    case 'NPS':
      return <CustomQuestionNps question={question} socialShare={socialShare} />
    default:
      return null
  }
}
