import { useTheme } from '@mui/system'
import { styled } from '@mui/system'
import { TableRow, TableCell, Button } from '@mui/material'

import { ReactComponent as EyeIcon } from '../../icons/eyeIcon_16x16.svg'
import { ReactComponent as EditIcon } from '../../icons/editIcon_16x16.svg'
import { ReactComponent as FilterIcon } from '../../icons/filterIcon_16x16.svg'
import { ReactComponent as ChatIcon } from '../../icons/chatBubbleSquareIcon_16x16.svg'
import { ReactComponent as MergeIcon } from '../../icons/mergeIcon_16x16.svg'
import { ReactComponent as RefreshIcon } from '../../icons/refreshIcon_16x16.svg'
import { ReactComponent as TrashIcon } from '../../icons/trash2Icon_16x16.svg'

import {
  ActionsTableCell,
  CheckboxTableCell,
  TableCellContent,
  TableCellSecondaryContent,
} from '../common/TableComponents'
import CheckBoxStyled from '../common/CheckBoxStyled'
import TableChip from '../common/TableChip'
import TableActionsDropdown from '../common/TableActionsDropdown'
import TableCellStyled from '../common/TableCellStyled'
import { VideoPlayerComponent } from '../common/VideoPlayerComponent'
import CreateCodeAlert from '../common/CreateCodeAlert'

import { renderCurrency, renderPercentage } from '../../utils/number'

const VideoTableRow = styled(TableRow)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}))

const TalksTableRowSpeakerPlaceholder = ({ handleOpenEventForm }) => {
  const theme = useTheme()
  const color = theme.palette.neutral.light
  const menuItems = [
    {
      text: 'View Report',
      icon: <EyeIcon />,
      onClick: () => {},
      visible: true,
      disabled: true,
    },
    {
      text: 'Edit Event',
      icon: <EditIcon />,
      onClick: () => {},
      visible: true,
      disabled: true,
    },
    {
      text: 'Download Leads',
      icon: <FilterIcon />,
      onClick: () => {},
      visible: true,
      disabled: true,
    },
    {
      text: 'Download Testimonials',
      icon: <ChatIcon />,
      onClick: () => {},
      visible: true,
      disabled: true,
    },
    {
      text: 'Sync Leads With Speakerflow',
      icon: <RefreshIcon />,
      onClick: () => {},
      visible: true,
      disabled: true,
    },
    {
      text: 'Merge Events',
      icon: <MergeIcon />,
      onClick: () => {},
      visible: true,
      disabled: true,
    },
    {
      text: 'Delete Event',
      icon: <TrashIcon />,
      onClick: () => {},
      visible: true,
      disabled: true,
    },
  ]

  return (
    <>
      <TableRow>
        <CheckboxTableCell>
          <CheckBoxStyled checked={false} disabled />
        </CheckboxTableCell>
        <TableCellStyled textWrap="nowrap" width="115px" color={color}>
          Jan 18, 2024
        </TableCellStyled>
        <TableCellStyled color={color} minWidth="160px">
          <TableCellContent>
            Your talk title
            <TableCellSecondaryContent>
              <TableChip text="YOUR CODE" color="grey" />
            </TableCellSecondaryContent>
          </TableCellContent>
        </TableCellStyled>
        <TableCellStyled color={color} width="20%" displayBreakpoint="md">
          Organization
        </TableCellStyled>
        <TableCellStyled
          color={color}
          align="right"
          width="5%"
          displayBreakpoint="lg">
          {renderCurrency(5000)}
        </TableCellStyled>
        <TableCellStyled
          color={color}
          align="center"
          width="5%"
          displayBreakpoint="lg">
          {renderPercentage(93)}
        </TableCellStyled>
        <TableCellStyled
          color={color}
          align="center"
          width="5%"
          displayBreakpoint="sm">
          42
        </TableCellStyled>
        <TableCellStyled
          color={color}
          align="center"
          width="5%"
          displayBreakpoint="sm">
          38
        </TableCellStyled>
        <ActionsTableCell align="center">
          <TableActionsDropdown menuItems={menuItems} />
        </ActionsTableCell>
      </TableRow>
      <TableRow>
        <TableCell colSpan={2} />
        <TableCellStyled textWrap="nowrap">
          <Button
            variant="text"
            color="secondary"
            onClick={handleOpenEventForm}>
            Add a talk!
          </Button>
        </TableCellStyled>
        <TableCell colSpan={7} />
      </TableRow>
      <VideoTableRow>
        <TableCell colSpan={10} align="center">
          <CreateCodeAlert />
          <VideoPlayerComponent
            title="Watch this video on how to successfully tee up your talk!"
            videoId="1051775817"
          />
        </TableCell>
      </VideoTableRow>
    </>
  )
}

export default TalksTableRowSpeakerPlaceholder
