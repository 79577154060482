import { useEffect, useState, useContext, useCallback } from 'react'
import { useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { styled } from '@mui/system'
import { isMobileOnly } from 'react-device-detect'
import { Typography, Dialog, IconButton } from '@mui/material'

import CloseIcon from '@mui/icons-material/Close'

import Api from '../../services/api'
import { AuthenticationContext } from '../authentication/authenticationContext'
import { formatDate } from '../common/helpers'

import NavBreadCrumbs from '../nav/NavBreadCrumbs'
import TotalAttendeesStat from './TotalAttendeesStat'
import TalkValueStat from './TalkValueStat'
import TalkReattendStat from './TalkReattendStat'
import TalkEngagementStat from './TalkEngagementStat'
import Banner from './Banner'
import ReportCardFeedbackList from './ReportCardFeedbackList'
import SpeakerHeader from '../speakerProfile/SpeakerHeader'
import ShareEventReportCard from './ShareEventReportCard'
import EventPlannerEngagement from './EventPlannerEngagement'
import CustomQuestion from './CustomQuestion'
import FeedbackSummaryParent from './FeedbackSummaryParent'
import StatCard from '../common/StatCard'
import PageContainer from '../common/PageContainer'
import CustomQuestionUpsell from './CustomQuestionUpsell'
import ConfirmDialog from '../common/ConfirmDialog'

import './EventReportCard.scss'

const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
}))

const ReportCardContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(3),
  backgroundColor: theme.palette.base.white,
  [theme.breakpoints.down('sm')]: {
    margin: theme.spacing(0, -3),
  },
}))

const SpeakerHeaderContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  marginBottom: theme.spacing(2.5),
  [theme.breakpoints.down('sm')]: {
    alignItems: 'center',
  },
}))

const CustomQuestionContainer = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fill, minmax(406px, 0.5fr))',
  gridAutoFlow: 'dense',
  gap: theme.spacing(2.5),
  margin: theme.spacing(2.5, 0),
  [theme.breakpoints.down('lg')]: {
    gridTemplateColumns: 'repeat(auto-fill, minmax(406px, 1fr))',
  },
  [theme.breakpoints.down('sm')]: {
    gridTemplateColumns: 'repeat(auto-fill, minmax(100%, 1fr))',
  },
}))

const FreeFlowCustomQuestionContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
  marginTop: theme.spacing(2.5),
}))

const HorizontalLine = styled('hr')(({ theme }) => ({
  width: '100%',
  border: 'none',
  borderBottom: `1px solid ${theme.palette.neutral.extraLight}`,
  margin: theme.spacing(6, 0),
}))

const initialReportDataState = {
  speaker: {
    id: null,
    name: null,
    avatar: null,
    isEventPlanner: null,
  },
  id: null,
  talkTitle: null,
  organization: null,
  date: null,
  totalResponses: null,
  ratingsBreakdown: {},
  feedback: [],
  improvement: [],
  takeaways: [],
  reattendBreakdown: {},
  isEventOwner: false,
  engagementBreakdown: [],
  speakers: [],
  eventType: null,
  isUserEvent: null,
  showBranding: null,
  access: {
    isEventOwner: null,
    isEventSpeaker: null,
    canShare: null,
  },
}

const initialConfirmProps = {
  title: '',
  onConfirm: () => {},
  onCancel: () => {},
  children: '',
}

const DataSummaryContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(2.5),
  flexWrap: 'wrap',
  justifyContent: 'center',
  margin: theme.spacing(2.5, 0),
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
}))

const EventReportCardParent = ({ leftNavOpen }) => {
  let params = useParams()
  const [isLoading, setIsLoading] = useState(false)
  const [customQuestionsLoading, setCustomQuestionsLoading] = useState(false)
  const [reportData, setReportData] = useState(initialReportDataState)
  const [customQuestions, setCustomQuestions] = useState([])
  const [showCustomContentUpsell, setShowCustomContentUpsell] = useState(false)
  const [socialShareOpen, setSocialShareOpen] = useState(false)
  const [socialShareContent, setSocialShareContent] = useState(null)
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false)
  const [confirmProps, setConfirmProps] = useState(initialConfirmProps)
  const { setNotification, authData } = useContext(AuthenticationContext)

  const { user } = authData

  useEffect(() => {
    const loadReportCardData = async () => {
      if (!params.sharelink) {
        return setNotification('Sorry, your request is invalid.')
      }

      const reqParams = {
        event_report: {
          share_key: params.sharelink,
        },
      }

      try {
        setIsLoading(true)
        const res = await Api.getEventReportCard(reqParams)
        if (!res.errors) {
          setReportData(res)
          setIsLoading(false)
        } else {
          throw res.errors
        }
      } catch (err) {
        setIsLoading(false)
        setNotification(err)
      }
    }

    loadReportCardData()
  }, [params.sharelink]) // WARNING: setNotification is purposely excluded because it causes unexpecte rerenders

  useEffect(() => {
    const loadCustomAnswers = async () => {
      if (!params.sharelink) {
        return
      }

      try {
        setCustomQuestionsLoading(true)

        const reqParams = {
          event_report: {
            share_key: params.sharelink,
          },
        }

        const res = await Api.getCustomAnswers(reqParams)

        if (!res.errors) {
          setShowCustomContentUpsell(res.showUpsell)
          setCustomQuestions(res.customQuestions)
          setCustomQuestionsLoading(false)
        } else {
          throw res.errors
        }
      } catch (err) {
        setCustomQuestionsLoading(false)
      }
    }

    if (reportData.id) {
      loadCustomAnswers()
    }
  }, [params.sharelink, reportData.id, user?.id])

  const isEventOwner = reportData?.access?.isEventOwner
  const canShareReport = reportData?.access?.canShare
  const canUseFeedbackActions = reportData?.access?.canUseFeedbackActions
  const isUserEvent = reportData?.isUserEvent

  const _handleToggleQuestionVisibility = async (question) => {
    try {
      const data = {
        answer_id: question.answers[0].answer_id,
      }
      const res = await Api.toggleVisibility(question.id, data)

      if (!res.errors) {
        setCustomQuestions(
          customQuestions.map((q) =>
            q.id === question.id
              ? { ...q, isQuestionPublic: !q.isQuestionPublic }
              : q
          )
        )

        const message = question.isQuestionPublic
          ? 'Responses for this question are now hidden on all reports.'
          : 'Responses for this question are now viewable on all reports.'

        setNotification(message, 'success')
      } else {
        throw res.errors
      }
    } catch (err) {
      setNotification(err)
    }
  }

  const handleToggleSummaryVisibility = useCallback(async () => {
    if (!params.sharelink) return
    try {
      const reqParams = {
        event_report_card_summary: {
          share_key: params.sharelink,
        },
      }
      const res = await Api.toggleEventReportCardSummaryVisibility(reqParams)

      if (!res.errors) {
        setReportData(res)
      } else {
        throw res.errors
      }
    } catch (err) {
      setNotification(err)
    }
  }, [params.sharelink, setNotification])

  const filterFeedback = (feedback) => {
    if (isEventOwner) return feedback

    return feedback.filter((feedback) => feedback.public_share === true)
  }

  const nonFreeFlowCustomQuestions = customQuestions.filter(
    (question) =>
      question.question_type && question.question_type !== 'FREE_FLOW'
  )

  // NOTE: The second part of the OR is to handle legacy custom questions
  const freeFlowCustomQuestions = customQuestions.filter(
    (question) =>
      question.question_type === 'FREE_FLOW' ||
      (!question.question_type && question.content)
  )

  const filterCustomQuestions = (customQuestions) => {
    if (isEventOwner) return customQuestions

    return customQuestions.filter(
      (question) => question.isQuestionPublic === true
    )
  }

  const handleToggleQuestionVisibility = async (question) => {
    if (question.isQuestionPublic) {
      await _handleToggleQuestionVisibility(question)
    } else {
      // NOTE: Questions that have been detached from a survey will have a null offer_code
      const offerCodeElement = question.offerCode ? (
        <strong>{question.offerCode}</strong>
      ) : (
        'this question'
      )
      const warning = (
        <>
          Making this question visible will also show it in every other report
          using code: {offerCodeElement}.
        </>
      )

      setConfirmProps({
        variant: 'info',
        hideDangerMessage: true,
        title: 'Make Question Visible',
        buttonText: 'Make visible',
        buttonColor: 'primary',
        cancelButtonText: 'Cancel',
        buttonOrder: 'confirmFirst',
        textColor: 'white',
        onConfirm: () => _handleToggleQuestionVisibility(question),
        onCancel: () => {},
        children: <Typography variant="body1">{warning}</Typography>,
      })
      setConfirmDialogOpen(true)
    }
  }

  const renderEmptyReport = () => (
    <div className="event-report-card__empty-report-text">
      <Typography variant="h4">
        <b>Your attendee feedback will appear here!</b>
      </Typography>
      <br />
      <Typography variant="h5">
        Make sure to have your Talkadot Slide (or QR code) added to the last
        page of your talk to collect feedback.
      </Typography>
    </div>
  )

  const breadCrumbs = [
    {
      link: '/talks',
      text: 'Talks',
    },
    {
      text: reportData.talkTitle || 'Event Report',
    },
  ]

  const handleOpenSocialShare = async (content) => {
    setSocialShareContent(content)
    setSocialShareOpen(true)
  }

  const handleCloseSocialShare = () => {
    setSocialShareOpen(false)
    setSocialShareContent(null)
  }

  const renderNonFreeFlowCustomQuestions = () => {
    if (showCustomContentUpsell) {
      return <CustomQuestionUpsell />
    } else if (filterCustomQuestions(nonFreeFlowCustomQuestions).length > 0) {
      return (
        <>
          <HorizontalLine />
          <CustomQuestionContainer>
            {filterCustomQuestions(nonFreeFlowCustomQuestions).map(
              (customQuestion, index) => (
                <CustomQuestion
                  key={index}
                  question={customQuestion}
                  isLoading={customQuestionsLoading}
                  isEventOwner={isEventOwner}
                  handleOpenSocialShare={handleOpenSocialShare}
                  reportData={reportData}
                  shareKey={params.sharelink}
                  handleToggleVisibility={handleToggleQuestionVisibility}
                />
              )
            )}
          </CustomQuestionContainer>
        </>
      )
    }
  }

  return (
    <PageContainer maxWidth={1440}>
      <Container>
        <Helmet>
          <meta name="robots" content="noindex" />
        </Helmet>
        {canShareReport && (
          <NavBreadCrumbs breadCrumbs={breadCrumbs}></NavBreadCrumbs>
        )}
        <ReportCardContainer>
          <SpeakerHeaderContainer>
            {isUserEvent && (
              <SpeakerHeader id={null} shareKey={params.sharelink} />
            )}
            {canShareReport && (
              <ShareEventReportCard
                shareKey={params.sharelink}
                sortedFeedback={reportData.takeaways}
                reportData={reportData}
                isLoading={isLoading}
                handleOpenSocialShare={handleOpenSocialShare}
              />
            )}
          </SpeakerHeaderContainer>
          <Banner
            speaker={reportData.speaker}
            talkTitle={reportData.talkTitle}
            organization={reportData.organization}
            date={formatDate(reportData.date, reportData.utcOffset)}
            speakers={reportData.speakers}
          />
          {!isLoading && !reportData.totalResponses ? (
            renderEmptyReport()
          ) : (
            <DataSummaryContainer>
              <StatCard type="responses" minWidth="224px">
                <TotalAttendeesStat
                  totalResponses={reportData.totalResponses}
                  isLoading={isLoading}
                />
              </StatCard>
              <StatCard
                type="valuable"
                ratingsBreakdown={reportData.ratingsBreakdown}
                eventType={reportData.eventType}>
                <TalkValueStat
                  ratingsBreakdown={reportData.ratingsBreakdown}
                  isLoading={isLoading}
                  eventType={reportData.eventType}
                />
              </StatCard>
              <StatCard
                type="reattend"
                reattendBreakdown={reportData.reattendBreakdown}
                eventType={reportData.eventType}
                speakerName={reportData.speaker?.name}>
                <TalkReattendStat
                  reattendBreakdown={reportData.reattendBreakdown}
                  isLoading={isLoading}
                  speaker={reportData.speaker}
                  eventType={reportData.eventType}
                />
              </StatCard>
              {
                /* TODO: Update this logic once we add event planner tooling to main app */
                reportData?.speaker?.isEventPlanner &&
                reportData.eventType?.toUpperCase() !== 'KEYNOTE' ? (
                  <EventPlannerEngagement
                    engagementBreakdown={reportData.engagementBreakdown}
                    isLoading={isLoading}
                  />
                ) : (
                  reportData.engagementBreakdown?.length > 0 && (
                    <StatCard type="emojis" minWidth="260px">
                      <TalkEngagementStat
                        engagementBreakdown={reportData.engagementBreakdown}
                        isLoading={isLoading}
                      />
                    </StatCard>
                  )
                )
              }
            </DataSummaryContainer>
          )}
          {!!reportData.totalResponses && (
            <>
              <FeedbackSummaryParent
                initialSummaryData={reportData.summary}
                speakerName={reportData.speaker?.name}
                shareKey={params.sharelink}
                toggleVisibility={handleToggleSummaryVisibility}
                isPublic={reportData.aiSummaryVisible}
                hasEnoughResponses={reportData.hasEnoughResponsesForAISummary}
                hasNewResponses={reportData.hasNewResponsesNotAISummarized}
                isEventOwner={isEventOwner}
                isUserEvent={isUserEvent}
              />
              {renderNonFreeFlowCustomQuestions()}
              <div>
                {filterFeedback(reportData.takeaways).length > 0 && (
                  <>
                    <HorizontalLine />
                    <ReportCardFeedbackList
                      sortedFeedback={filterFeedback(reportData.takeaways)}
                      title="How attendees describe this talk"
                      isLoading={isLoading}
                      reportData={reportData}
                      shareKey={params.sharelink}
                      canShareReport={canShareReport}
                      canUseFeedbackActions={canUseFeedbackActions}
                      setReportData={setReportData}
                      canFavourite={true}
                      leftNavOpen={leftNavOpen}
                      handleOpenSocialShare={handleOpenSocialShare}
                    />
                  </>
                )}
                {!reportData?.speaker?.isEventPlanner &&
                  filterFeedback(reportData.feedback).length > 0 && (
                    <>
                      <HorizontalLine />
                      <ReportCardFeedbackList
                        sortedFeedback={filterFeedback(reportData.feedback)}
                        title={`Feedback for ${reportData.speaker.name} and the event`}
                        isLoading={isLoading}
                        reportData={reportData}
                        shareKey={params.sharelink}
                        canShareReport={canShareReport}
                        canUseFeedbackActions={canUseFeedbackActions}
                        setReportData={setReportData}
                        canFavourite={true}
                        leftNavOpen={leftNavOpen}
                        handleOpenSocialShare={handleOpenSocialShare}
                      />
                    </>
                  )}
              </div>
            </>
          )}

          {/* TODO: Fix mobile social share for these */}
          {filterCustomQuestions(freeFlowCustomQuestions).length > 0 && (
            <>
              <HorizontalLine />
              <FreeFlowCustomQuestionContainer>
                {filterCustomQuestions(freeFlowCustomQuestions).map(
                  (customQuestion, index) => (
                    <ReportCardFeedbackList
                      key={index}
                      sortedFeedback={customQuestion.answers?.filter(
                        (answer) => answer.text
                      )}
                      title={customQuestion.content}
                      isCustomQuestion={true}
                      question={customQuestion}
                      isQuestionPublic={customQuestion.isQuestionPublic}
                      isLoading={customQuestionsLoading}
                      reportData={reportData}
                      shareKey={params.sharelink}
                      canShareReport={canShareReport}
                      canUseFeedbackActions={canUseFeedbackActions}
                      isEventOwner={isEventOwner}
                      leftNavOpen={leftNavOpen}
                      handleOpenSocialShare={handleOpenSocialShare}
                      customQuestionId={customQuestion.id}
                      handleToggleVisibility={handleToggleQuestionVisibility}
                    />
                  )
                )}
              </FreeFlowCustomQuestionContainer>
            </>
          )}
          {reportData?.improvement.length > 0 && (
            <>
              <HorizontalLine />
              <ReportCardFeedbackList
                sortedFeedback={reportData.improvement}
                title="Suggestions For Improvement"
                feedbackType="improvement"
                reportData={reportData}
                shareKey={params.sharelink}
                canShareReport={canShareReport}
                canUseFeedbackActions={canUseFeedbackActions}
                isEventOwner={isEventOwner}
                leftNavOpen={leftNavOpen}
              />
            </>
          )}
        </ReportCardContainer>
      </Container>
      <Dialog
        open={socialShareOpen}
        onClose={handleCloseSocialShare}
        aria-labelledby="social-share-dialog"
        fullwidth="true"
        maxWidth="xl">
        {!isMobileOnly && (
          <IconButton
            onClick={() => setSocialShareOpen(false)}
            sx={{ position: 'absolute', right: 8, top: 8, zIndex: '100' }}>
            <CloseIcon />
          </IconButton>
        )}
        {socialShareContent}
      </Dialog>
      <ConfirmDialog
        open={confirmDialogOpen}
        setOpen={setConfirmDialogOpen}
        onClose={() => {
          setConfirmDialogOpen(false)
        }}
        variant={confirmProps.variant}
        hideDangerMessage={confirmProps.hideDangerMessage}
        onCancel={confirmProps.onCancel}
        showCancel={confirmProps.showCancel}
        cancelButtonText={confirmProps.cancelButtonText}
        title={confirmProps.title}
        buttonText={confirmProps.buttonText}
        buttonColor={confirmProps.buttonColor}
        textColor={confirmProps.textColor}
        onConfirm={confirmProps.onConfirm}
        buttonOrder={confirmProps.buttonOrder}>
        {confirmProps.children}
      </ConfirmDialog>
    </PageContainer>
  )
}

export default EventReportCardParent
