import { exportComponentAsJPEG } from 'react-component-export-image'
import domtoimage from '../../utils/domToImage'
import envConfig from '../../envConfig'
import moment from 'moment'

export const handleCopy = (content) => {
  window.navigator.clipboard.writeText(content)
}

export const formatDate = (date, offset) => {
  // return date
  if (!offset) {
    // Default to PST if no offset is set
    offset = -420
  }

  // return moment(date).utcOffset(offset).format('MMM D, YYYY')
  return moment(date).format('MMM D, YYYY')
}

// dateString should be the datetime string output from the backend
export const formatDateWithTimeZone = (
  dateString,
  timeZone = 'America/New_York'
) => {
  const date = new Date(dateString)

  // Options for the date part
  const dateOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    timeZone,
  }
  const datePart = new Intl.DateTimeFormat('en-US', dateOptions).format(date)

  const timeOptions = {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
    timeZone,
  }
  const timePart = new Intl.DateTimeFormat('en-US', timeOptions).format(date)

  // Combine date and time parts
  const formattedDate = `${datePart} at ${timePart} EST`

  return formattedDate
}

export const formatTime = (timestamp) => {
  const momentTimestamp = moment(timestamp).format('h:mm:ss')
  return momentTimestamp
}

export const timeDifferenceInDays = (d0, d1) => {
  const diffTime = Math.abs(d0 - d1)
  return Math.ceil(diffTime / (1000 * 60 * 60 * 24))
}

export const downloadSlideAsPng = (componentRef, fileName, callback) => {
  // Note: calling domtoimage twice fixes an issue with Safari and iOS not rendering the image properly
  // Link below is for a different image conversion package but has the same issue
  // https://github.com/tsayen/dom-to-image/issues/343
  domtoimage
    .toPng(componentRef.current, {
      width: 960,
      height: 540,
      quality: 1.0,
      bgcolor: 'white',
      cacheBust: true,
    })
    .then((dataUrl) =>
      domtoimage.toPng(componentRef.current, {
        width: 960,
        height: 540,
        quality: 1.0,
        bgcolor: 'white',
        cacheBust: true,
      })
    )
    .then((dataUrl2) => {
      var link = document.createElement('a')
      link.download = `${fileName}.png`
      link.href = dataUrl2
      link.click((e) => e.preventDefault())
    })
    .then(() => {
      if (callback) {
        callback()
      }
    })
}

export const downloadComponentAsPng = async (componentRef, fileName) => {
  domtoimage
    .toPng(componentRef.current, {
      width: 844,
      height: 869,
      quality: 1.0,
      bgcolor: 'white',
      cacheBust: true,
    })
    .then((dataUrl) =>
      domtoimage.toPng(componentRef.current, {
        width: 844,
        height: 869,
        quality: 1.0,
        bgcolor: 'white',
        cacheBust: true,
      })
    )
    .then((dataUrl2) => {
      var link = document.createElement('a')
      link.download = `${fileName}.png`
      link.href = dataUrl2
      link.click((e) => e.preventDefault())
    })
}

export const downloadInvisibleComponent = (
  componentRef,
  componentId,
  fileName
) => {
  return exportComponentAsJPEG(componentRef, {
    fileName,
    html2CanvasOptions: {
      onclone: (clonedDoc) => {
        clonedDoc.getElementById(componentId).style.visibility = 'visible'
        var svgElements = clonedDoc.body.querySelectorAll('svg')

        svgElements.forEach(function (item) {
          item.setAttribute('width', item.getBoundingClientRect().width)
          item.setAttribute('height', item.getBoundingClientRect().height)
        })
      },
    },
  })
}

export const downloadShareCodeAsPng = (componentRef, fileName) => {
  // Note: calling domtoimage twice fixes an issue with Safari and iOS not rendering the image properly
  // Link below is for a different image conversion package but has the same issue
  // https://github.com/tsayen/dom-to-image/issues/343
  domtoimage
    .toPng(componentRef.current, {
      style: {
        margin: '10px',
      },
      width: 230,
      height: 230,
      quality: 1.0,
      bgcolor: 'white',
      cacheBust: true,
      pixelRatio: 2,
    })
    .then((dataUrl) =>
      domtoimage.toPng(componentRef.current, {
        style: {
          margin: '10px',
        },
        width: 230,
        height: 230,
        quality: 1.0,
        bgcolor: 'white',
        cacheBust: true,
        pixelRatio: 2,
      })
    )
    .then((dataUrl2) => {
      var link = document.createElement('a')
      link.download = `${fileName}.png`
      link.href = dataUrl2
      link.click((e) => e.preventDefault())
    })
}

export const removeEmptysAndNulls = (obj) => {
  for (var propName in obj) {
    if (obj[propName] === null || obj[propName] === undefined) {
      delete obj[propName]
    }
  }
  return obj
}

export const makeSurveyLink = ({ shortlink, code, test = false }) => {
  let baseUrl = `${envConfig.REACT_APP_SURVEY_ENDPOINT}/${shortlink}`

  if (code) {
    baseUrl += `?code=${code}`
  }

  if (test) {
    baseUrl += `&mode=test`
  }

  return baseUrl
}

export const makeReferralLink = (shortlink) => {
  return `${envConfig.REACT_APP_REFERRAL_URL}${shortlink}`
}

export const makeRegistrationLink = () => {
  return `${envConfig.REACT_APP_CLIENT_ENDPOINT}/register`
}

export const eventReportCardShareLink = (shortlink, shareKey) => {
  if (shortlink) {
    return `${envConfig.REACT_APP_PUBLIC_PAGE_ENDPOINT}/s/${shortlink}/event-report/${shareKey}`
  } else {
    return `${envConfig.REACT_APP_PUBLIC_PAGE_ENDPOINT}/event-report/${shareKey}`
  }
}

export const publicProfileLink = (shortlink) => {
  return `${envConfig.REACT_APP_PUBLIC_PAGE_ENDPOINT}/s/${shortlink}`
}

export const extractShortlinkFromUrl = (url) => {
  // Extract shortlink from URL for pages that include "/s/" like /s/:shortlink/event-report/:sharelink
  const regex = /\/s\/(\w+)(?:\/event-report\/\w+)?/
  const match = url.match(regex)
  if (match && match[1]) {
    return match[1]
  } else {
    return null
  }
}

export const onlyAllowPositiveNumbersNoDecimals = (value) => {
  value = value.replace(/[^0-9]/g, '')

  return value
}

export const onlyAllowPositiveNumbersAndDecimals = (value) => {
  value = value.replace(/[^0-9.]/g, '')

  // Allow only one decimal point
  const decimalCount = (value.match(/\./g) || []).length
  if (decimalCount > 1) {
    value = value.substring(0, value.lastIndexOf('.'))
  }

  return value
}

/**
 * Takes a value and if that value is equal to an invalid entry, it returns a fallback value.
 * Otherwise, it processes the value through the series of optional functions passed in.
 *
 * @param {*} value - The value to process.
 * @param {Object} options - An options object.
 * @param {*} [options.invalidValues] - (Optional) Array of values to check for breaking and returning the fallback.
 * @param {*} [options.fallback] - (Optional) The fallback value to return if the value to break on is zero.
 * @param {...function} funcs - (Optional) An ordered series of functions to call on the value only if the value to break on is non-zero.
 * @returns {*} The processed value or the fallback value.
 *
 * @example
 * // Basic usage with default options
 * processWithFallback(0); // Returns '-'
 *
 * processWithFallback(10, {}, renderPercentage); // Returns '10%'
 *
 * processWithFallback('hi', { invalidValues: ['hi', 0], fallback: 'N/A' }, renderPercentage); // Returns 'N/A'
 *
 * processWithFallback(
 *   100,
 *   { invalidValues: [0], fallback: 'N/A' },
 *   (val) => new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(val),
 *   (val) => `${val}s`
 * ); // Returns '$100.00s'
 */
export const processWithFallback = (value, opts = {}, ...funcs) => {
  if (typeof opts === 'function') {
    // last arg is a func not opts
    funcs = [opts].concat(funcs)
    opts = {}
  }
  const { invalidValues = [0], fallback = '-' } = { ...opts }

  if (invalidValues.includes(value)) {
    return fallback
  }

  return funcs.reduce((acc, func) => func(acc), value)
}
