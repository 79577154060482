import { useState } from 'react'

import PageContainer from '../common/PageContainer'
import PageHeader from '../common/PageHeader'
import ModalCloseIcon from '../common/ModalCloseIcon'

import { Typography, Dialog, useMediaQuery, useTheme } from '@mui/material'

import MailOutlineIcon from '@mui/icons-material/MailOutline'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import NewReleasesOutlinedIcon from '@mui/icons-material/NewReleasesOutlined'
import { ReactComponent as HeadphoneIcon } from '../../icons/headphoneIcon.svg'
import { ReactComponent as CommunityIcon } from '../../icons/navIcons/communityIcon.svg'
import { ReactComponent as ExternalLinkIcon } from '../../icons/externalLinkIcon_16x16.svg'

import UpcomingWebinars, { WEBINAR_TYPES } from './UpcomingWebinars'

import { styled } from '@mui/system'

const HelpDetailsContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  gap: theme.spacing(2.5),
}))

const ContentWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(3.5, 6),
  backgroundColor: theme.palette.base.white,
  minWidth: '280px',
  maxWidth: '550px',
}))

const Header = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: theme.spacing(3),
}))

const StyledIconWrapper = styled('div')(({ theme }) => ({
  marginBottom: theme.spacing(3),
  svg: {
    height: '35px',
    width: '35px',
  },
}))

const ExternalLink = styled('a')(({ theme }) => ({
  color: theme.palette.secondary.main,
  cursor: 'pointer',
  textDecoration: 'none',
  '&:hover': {
    textDecoration: 'underline',
  },
  '&:visited': {
    color: theme.palette.secondary.main,
  },
}))

const HELP_EMAIL = 'hello@talkadot.com'
const COMMUNITY_LINK = 'https://talk.talkadot.com'
const HELP_ARTICLES_LINK = 'https://talk.talkadot.com/c/resources'
const ANNOUNCEMENTS_LINK = 'https://talk.talkadot.com/c/announcements/'

const HelpCenterParent = () => {
  const [webinarModalOpen, setWebinarModalOpen] = useState(false)
  const [webinarType, setWebinarType] = useState('')
  const isMobile = useMediaQuery(useTheme().breakpoints.down('sm'))

  const CONTENT_BLOCKS = [
    {
      id: 0,
      title: 'Contact Us',
      icon: <MailOutlineIcon />,
      content: (
        <>
          Have questions? Email us directly at{' '}
          <ExternalLink
            href={`mailto:${HELP_EMAIL}`}
            target="_blank"
            rel="noreferrer">
            {HELP_EMAIL}
          </ExternalLink>{' '}
          and we'll get back to you as soon as we can. Support hours are
          typically M-F, 9am - 5pm PST.
        </>
      ),
    },
    {
      id: 1,
      title: 'Live Training Sessions',
      icon: <HeadphoneIcon />,
      content: (
        <>
          Want confidence you've set up your account correctly, and to make sure
          you're using best practices?{' '}
          <ExternalLink
            onClick={() => {
              setWebinarType(WEBINAR_TYPES.DEMO)
              setWebinarModalOpen(true)
            }}>
            Join one of our upcoming live demo's
          </ExternalLink>
          .
        </>
      ),
    },
    {
      id: 2,
      title: 'Live Q&A Sessions',
      icon: <HelpOutlineIcon />,
      content: (
        <>
          Have specific questions you want to ask us on a call?{' '}
          <ExternalLink
            onClick={() => {
              setWebinarType(WEBINAR_TYPES.AMA)
              setWebinarModalOpen(true)
            }}>
            Join one of our upcoming live Ask Me Anything sessions
          </ExternalLink>
          .
        </>
      ),
    },
    {
      id: 3,
      title: 'Get Help From Fellow Speakers',
      icon: <CommunityIcon />,
      content: (
        <>
          Leverage the Talkadot Community to get best practice answers from
          fellow speakers{' '}
          <ExternalLink href={COMMUNITY_LINK} target="_blank" rel="noreferrer">
            here
          </ExternalLink>
          .
        </>
      ),
    },
    {
      id: 4,
      title: 'Self-Serve Help Docs',
      icon: <ExternalLinkIcon />,
      content: (
        <>
          Looking for quick reference answers to any questions you have?{' '}
          <ExternalLink
            href={HELP_ARTICLES_LINK}
            target="_blank"
            rel="noreferrer">
            Check out our help docs here
          </ExternalLink>
          .
        </>
      ),
    },
    {
      id: 5,
      title: "What's New?",
      icon: <NewReleasesOutlinedIcon />,
      content: (
        <>
          Learn of all the new features we are constantly adding to Talkadot!{' '}
          <ExternalLink
            href={ANNOUNCEMENTS_LINK}
            target="_blank"
            rel="noreferrer">
            Click here for the latest info.
          </ExternalLink>
        </>
      ),
    },
  ]

  return (
    <PageContainer>
      <PageHeader header="Need Help?" />
      <HelpDetailsContainer>
        {CONTENT_BLOCKS.map((contentBlock) => {
          return (
            <ContentWrapper key={contentBlock.id}>
              <StyledIconWrapper>{contentBlock.icon}</StyledIconWrapper>
              <Header>
                <Typography variant="h5">{contentBlock.title}</Typography>
              </Header>
              <Typography>{contentBlock.content}</Typography>
            </ContentWrapper>
          )
        })}
      </HelpDetailsContainer>
      <Dialog
        fullWidth
        fullScreen={isMobile}
        onClose={() => setWebinarModalOpen(false)}
        open={webinarModalOpen}>
        <ModalCloseIcon onClick={() => setWebinarModalOpen(false)} />
        <UpcomingWebinars webinarType={webinarType} />
      </Dialog>
    </PageContainer>
  )
}

export default HelpCenterParent
