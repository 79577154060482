import { styled } from '@mui/system'
import { Typography } from '@mui/material'

import { ResponsesSvg } from './ResponsesSvg'
import { ValuableSvg } from './ValuableSvg'
import { ReattendSvg } from './ReattendSvg'
import { LocationSvg } from './LocationSvg'

const Container = styled('div')({
  display: 'flex',
  justifyContent: 'center',
})

const CardContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(1.5),
  border: `1px solid ${theme.palette.border.light}`,
  borderRadius: theme.shape.borderRadius.sm,
  width: '100%',
  height: '164px',
}))

const CardHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
}))

const HeaderLogo = styled('div')({
  display: 'flex',
  alignItems: 'center',
  marginRight: '10px',
})

const HeaderText = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.inputText,
}))

const CardContentContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flex: 1,
})

const CardFooter = styled(Typography)(({ theme }) => ({
  fontWeight: theme.fontWeights.semiBold,
  textAlign: 'center',
}))

const typeConfig = {
  responses: {
    text: 'Audience Feedback',
    logo: <ResponsesSvg />,
    color: (theme) => theme.palette.secondary.main,
    footer: 'Responses from attendees',
  },
  valuable: {
    text: 'Find Valuable',
    logo: <ValuableSvg />,
    color: (theme) => theme.palette.profileWidget.lightBlue,
    footer: 'Find sessions valuable',
  },
  reattend: {
    text: 'Reattend Rate',
    logo: <ReattendSvg />,
    color: (theme) => theme.palette.profileWidget.teal,
    footer: 'Interested in attending future talks',
  },
  location: {
    text: 'Talks Given',
    logo: <LocationSvg />,
    color: (theme) => theme.palette.profileWidget.green,
    footer: 'Audience type',
  },
}

export const ProfileStatCard = ({ type, children }) => {
  const { text, logo, footer } = typeConfig[type]

  return (
    <Container>
      <CardContainer>
        <CardHeader>
          <HeaderLogo>{logo}</HeaderLogo>
          <HeaderText variant="h6">{text}</HeaderText>
        </CardHeader>
        <CardContentContainer>{children}</CardContentContainer>
        <CardFooter variant="body1">{footer}</CardFooter>
      </CardContainer>
    </Container>
  )
}
