import { styled } from '@mui/system'
import { Skeleton, Typography } from '@mui/material'
import { Chart as ChartJS, ArcElement, Tooltip } from 'chart.js'
import { Doughnut } from 'react-chartjs-2'

import { useTheme } from '@mui/material/styles'

ChartJS.register(ArcElement, Tooltip)

const StatWrapper = styled('div')({
  display: 'flex',
})

const InPersonPercentageWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',
})

const VirtualPercentageWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
})

const StatPercentage = styled(Typography)(({ theme }) => ({
  fontWeight: theme.fontWeights.bold,
}))

const CircleChartWrapper = styled('div')({
  alignSelf: 'center',
  width: '96px',
  height: '80px',
  marginLeft: '16px',
})

export const LocationStatContent = ({ locationBreakdown, isLoading }) => {
  const theme = useTheme()
  const { totalEvents, inPerson, virtual } = locationBreakdown
  const inPersonRate = (inPerson / totalEvents) * 100 || 0
  const virtualRate = (virtual / totalEvents) * 100 || 0

  const chartData = {
    labels: ['Virtual', 'In Person'],
    datasets: [
      {
        data: [virtual, inPerson],
        backgroundColor: [
          theme.palette.neutral.extraLight,
          theme.palette.secondary.main,
        ],
        borderWidth: 0,
      },
    ],
  }

  const options = {
    plugins: {
      legend: {
        display: false,
      },
    },
    layout: {
      // Note: this is a workaround to prevent tooltips from being cut off
      padding: {
        right: 16,
        bottom: 16,
      },
    },
  }

  return isLoading ? (
    <Skeleton width={200} height={30} />
  ) : (
    <>
      {inPersonRate > 0 && (
        <StatWrapper>
          <InPersonPercentageWrapper>
            <StatPercentage variant="h3">
              {Math.round(inPersonRate)}
              <span>%</span>
            </StatPercentage>
            <Typography variant="caption">In person</Typography>
          </InPersonPercentageWrapper>
          <CircleChartWrapper>
            <Doughnut data={chartData} options={options} />
          </CircleChartWrapper>
          <VirtualPercentageWrapper>
            <StatPercentage variant="h3">
              {Math.round(virtualRate)}%
            </StatPercentage>
            <Typography variant="caption">Virtual</Typography>
          </VirtualPercentageWrapper>
        </StatWrapper>
      )}
    </>
  )
}
