import { styled } from '@mui/system'
import { Typography, Tooltip, IconButton } from '@mui/material'

import { ReactComponent as EyeIcon } from '../../icons/eyeIcon_16x16.svg'
import { ReactComponent as EyeOffIcon } from '../../icons/eyeOffIcon_16x16.svg'

import TableActionsDropdown from '../common/TableActionsDropdown'

const CardContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'socialShare',
})(({ theme, colSpan = 1, socialShare }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: socialShare ? theme.spacing(0) : theme.spacing(5),
  minHeight: '185px',
  borderRadius: theme.shape.borderRadius.sm,
  padding: socialShare ? theme.spacing(0) : theme.spacing(4),
  border: socialShare
    ? 'none'
    : `1px solid ${theme.palette.neutral.extraLight}`,
  flex: 1,
  gridColumn: `span ${colSpan}`,
  [theme.breakpoints.down('lg')]: {
    gridColumn: 'span 1', // Restrict to one column on small screens
  },
}))

const CardHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  gap: theme.spacing(1),
}))

const CardHeaderLeft = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
}))

const CardHeaderRight = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
  alignItems: 'flex-start',
}))

const QuestionPublicIcon = styled(IconButton, {
  shouldForwardProp: (prop) => prop !== 'isQuestionPublic',
})(({ theme, isQuestionPublic }) => ({
  padding: '10px',
  svg: {
    path: {
      stroke: isQuestionPublic
        ? theme.palette.neutral.veryDark
        : theme.palette.error.semiDark,
    },
  },
}))

const CardContent = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flex: 1,
})

const ResponseCountContent = styled(Typography)(({ theme }) => ({
  color: theme.palette.neutral.main,
}))

const CustomQuestionCard = ({
  question,
  isEventOwner,
  children,
  handleSocialShareDownload,
  socialShare = false,
  handleToggleVisibility,
}) => {
  const renderQuestionType = () => {
    switch (question.question_type) {
      case 'MULTIPLE_CHOICE':
        return 'Multiple Choice'
      case 'FREE_FLOW':
        return 'Long Text'
      case 'EMOJI_SCALE':
        return '5 Point Scale'
      case 'BOOLEAN':
        return 'Yes / No'
      case 'NPS':
        return 'Net Promoter Score®'
      default:
        return ''
    }
  }

  const totalResponses = question.answers.length

  const colSpan =
    question.question_type === 'MULTIPLE_CHOICE' &&
    question.possible_answers.length > 5
      ? 2
      : 1

  return (
    <CardContainer colSpan={colSpan} socialShare={socialShare}>
      <CardHeader>
        <CardHeaderLeft socialShare={socialShare}>
          {!socialShare && (
            <Typography variant="body2">{renderQuestionType()}</Typography>
          )}
          <Typography variant="h4">{question.content}</Typography>
          <ResponseCountContent variant="body2">
            {totalResponses} {totalResponses === 1 ? 'response' : 'responses'}
          </ResponseCountContent>
        </CardHeaderLeft>
        <CardHeaderRight>
          {isEventOwner && !socialShare && (
            <>
              <Tooltip
                title={
                  question.isQuestionPublic
                    ? 'Visible to everyone'
                    : 'Only visible to you'
                }
                enterTouchDelay={0}
                leaveTouchDelay={3000}>
                <QuestionPublicIcon
                  isQuestionPublic={question.isQuestionPublic}
                  onClick={() => {
                    handleToggleVisibility(question)
                  }}>
                  {question.isQuestionPublic ? <EyeIcon /> : <EyeOffIcon />}
                </QuestionPublicIcon>
              </Tooltip>
              <TableActionsDropdown
                menuItems={[
                  {
                    text: 'Download Shareable Image',
                    disabled:
                      !question.isQuestionPublic &&
                      question.question_type === 'FREE_FLOW',
                    onClick: () => {
                      handleSocialShareDownload()
                    },
                  },
                ]}
              />
            </>
          )}
        </CardHeaderRight>
      </CardHeader>
      <CardContent>{children}</CardContent>
    </CardContainer>
  )
}

export default CustomQuestionCard
