import { useRef, useEffect, useCallback, useState } from 'react'
import { styled } from '@mui/system'
import { TableHead, TableRow, useMediaQuery, useTheme } from '@mui/material'

import TableCellStyled from './TableCellStyled'
import TableHeaderCellSortable from '../common/TableHeaderCellSortable'

import {
  upperNavHeight,
  upperNavHeightMobile,
} from '../../styles/layoutNavConstants'

const StickyHeader = styled(TableHead)(({ isSticky, navHeight }) => ({
  position: 'fixed',
  top: `${navHeight}px`,
  background: 'white',
  zIndex: 10,
  boxShadow: isSticky ? '0px 2px 5px rgba(0, 0, 0, 0.1)' : 'none',
  overflow: 'hidden',
  visibility: isSticky ? 'visible' : 'hidden', // Hide if not sticky
}))

const StyledTableHead = styled(TableHead)(({ isSticky }) => ({
  visibility: isSticky ? 'hidden' : 'visible', // Hide if sticky
}))

const TableHeaderSortable = ({
  headCells,
  order,
  orderBy,
  onRequestSort,
  loading,
}) => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth)
  const [colWidths, setColWidths] = useState([])
  const [isSticky, setIsSticky] = useState(false)
  const headerRef = useRef(null)
  const stickyHeaderRef = useRef(null)
  const theme = useTheme()

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth)

      if (headerRef.current) {
        const thElements = headerRef.current.querySelectorAll('th')

        // Delay the width calculation to ensure layout has completed
        requestAnimationFrame(() => {
          const widths = Array.from(thElements)
            .filter((th) => window.getComputedStyle(th).display !== 'none') // Only include visible th elements
            .map((th) => th.getBoundingClientRect().width) // Map the width for visible elements

          setColWidths(widths)
        })
      }
    }

    // Trigger the resize function on initial render
    handleResize()

    // Listen to resize events
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [loading])

  useEffect(() => {
    if (headerRef.current && colWidths.length > 0) {
      const thElements = stickyHeaderRef.current.querySelectorAll('th')
      const filteredElements = Array.from(thElements).filter(
        (th) => window.getComputedStyle(th).display !== 'none'
      )

      filteredElements.forEach((th, i) => {
        // Only apply the width if the display is not 'none'
        if (window.getComputedStyle(th).display !== 'none') {
          th.style.width = `${colWidths[i]}px`
        }
      })
    }
  }, [colWidths])

  const getLastVisibleCellIndex = (headCells, screenWidth, theme) => {
    return headCells.reduce((acc, headCell, index) => {
      // Define breakpoints for your screen sizes
      const smBreakpoint = theme.breakpoints.values.sm // 600px
      const mdBreakpoint = theme.breakpoints.values.md // 960px
      const lgBreakpoint = theme.breakpoints.values.lg // 1280px

      // Skip the cell based on its displayBreakpoint and current screen width
      if (headCell.displayBreakpoint) {
        if (
          (headCell.displayBreakpoint === 'sm' && screenWidth < smBreakpoint) ||
          (headCell.displayBreakpoint === 'md' && screenWidth < mdBreakpoint) ||
          (headCell.displayBreakpoint === 'lg' && screenWidth < lgBreakpoint)
        ) {
          return acc // Skip this cell if its breakpoint is higher than the screen width
        }
      }

      return index
    }, 0)
  }

  const renderHeadCells = (lastVisibleCellIndex) => {
    return headCells.map((headCell, i) => {
      const lastVisibleCell = i === lastVisibleCellIndex

      if (
        headCell.id === 'checkbox' ||
        headCell.id === 'actions' ||
        headCell.id === 'code settings' ||
        headCell.id === 'leadTypes' ||
        headCell.id === 'collapse'
      ) {
        return (
          <TableCellStyled
            key={headCell.id}
            align={headCell.align}
            displayBreakpoint={headCell.displayBreakpoint}
            lastVisibleCell={lastVisibleCell}>
            {headCell.label}
          </TableCellStyled>
        )
      }

      return (
        <TableHeaderCellSortable
          key={headCell.id}
          headCell={headCell}
          order={order}
          orderBy={orderBy}
          onRequestSort={onRequestSort}
          lastVisibleCell={lastVisibleCell}
        />
      )
    })
  }

  const navHeight = useMediaQuery(theme.breakpoints.down('sm'))
    ? upperNavHeightMobile
    : upperNavHeight

  // Scroll handling to determine sticky state
  const handleScroll = useCallback(() => {
    if (headerRef.current) {
      const headerElement = headerRef.current
      const headerTop = headerElement.getBoundingClientRect().top

      if (headerTop <= navHeight) {
        setIsSticky(true)
      } else {
        setIsSticky(false)
      }
    }
  }, [navHeight])

  useEffect(() => {
    const handleScrollDebounced = () => {
      window.requestAnimationFrame(handleScroll)
    }

    window.addEventListener('scroll', handleScrollDebounced)
    return () => {
      window.removeEventListener('scroll', handleScrollDebounced)
    }
  }, [handleScroll])

  const lastVisibleCellIndex = getLastVisibleCellIndex(
    headCells,
    screenWidth,
    theme
  )

  return (
    <>
      <StickyHeader
        ref={stickyHeaderRef}
        isSticky={isSticky}
        navHeight={navHeight}>
        <TableRow>{renderHeadCells(lastVisibleCellIndex)}</TableRow>
      </StickyHeader>

      <StyledTableHead ref={headerRef} isSticky={isSticky}>
        <TableRow>{renderHeadCells(lastVisibleCellIndex)}</TableRow>
      </StyledTableHead>
    </>
  )
}

export default TableHeaderSortable
