import { useCallback, useRef, forwardRef, useImperativeHandle } from 'react'

import TalkVideoUploader from './TalkVideoUploader'
import { FullFormSection } from '../../common/FormComponents'

import Api from '../../../services/api'

const SpeechTopicVideoUploadParent = forwardRef((props, ref) => {
  const files = useRef([])

  useImperativeHandle(ref, () => ({
    getFiles: () => files.current,
  }))

  const handleUploadComplete = useCallback(async (fileObj) => {
    try {
      const res = await Api.createSignatureTalkMediaUpload({
        signature_talk_media_upload: {
          file_key: fileObj.fileKey,
          name: fileObj.file.name,
          size: fileObj.file.size,
          media_type: 'video',
        },
      })
      if (res.errors) throw res.errors
      files.current = [...files.current, { mediaUploadObj: res, fileObj }]
    } catch (error) {
      console.error(error)
    }
  }, [])

  const handleUploadDelete = useCallback((file) => {
    // TODO: delete file from S3
    // TODO: remove from speech topic if we already have it saved
    console.log('file deleted', file)
  }, [])

  return (
    <FullFormSection
      title="Do you have a video related to this speech topic?"
      stepNumber="6"
    >
      <TalkVideoUploader
        onUploadComplete={handleUploadComplete}
        onUploadDelete={handleUploadDelete}
      />
    </FullFormSection>
  )
})

export default SpeechTopicVideoUploadParent

