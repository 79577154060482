import TableHeaderSortable from '../common/TableHeaderSortable'

const LeadsTableHeader = ({ order, orderBy, onRequestSort, loading }) => {
  const headCells = [
    { id: 'checkbox', label: '' },
    { id: 'collapse', label: '' },
    { id: 'createdAt', label: 'Date' },
    { id: 'event.talkTitle', label: 'Talk Title', displayBreakpoint: 'md' },
    { id: 'organization', label: 'Organization', displayBreakpoint: 'lg' },
    { id: 'name', label: 'Lead Name', displayBreakpoint: 'lg' },
    { id: 'email', label: 'Lead Contact' },
    // TODO: Figure out sorting for leadTypes
    {
      id: 'leadTypes',
      label: 'Type',
      displayBreakpoint: 'lg',
      align: 'center',
    },
    {
      id: 'actions',
      label: 'Actions',
      align: 'center',
    },
  ]

  return (
    <TableHeaderSortable
      headCells={headCells}
      order={order}
      orderBy={orderBy}
      onRequestSort={onRequestSort}
      loading={loading}
    />
  )
}

export default LeadsTableHeader
