import { styled } from '@mui/system'
import { Typography } from '@mui/material'
import CheckBoxStyled from '../../common/CheckBoxStyled'

const Container = styled('div')(({ theme }) => ({
  paddingTop: theme.spacing(1.5),
  paddingBottom: theme.spacing(1.5),
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(0.5),
  width: '90%',
  [theme.breakpoints.down('lg')]: {
    width: '100%',
  },
}))

const Row = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1.25),
}))

const StyledLabel = styled(Typography)(({ theme }) => ({
  color: theme.palette.neutral.veryDark,
}))

const StyledWarning = styled(Typography)(({ theme }) => ({
  color: theme.palette.error.semiDark,
  marginLeft: theme.spacing(4.25),
  maxWidth: '500px',
}))

const CodeBuilderOver18PossibleAnswer = ({
  question,
  possibleAnswer,
  questionLoading,
  setActiveTab,
  handleToggleOver18,
}) => {
  return (
    <Container>
      <Row>
        <CheckBoxStyled
          sx={{ padding: 0 }}
          checked={possibleAnswer.enabled}
          disabled={questionLoading}
          onChange={() => {
            setActiveTab(1)
            handleToggleOver18(
              question,
              possibleAnswer,
              !possibleAnswer.enabled
            )
          }}
        />
        <StyledLabel variant="body1" component="span">
          I am speaking to an audience under 18
        </StyledLabel>
      </Row>
      <StyledWarning variant="paragraph00" component="span">
        When checked, anyone who identifies as under 18 will not have any
        personal information such as their name or email collected.
      </StyledWarning>
    </Container>
  )
}

export default CodeBuilderOver18PossibleAnswer
