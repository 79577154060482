import { styled } from '@mui/system'
import { Button, Typography, Tooltip } from '@mui/material'

import CheckBoxStyled from './CheckBoxStyled'
import SplitButton from './SplitButton'

const ActionsContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: theme.spacing(2),
  [theme.breakpoints.down('md')]: {
    // flexDirection: 'column-reverse',
    // alignItems: 'flex-start',
  },
}))

const BulkActionsContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  paddingLeft: theme.spacing(0.5),
}))

const SelectedCount = styled(Typography)(({ theme }) => ({
  fontWeight: '600',
  marginRight: theme.spacing(1),
  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}))

const BulkActionsButtons = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  flex: '1',
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}))

const BulkActionsButton = styled(Button)(({ theme }) => ({
  svg: {
    path: {
      stroke: theme.palette.neutral.semiLight,
    },
  },
  fontWeight: '400',
}))

const SplitButtonContainer = styled('div')(({ theme }) => ({
  display: 'none',
  [theme.breakpoints.down('md')]: {
    display: 'block',
  },
}))

const CtaContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  [theme.breakpoints.down('md')]: {
    // width: '100%',
    // marginBottom: theme.spacing(1),
  },
}))

export const StyledButton = styled(Button)(({ theme }) => ({
  color: theme.palette.base.white,
  svg: {
    marginRight: theme.spacing(1),
    minWidth: '16px',
  },
  [theme.breakpoints.down('md')]: {
    minWidth: '50px',
    height: '50px',
    paddingLeft: '8px',
    paddingRight: '8px',
    svg: {
      marginRight: 0,
    },
    '& .button-text': {
      display: 'none',
    },
  },
}))

const renderCtaButton = ({
  onClick,
  text,
  icon,
  disabled = false,
  tooltipText,
  tooltipPlacement = 'bottom',
}) => {
  const button = (
    <StyledButton
      variant="contained"
      color="primary"
      disabled={disabled}
      onClick={onClick}>
      {icon && icon}
      <span className="button-text">{text}</span>
    </StyledButton>
  )

  return tooltipText ? (
    <Tooltip title={tooltipText} placement={tooltipPlacement}>
      <div>{button}</div>
    </Tooltip>
  ) : (
    button
  )
}

const renderActionButton = ({ text, icon, onClick, disabled, tooltipText }) => {
  const button = (
    <BulkActionsButton
      key={text}
      variant="text"
      color="secondary"
      startIcon={icon}
      onClick={onClick}
      disabled={disabled}>
      {text}
    </BulkActionsButton>
  )

  return tooltipText ? (
    <Tooltip
      key={text}
      title={tooltipText}
      slotProps={{
        popper: {
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, -14],
              },
            },
          ],
        },
      }}>
      <div>{button}</div>
    </Tooltip>
  ) : (
    button
  )
}

const TableActions = ({
  showCta = true,
  ctaOpts = {},
  showBulkActions = false,
  selectedItems,
  allSelected,
  handleSelectAllChange,
  bulkActions,
}) => {
  return (
    <ActionsContainer>
      <BulkActionsContainer>
        {showBulkActions && (
          <>
            <CheckBoxStyled
              checked={allSelected}
              onChange={handleSelectAllChange}
            />
            <SelectedCount variant="body1">
              {selectedItems?.length} selected
            </SelectedCount>
            <BulkActionsButtons>
              {bulkActions.map((action) => renderActionButton(action))}
            </BulkActionsButtons>
            <SplitButtonContainer>
              <SplitButton options={bulkActions} />
            </SplitButtonContainer>
          </>
        )}
      </BulkActionsContainer>
      <CtaContainer>{showCta && renderCtaButton(ctaOpts)}</CtaContainer>
    </ActionsContainer>
  )
}

export default TableActions
