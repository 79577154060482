import { useState, useEffect } from 'react'
import { styled } from '@mui/system'

import {
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Select,
  MenuItem,
  Button,
} from '@mui/material'

import {
  FormFieldContainer,
  FormLabel,
  SendTestEmailButton,
} from './sharedStyles'
import InputSection, { TextInput } from '../../common/InputSection'

import isEmail from 'validator/lib/isEmail'

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  '&.Mui-selected': {
    backgroundColor: `${theme.palette.neutral.extraLight} !important`,
  },
}))

const StyledSelect = styled(Select)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  borderRadius: theme.shape.borderRadius.xxs,
  backgroundColor: theme.palette.base.white,
  '& .MuiSelect-select': {
    padding: '1px 10px',
    minHeight: '48px',
    display: 'flex',
    alignItems: 'center',
  },
}))

const EmailCampaignTestParent = ({
  testEmailProps,
  handleClose,
  sendTestEmail,
  isLoading,
}) => {
  const [email, setEmail] = useState('')
  const [offers, setOffers] = useState([])
  const [error, setError] = useState('')
  const [selectedCode, setSelectedCode] = useState('')

  useEffect(() => {
    setEmail(testEmailProps.email)
    setOffers(testEmailProps.offers)
    setSelectedCode(testEmailProps.offers[0]?.code || '')
  }, [testEmailProps])

  const handleSelectChange = (event) => {
    setSelectedCode(event.target.value)
  }

  const handleEmailBlur = () => {
    if (!isEmail(email)) {
      setError('Please enter a valid email address')
    }
  }

  const handleSendTestEmail = async () => {
    const offerId = offers.find((offer) => offer.code === selectedCode)?.id
    const result = await sendTestEmail(offerId, email)

    if (result.error) {
      setError(result.error)
    } else {
      handleClose()
    }
  }

  return (
    <>
      <DialogTitle>Send Test Email</DialogTitle>
      <DialogContent>
        <Typography variant="body1" gutterBottom>
          This will send a test email of what your leads will receive to the
          email address you enter, using the code you have selected.
        </Typography>
        <FormFieldContainer>
          <FormLabel label="Email Address:" />
          <InputSection
            canEdit={true}
            showError={error?.length > 0}
            errorMsg={error}>
            <TextInput
              id="outlined-disabled"
              variant="outlined"
              value={email}
              onChange={(e) => {
                setError('')
                setEmail(e.target.value)
              }}
              onBlur={handleEmailBlur}
              fullWidth
            />
          </InputSection>
        </FormFieldContainer>
        <FormFieldContainer>
          <FormLabel label="Code:" />
          <StyledSelect
            value={selectedCode}
            onChange={handleSelectChange}
            color="secondary">
            {offers.map((offer) => (
              <StyledMenuItem
                key={offer.id}
                value={offer.code}
                selected={selectedCode === offer.code}>
                {offer.code}
              </StyledMenuItem>
            ))}
          </StyledSelect>
        </FormFieldContainer>
      </DialogContent>
      <DialogActions>
        <Button style={{ color: 'orange' }} onClick={() => handleClose()}>
          Cancel
        </Button>
        <SendTestEmailButton
          variant="contained"
          disabled={error?.length > 0 || isLoading}
          onClick={handleSendTestEmail}>
          Send test email
        </SendTestEmailButton>
      </DialogActions>
    </>
  )
}

export default EmailCampaignTestParent
