import { useEffect, useState } from 'react'
import {
  Paper,
  Table,
  TableContainer,
  TableBody,
  TableRow,
  TableCell,
  Skeleton,
  useMediaQuery,
  useTheme,
} from '@mui/material'

import LeadsTableHeader from './LeadsTableHeader'
import LeadsTableRow from './LeadsTableRow'
import LeadsTableRowPlaceholder from './LeadsTableRowPlaceholder'
import TablePaginationFooter from '../common/TablePaginationFooter'

import { getLeadsComparator } from './helpers'

const LeadsTable = ({
  leads,
  loading,
  handleDeleteLead,
  handleUpdateLeads,
  handleCheckboxChange,
  handleMarkAsViewed,
  selectedLeads,
  page,
  setPage,
}) => {
  // const [rowsPerPage, setRowsPerPage] = useState(5)
  const [order, setOrder] = useState('desc')
  const [orderBy, setOrderBy] = useState('createdAt')
  const [visibleRows, setVisibleRows] = useState([])

  const theme = useTheme()
  const isLargeScreen = useMediaQuery(theme.breakpoints.down('lg'))
  const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'))

  const rowsPerPage = 25

  useEffect(() => {
    setVisibleRows(
      leads
        .slice()
        .sort(getLeadsComparator(order, orderBy))
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    )
  }, [leads, page, rowsPerPage, order, orderBy])

  const handleChangePage = (newPage) => {
    setPage(newPage)
  }

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - leads.length) : 0

  let skeletonColumns = 6
  if (isLargeScreen) {
    skeletonColumns = 3
  } else if (isMediumScreen) {
    skeletonColumns = 2
  }

  const renderLoadingSkeleton = () =>
    Array.from({ length: 3 }).map((_, index) => (
      <TableRow key={`skeleton-row-${index}`}>
        <TableCell />
        <TableCell />
        {Array.from({ length: skeletonColumns }).map((_, cellIndex) => (
          <TableCell key={`skeleton-table-cell-${cellIndex}`}>
            <Skeleton variant="text" />
          </TableCell>
        ))}
        <TableCell />
      </TableRow>
    ))

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const renderTableRows = () => {
    if (loading) {
      return renderLoadingSkeleton()
    }

    if (visibleRows.length === 0) {
      return <LeadsTableRowPlaceholder />
    }

    return visibleRows.map((lead) => (
      <LeadsTableRow
        key={lead.id}
        lead={lead}
        handleDeleteLead={handleDeleteLead}
        handleUpdateLeads={handleUpdateLeads}
        handleCheckboxChange={handleCheckboxChange}
        handleMarkAsViewed={handleMarkAsViewed}
        selectedLeads={selectedLeads}
      />
    ))
  }

  return (
    <>
      <TableContainer component={Paper} elevation={0}>
        <Table aria-label="talks table" stickyHeader>
          <LeadsTableHeader
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            loading={loading}
          />
          <TableBody>
            {renderTableRows()}
            {emptyRows > 0 && (
              <TableRow
                style={{
                  height: 57 * emptyRows,
                }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePaginationFooter
        rows={leads}
        rowsPerPage={rowsPerPage}
        page={page}
        handleChangePage={handleChangePage}
      />
    </>
  )
}

export default LeadsTable
