import { styled } from '@mui/system'
import { useTheme } from '@mui/material/styles'
import { Typography } from '@mui/material'
import { Doughnut } from 'react-chartjs-2'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'

import EmojiRating from '../common/EmojiRating'

// Register Chart.js components and plugins
ChartJS.register(ArcElement, Tooltip, Legend)

// Register custom plugin locally
const customAverageLabelPlugin = {
  id: 'customAverageLabel',
  beforeDraw(chart) {
    const { width, height, ctx } = chart
    const averagePercentage =
      chart.options.plugins.customAverageLabel?.averagePercentage || '0'

    ctx.save()

    const text = `${averagePercentage}%`
    ctx.font = 'bold 36px Figtree, sans-serif'
    ctx.textBaseline = 'middle'
    ctx.textAlign = 'center'

    const textX = width / 2
    const textY = height * 0.75

    ctx.fillText(text, textX, textY)
    ctx.restore()
  },
}

const Container = styled('div', {
  shouldForwardProp: (prop) => prop !== 'socialShare',
})(({ theme, socialShare }) => ({
  display: 'flex',
  // flexDirection: 'column',
  flexDirection: socialShare ? 'row' : 'column',
  alignItems: 'center',
  gap: theme.spacing(3),
  flex: 1,
}))

const DonutContainer = styled('div')(({ theme }) => ({
  width: '320px',
  height: '160px', // Limit the container height to half the canvas height
  overflow: 'hidden', // Hide any overflow from the canvas
  display: 'flex',
  justifyContent: 'center', // Center the donut chart horizontally
  [theme.breakpoints.down('sm')]: {
    width: '260px',
    height: '130px',
  },
}))

const EmojiIconsContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  flex: 1,
  maxWidth: '320px',
  width: '100%',
  [theme.breakpoints.down('sm')]: {
    maxWidth: '260px',
  },
}))

const EmojiIconContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  flex: 1,
  gap: theme.spacing(1),
}))

const CustomQuestionEmojiScale = ({ question, socialShare }) => {
  const theme = useTheme()

  const range = Array.from({ length: 5 }, (_, i) => i + 1)
  const labels = [
    { answerValue: 1, label: 'Very negative' },
    { answerValue: 2, label: 'Negative' },
    { answerValue: 3, label: 'Neutral' },
    { answerValue: 4, label: 'Positive' },
    { answerValue: 5, label: 'Very positive' },
  ]

  const emojiRatings = range.map((rating) => {
    return {
      range_value: rating,
      label: labels.find((label) => label.answerValue === rating).label,
      count: question.answers.filter((answer) => answer.range_value === rating)
        .length,
      percentage: question.answers.length
        ? Math.round(
            (question.answers.filter((answer) => answer.range_value === rating)
              .length /
              question.answers.length) *
              100
          )
        : 0,
    }
  })

  const averageRating =
    emojiRatings.reduce(
      (acc, rating) => acc + rating.range_value * rating.count,
      0
    ) / question.answers.length

  const averageRatingPercentage = Math.round(
    (averageRating / 5) * 100
  ).toString()

  const chartData = {
    labels: labels.map((label) => label.label),
    datasets: [
      {
        data: emojiRatings.map((rating) => rating.count),
        backgroundColor: [
          theme.palette.emojis.red,
          theme.palette.emojis.orange,
          theme.palette.emojis.yellow,
          theme.palette.emojis.lime,
          theme.palette.emojis.green,
        ],
        borderWidth: 0,
      },
    ],
  }

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    rotation: -90, // Start from the top
    circumference: 180, // Draw a half-donut
    cutout: '70%', // Make it look like a gauge by increasing the inner radius
    plugins: {
      legend: {
        display: false, // Hide the legend if you want custom labels below the chart
      },
      tooltip: {
        enabled: true,
      },
      customAverageLabel: {
        averagePercentage: averageRatingPercentage, // Pass the percentage dynamically
      },
    },
  }

  return (
    <Container socialShare={socialShare}>
      <DonutContainer>
        <Doughnut
          data={chartData}
          options={options}
          plugins={[customAverageLabelPlugin]}
        />
      </DonutContainer>
      <EmojiIconsContainer>
        {emojiRatings
          .sort((a, b) => b.range_value - a.range_value)
          .map((rating) => (
            <EmojiIconContainer key={rating.range_value}>
              <EmojiRating
                answerText={rating.label}
                answerValue={rating.range_value.toString()}
                emojiSize="16px"
              />
              <Typography variant="body1">{rating.percentage}%</Typography>
            </EmojiIconContainer>
          ))}
      </EmojiIconsContainer>
    </Container>
  )
}

export default CustomQuestionEmojiScale
