import { styled } from '@mui/system'

import { Typography } from '@mui/material'

const Description = styled(Typography)(({ theme }) => ({
  maxWidth: '850px',
  fontSize: '1rem',
  color: theme.palette.neutral.semiDark,
  marginBottom: '25px',
  a: {
    color: theme.palette.neutral.semiDark,
  },
}))

const PageDescription = ({ children }) => (
  <Description>{children}</Description>
)

export default PageDescription