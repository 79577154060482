export const LocationSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="26"
      viewBox="0 0 16 26"
      fill="none">
      <path
        d="M4.05078 25H11.3651"
        stroke="#323A46"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
      <path
        d="M11.9708 13.3429C11.9708 14.6159 11.5213 15.8368 10.7212 16.737C9.921 17.6371 8.83576 18.1429 7.70417 18.1429C6.57258 18.1429 5.48733 17.6371 4.68718 16.737C3.88702 15.8368 3.4375 14.6159 3.4375 13.3429V5.8C3.4375 4.52696 3.88702 3.30606 4.68718 2.40589C5.48733 1.50571 6.57258 1 7.70417 1C8.83576 1 9.921 1.50571 10.7212 2.40589C11.5213 3.30606 11.9708 4.52696 11.9708 5.8V13.3429Z"
        stroke="#323A46"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
      <path
        d="M7.70312 25V20.8857"
        stroke="#323A46"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
      <path
        d="M1 10.6006V13.3434C1 15.3439 1.70639 17.2625 2.96378 18.677C4.22117 20.0916 5.92655 20.8863 7.70476 20.8863C9.48297 20.8863 11.1884 20.0916 12.4457 18.677C13.7031 17.2625 14.4095 15.3439 14.4095 13.3434V10.6006"
        stroke="#323A46"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
      <path
        d="M3.4375 9.91406H11.9708"
        stroke="#323A46"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
    </svg>
  )
}
