import { useCallback, useMemo } from 'react'

import { styled } from '@mui/system'
import Dropzone from 'react-dropzone'
import { IconButton, Typography, LinearProgress, Button } from '@mui/material'

import { ReactComponent as TrashIcon } from '../../icons/trash2Icon_16x16.svg'
import { ReactComponent as FileIcon } from '../../icons/fileIcon_16x16.svg'
import { ReactComponent as CancelIcon } from '../../icons/closeIcon_16x16.svg'

import { ReactComponent as CloudUploadIcon } from '../../icons/cloudUploadIcon.svg'
import { ReactComponent as FolderIcon } from '../../icons/folderIcon_16x16.svg'

const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginTop: theme.spacing(2.5),
  padding: theme.spacing(2, 1.5),
  width: '100%',
  justifyContent: 'space-between',
}))

const FileInfoContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
}))

const FileIconContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '26px',
  height: '26px',
  borderRadius: '50%',
  backgroundColor: theme.palette.neutral.lightest,
  marginRight: theme.spacing(1.5),
  svg: {
    maxWidth: '12px',
    path: {
      stroke: theme.palette.neutral.main,
    },
  },
}))

const FileNameContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
})

const FileName = styled(Typography)(({ theme }) => ({
  color: theme.palette.neutral.darkest,
  fontWeight: '500',
  maxWidth: '100%',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}))

const UploadDetails = styled(Typography)(({ theme }) => ({
  color: theme.palette.neutral.main,
}))

const FileCta = styled(IconButton)(({ theme }) => ({
  marginLeft: theme.spacing(1.5),
  svg: {
    path: {
      stroke: theme.palette.error.semiDark,
    },
  },
}))

const StyledLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: '8px',
  borderRadius: theme.shape.borderRadius.xxs,
  margin: theme.spacing(1, 0),
}))

const DropzoneWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  margin: theme.spacing(3.75, 0),
}))

const DropzoneContainer = styled('div')(({ theme, isDragActive }) => ({
  alignItems: 'center',
  border: isDragActive
    ? `1px solid ${theme.palette.primary.main}`
    : `1px dashed ${theme.palette.border.main}`,
  width: '360px',
  padding: theme.spacing(2.5, 7.5),
  textAlign: 'center',
  cursor: 'pointer',
  borderRadius: theme.shape.borderRadius.sm,
  backgroundColor: isDragActive ? theme.palette.action.hover : 'transparent',
  transition: 'background-color 0.3s ease',
  [theme.breakpoints.down('sm')]: {
    width: 'auto',
  },
}))

const UploadButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2.5),
  color: theme.palette.base.white,
  height: '32px',
  borderRadius: theme.shape.borderRadius.xs,
  svg: {
    path: {
      stroke: theme.palette.base.white,
    },
  },
}))

const DropzoneText = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.hint,
  div: {
    fontWeight: theme.fontWeights.semiBold,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}))

const ErrorText = styled(Typography)(({ theme }) => ({
  color: theme.palette.error.main,
  fontWeight: theme.fontWeights.semiBold,
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(1),
}))

const parseFileRejectionError = (errorCode) => {
  switch (errorCode) {
    case 'file-invalid-type':
      return 'Invalid file type.'
    case 'file-too-large':
      return 'File is too large.'
    default:
      return 'Sorry, something went wrong uploading your file'
  }
}

const renderFileRejectionErrors = (
  fileRejection,
  humanizeFileRejectionError
) => {
  const { errors } = fileRejection
  return errors.map((error) => {
    return (
      <ErrorText key={error.code} variant="body1" component="div">
        {humanizeFileRejectionError
          ? humanizeFileRejectionError(error.code)
          : parseFileRejectionError(error.code)}
      </ErrorText>
    )
  })
}

const UploadFile = ({
  fileName,
  fileSize,
  uploading,
  icon,
  uploadProgress,
  handleUploadCancel,
  handleUploadDelete,
}) => {
  const displayIcon = useMemo(() => {
    return icon || <FileIcon />
  })

  const renderCta = () => {
    if (uploading) {
      return (
        <FileCta onClick={handleUploadCancel}>
          <CancelIcon />
        </FileCta>
      )
    }

    return (
      <FileCta onClick={handleUploadDelete}>
        <TrashIcon />
      </FileCta>
    )
  }

  return (
    <Container>
      <FileInfoContainer>
        <FileIconContainer>{displayIcon}</FileIconContainer>
        <FileNameContainer>
          <FileName variant="body1">{fileName}</FileName>
          {uploading && (
            <>
              <StyledLinearProgress
                variant="determinate"
                color="secondary"
                value={uploadProgress}
              />
              <UploadDetails variant="caption">
                {(fileSize / 1000000).toFixed(1)}MB • {uploadProgress}%
                uploading
              </UploadDetails>
            </>
          )}
        </FileNameContainer>
      </FileInfoContainer>
      {renderCta()}
    </Container>
  )
}

const UploadMediaDropzone = (props) => {
  const {
    acceptedMimeTypes,
    maxNumberOfFiles,
    maxFileSize,
    isUploading,
    handleFileUpload,
    dropzoneHelperContent,
    humanizeFileRejectionError,
  } = props

  const handleOnDrop = useCallback(
    (acceptedFiles) => {
      handleFileUpload(acceptedFiles)
    },
    [handleFileUpload]
  )
  const renderDropzone = useCallback(
    ({ getRootProps, getInputProps, isDragActive, fileRejections }) => (
      <DropzoneContainer {...getRootProps()} isDragActive={isDragActive}>
        <input {...getInputProps()} />
        <div>
          {fileRejections.length > 0 &&
            renderFileRejectionErrors(
              fileRejections[0],
              humanizeFileRejectionError
            )}
        </div>
        <CloudUploadIcon />
        <DropzoneText variant="body2" component="div">
          <div>
            {isDragActive
              ? 'Drop the files here...'
              : 'Drag and drop a file to upload'}
          </div>
        </DropzoneText>
        <DropzoneText variant="body1">
          {/* We actually currently support way more file types */}
          {dropzoneHelperContent}
        </DropzoneText>
        {!isUploading && (
          <UploadButton
            variant="contained"
            color="primary"
            startIcon={<FolderIcon />}
          >
            Browse Files
          </UploadButton>
        )}
      </DropzoneContainer>
    ),
    [dropzoneHelperContent, humanizeFileRejectionError, isUploading]
  )

  return (
    <DropzoneWrapper>
      <Dropzone
        onDrop={handleOnDrop}
        accept={acceptedMimeTypes}
        maxFiles={maxNumberOfFiles}
        maxSize={maxFileSize}
      >
        {renderDropzone}
      </Dropzone>
    </DropzoneWrapper>
  )
}

export default UploadMediaDropzone

export { UploadFile }
