import { useState, useRef, useEffect, useCallback } from 'react'

const usePolling = ({
  apiMethod,
  shouldStopPolling,
  maxTries = 10,
  pollingInterval = 1000,
}) => {
  const [data, setData] = useState(null)
  const [error, setError] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const triesRef = useRef(0)
  const pollingRef = useRef(null)

  const stopPolling = useCallback(() => {
    if (pollingRef.current) {
      clearTimeout(pollingRef.current)
      pollingRef.current = null
    }
    triesRef.current = 0
  }, [])

  const startPolling = useCallback(
    (params) => {
      setIsLoading(true)
      return new Promise((resolve, reject) => {
        const poll = async () => {
          if (triesRef.current >= maxTries) {
            stopPolling()
            resolve({ status: 'failed', tries: triesRef.current })
            setData({ status: 'failed' })
            setIsLoading(false)
            return
          }

          try {
            const res = await apiMethod(params)

            if (res.errors) {
              stopPolling()
              setError(res.errors)
              setIsLoading(false)
              reject(res.errors)
              return
            }

            const latestData = res
            if (shouldStopPolling(latestData)) {
              setData(latestData)
              stopPolling()
              resolve({ status: 'completed', data: latestData })
              setIsLoading(false)
              return
            }

            triesRef.current += 1
            pollingRef.current = setTimeout(poll, pollingInterval)
          } catch (err) {
            stopPolling()
            setError(err)
            setIsLoading(false)
            reject(err)
          }
        }

        poll()
      })
    },
    [apiMethod, shouldStopPolling, maxTries, pollingInterval, stopPolling]
  )

  useEffect(() => {
    return () => stopPolling() // Clean up on unmount
  }, [stopPolling])

  return { data, error, isLoading, startPolling, stopPolling }
}

export default usePolling
