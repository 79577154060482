import { styled } from '@mui/system'
import { Skeleton, Typography } from '@mui/material'
import { Chart as ChartJS, ArcElement, Tooltip } from 'chart.js'
import { Doughnut } from 'react-chartjs-2'

import { useTheme } from '@mui/material/styles'

ChartJS.register(ArcElement, Tooltip)

const StatWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(2),
  // Note: this is fudging the centering due to the chart padding for the label overflow fix
  marginLeft: '40px',
}))

const StatPercentage = styled(Typography)(({ theme }) => ({
  fontWeight: theme.fontWeights.bold,
}))

const CircleChartWrapper = styled('div')({
  // Note: This width is to account for the chart layout padding to handle the label overflow
  width: '120px',
  height: '80px',
})

export const ReattendStatContent = ({
  reattendBreakdown,
  isLoading,
  speaker,
}) => {
  const theme = useTheme()
  const { d_yes, p_yes, p_no, d_no, total_reattend_answers } = reattendBreakdown
  const reattendRate =
    parseInt(((d_yes + p_yes) / total_reattend_answers) * 100) || 0

  const chartData = {
    labels: ['Definitely', 'Probably', 'Probably Not', 'Definitely Not'],
    datasets: [
      {
        data: [d_yes, p_yes, p_no, d_no],
        backgroundColor: [
          theme.palette.secondary.main,
          theme.palette.profileWidget.lightBlue,
          theme.palette.neutral.dark,
          theme.palette.neutral.extraLight,
        ],
        borderWidth: 0,
      },
    ],
  }

  const options = {
    plugins: {
      legend: {
        display: false,
      },
    },
    layout: {
      // Note: this is a workaround to prevent tooltips from being cut off
      padding: {
        right: 40,
        bottom: 40,
      },
    },
  }

  return isLoading ? (
    <Skeleton width={200} height={30} />
  ) : (
    <>
      {reattendRate > 0 && (
        <StatWrapper>
          <StatPercentage variant="h3">{reattendRate}%</StatPercentage>
          <CircleChartWrapper>
            <Doughnut data={chartData} options={options} />
          </CircleChartWrapper>
        </StatWrapper>
      )}
    </>
  )
}
