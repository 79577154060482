import { useState, useEffect } from 'react'
import { useParams, useLocation } from 'react-router-dom'
import * as qs from 'qs'

import Api from '../../services/api'

import ReportCardStatImage from './ReportCardStatImage'
import TestimonialStatImage from './TestimonialStatImage'
import CustomQuestionSocialShareContent from './CustomQuestionSocialShareContent'

const initialReportDataState = {
  speaker: {
    id: null,
    name: null,
    avatar: null,
    isEventPlanner: null,
  },
  id: null,
  talkTitle: null,
  organization: null,
  date: null,
  totalResponses: null,
  ratingsBreakdown: {},
  feedback: [],
  improvement: [],
  takeaways: [],
  reattendBreakdown: {},
  isEventOwner: false,
  engagementBreakdown: [],
  speakers: [],
  eventType: null,
  isUserEvent: null,
  showBranding: null,
  access: {
    isEventOwner: null,
    isEventSpeaker: null,
    canShare: null,
  },
}

const SocialShareImageParent = () => {
  let params = useParams()
  const location = useLocation()
  const [isLoading, setIsLoading] = useState(false)
  const [reportData, setReportData] = useState(initialReportDataState)
  const [customQuestions, setCustomQuestions] = useState([])
  const shareKey = params.sharelink

  const urlParams = qs.parse(location.search, { ignoreQueryPrefix: true })

  const { answerId, customQuestionId } = urlParams

  useEffect(() => {
    const fetchData = async () => {
      if (!params.sharelink) return

      setIsLoading(true) // Set loading before both API calls

      try {
        const reqParams = { event_report: { share_key: params.sharelink } }

        const [reportRes, customRes] = await Promise.all([
          Api.getEventReportCard(reqParams),
          customQuestionId
            ? Api.getCustomAnswers(reqParams)
            : Promise.resolve(null),
        ])

        if (reportRes && !reportRes.errors) {
          setReportData(reportRes)
        }

        if (customRes && !customRes.errors) {
          setCustomQuestions(customRes.customQuestions)
        }
      } catch (err) {
        console.error('Error fetching data:', err)
      } finally {
        setIsLoading(false) // Only set false after both finish
      }
    }

    fetchData()
  }, [params.sharelink])

  if (isLoading) {
    return <></>
  }

  // This is a custom free flow question
  if (customQuestionId && answerId) {
    if (!customQuestions || customQuestions.length === 0) {
      return <></>
    }

    const customQuestion = customQuestions.find(
      (question) => question.id === parseInt(customQuestionId)
    )

    const customAnswer = customQuestion.answers.find(
      (answer) => answer.answer_id === parseInt(answerId)
    )

    return (
      <TestimonialStatImage
        reportData={reportData}
        sortedFeedback={reportData.takeaways}
        shareKey={shareKey}
        customPickedFeedback={[customAnswer]}
      />
    )
  }

  // This is a custom non free flow question
  if (customQuestionId) {
    if (!customQuestions || customQuestions.length === 0) {
      return <></>
    }

    const customQuestion = customQuestions.find(
      (question) => question.id === parseInt(customQuestionId)
    )

    return (
      <CustomQuestionSocialShareContent
        question={customQuestion}
        reportData={reportData}
        shareKey={shareKey}
        isEventOwner={false}
        handleOpenSocialShare={() => {}}
      />
    )
  }

  // This is a specific testimonial
  if (answerId) {
    const sortedFeedback = reportData.takeaways
    const filteredFeedback = sortedFeedback.filter(
      (feedback) => feedback.public_share === true
    )

    let customPickedFeedback = null

    if (answerId) {
      customPickedFeedback = filteredFeedback.filter(
        (feedback) => feedback.answer_id === parseInt(answerId)
      )

      // if no custom picked feedback is found in the takeaways, then try to find it in the reportData.feedback
      if (customPickedFeedback.length === 0) {
        const sortedFeedback = reportData.feedback
        const filteredFeedback = sortedFeedback.filter(
          (feedback) => feedback.public_share === true
        )
        customPickedFeedback = filteredFeedback.filter(
          (feedback) => feedback.answer_id === parseInt(answerId)
        )
      }
    }

    return (
      <TestimonialStatImage
        reportData={reportData}
        sortedFeedback={filteredFeedback}
        shareKey={shareKey}
        customPickedFeedback={customPickedFeedback}
      />
    )
  }

  // This is the report card and top testimonials
  return (
    <>
      <ReportCardStatImage
        reportData={reportData}
        shareKey={shareKey}
        isLoading={isLoading}
      />
      <TestimonialStatImage
        reportData={reportData}
        sortedFeedback={reportData.takeaways}
        shareKey={shareKey}
      />
    </>
  )
}

export default SocialShareImageParent
