import { useState, useEffect, useContext } from 'react'
import EmailCampaignParent from './EmailCampaignParent'
import PageDescription from '../../common/PageDescription'
import PageContainer from '../../common/PageContainer'
import PageHeader from '../../common/PageHeader'
import EmailCampaignStatSummary from './EmailCampaignStatSummary'
import EmailCampaignTabs from './EmailCampaignTabs'
import EmailCampaignTestParent from './EmailCampaignTestParent'

import { Notification } from '../../common/Notification'
import { AuthenticationContext } from '../../authentication/authenticationContext'
import {
  usePermissionHelper,
  EMAIL_CAMPAIGNS,
} from '../../../utils/permission_helper'
import { humanizeError } from '../../../utils/errorFormatter'

import { Dialog, IconButton, useMediaQuery, useTheme, Box } from '@mui/material'
import { styled } from '@mui/system'
import CloseIcon from '@mui/icons-material/Close'

import Api from '../../../services/api'

const UpgradeLink = styled('span')(({ theme }) => ({
  cursor: 'pointer',
  textDecoration: 'underline',
  '&:hover': {
    color: theme.palette.secondary.main,
  },
}))

export const OPT_IN = 'opt_in'
export const BOOKING = 'booking'
export const REFERRAL = 'referral'
export const EMAIL = OPT_IN

const initialTestEmailProps = {
  email: '',
  offers: [],
}

const EmailCampaignSettings = () => {
  const [emailCampaigns, setEmailCampaigns] = useState([])
  const [replyToEmail, setReplyToEmail] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [activeTab, setActiveTab] = useState('booking')
  const [currentCampaign, setCurrentCampaign] = useState('booking')
  const [selectedOffer, setSelectedOffer] = useState('')
  const [testEmailProps, setTestEmailProps] = useState(initialTestEmailProps)
  const [testModalOpen, setTestModalOpen] = useState(false)

  const { canUseEmailCampaigns } = usePermissionHelper()
  const { toggleUpgradeModal, authData, updatePermission, setNotification } =
    useContext(AuthenticationContext)

  const { findPermission } = usePermissionHelper()
  const leadsPermissionData = findPermission(EMAIL_CAMPAIGNS)
  const { consumedPercentage, limit, isBelowLimit, unlimitedUsage } =
    leadsPermissionData
  const reachedEmailAlertThreshold = consumedPercentage() >= 90
  const { user } = authData
  const {
    membership: { isTrialing, shouldShowUpgrades },
  } = user
  const showUpgradeButton =
    isTrialing || (!unlimitedUsage && shouldShowUpgrades)

  const bookingCampaign = emailCampaigns.find(
    (campaign) => campaign.target === BOOKING
  )
  const referralCampaign = emailCampaigns.find(
    (campaign) => campaign.target === REFERRAL
  )
  const optInCampaign = emailCampaigns.find(
    (campaign) => campaign.target === OPT_IN
  )

  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))

  useEffect(() => {
    const getEmailCampaigns = async () => {
      try {
        const res = await Api.fetchEmailCampaigns()

        if (!res.errors) {
          setEmailCampaigns(res.campaigns)
          setReplyToEmail(res.replyToEmail)
          setTestEmailProps({
            email: user.email,
            offers: res.offers,
          })

          // Update permission values for upgrade widget
          const emailCampaignsPermission = res.emailCampaignsPermission
          updatePermission(emailCampaignsPermission)

          setIsLoading(false)
        } else {
          throw res.errors
        }
      } catch (err) {
        setIsLoading(false)
      }
    }

    getEmailCampaigns()
  }, [])

  useEffect(() => {
    switch (activeTab) {
      case BOOKING:
        setCurrentCampaign(bookingCampaign)
        break
      case REFERRAL:
        setCurrentCampaign(referralCampaign)
        break
      case EMAIL:
        setCurrentCampaign(optInCampaign)
        break
      default:
        setCurrentCampaign(bookingCampaign)
    }
  }, [activeTab, bookingCampaign, referralCampaign, optInCampaign])

  const toggleCampaignActive = async (campaignToUpdateId, toggleValue) => {
    if (!canUseEmailCampaigns()) {
      return toggleUpgradeModal(
        true,
        'Upgrade now to send automated emails to your audience!',
        EMAIL_CAMPAIGNS
      )
    }

    try {
      const res = await Api.updateEmailCampaign(campaignToUpdateId, {
        is_active: toggleValue,
      })

      if (res.errors) {
        throw res.errors
      } else {
        setEmailCampaigns(
          emailCampaigns.map((emailCampaign) =>
            emailCampaign.id === res.id
              ? { ...emailCampaign, isActive: res.isActive }
              : { ...emailCampaign }
          )
        )
      }
    } catch (err) {
      // handle error here
    }
  }

  const sendTestEmail = async (selectedOfferId, email) => {
    setIsLoading(true)

    try {
      const res = await Api.sendTestCampaignEmail(currentCampaign.id, {
        email: email,
        survey_offer_id: selectedOfferId,
      })

      if (res.errors) {
        throw res.errors
      } else {
        setIsLoading(false)
        setNotification('Email successfully sent!', 'success')
        return { success: true }
      }
    } catch (err) {
      setIsLoading(false)

      return { error: humanizeError(err) }
    }
  }

  const openTestModal = () => {
    setTestModalOpen(true)
  }

  const closeTestModal = () => {
    setTestModalOpen(false)
  }

  return (
    <PageContainer>
      <PageHeader
        header="Configure Email Followup"
        showUpgradeButton={showUpgradeButton}
      />
      <PageDescription>
        You’ve got the leads. Now let’s make sure you are nurturing them! On all
        Talkadot Pro+ plans, we can automatically send followup emails for you
        after every talk.{' '}
        <a
          href="https://talk.talkadot.com/c/resources/talkadot-automated-email-campaigns-help-article"
          target="_blank"
          rel="noreferrer">
          Learn More
        </a>
        .
      </PageDescription>

      {/* Only show this notice for people who can't use email campaigns */}
      {!canUseEmailCampaigns() && (
        <Notification variant="redWarning">
          Automatic emails sent to your audience members is a Pro+ feature.{' '}
          <UpgradeLink
            onClick={() => {
              toggleUpgradeModal(
                true,
                'Upgrade to send these automatic emails to your audience!'
              )
            }}>
            {' '}
            Upgrade to turn these on!
          </UpgradeLink>
        </Notification>
      )}

      {/* Only show this notice for people who can use email campaigns and are approaching their limit */}
      {canUseEmailCampaigns() && reachedEmailAlertThreshold && (
        <Notification variant="redWarning">
          You are limited to {limit} emails on your current plan.{' '}
          <UpgradeLink
            onClick={() => {
              toggleUpgradeModal(
                true,
                'Upgrade to increase your email campaign sending limit!'
              )
            }}>
            {' '}
            Upgrade to increase your limit.
          </UpgradeLink>
        </Notification>
      )}
      <EmailCampaignStatSummary
        emailCampaigns={emailCampaigns}
        bookingCampaign={bookingCampaign}
        referralCampaign={referralCampaign}
        optInCampaign={optInCampaign}
        unlimitedUsage={unlimitedUsage}
        isBelowLimit={isBelowLimit}
        canUseEmailCampaigns={canUseEmailCampaigns}
      />
      <EmailCampaignTabs
        setActiveTab={setActiveTab}
        emailCampaigns={emailCampaigns}
        bookingCampaign={bookingCampaign}
        referralCampaign={referralCampaign}
        optInCampaign={optInCampaign}
      />
      <EmailCampaignParent
        isLoading={isLoading}
        toggleCampaignActive={toggleCampaignActive}
        campaign={currentCampaign}
        replyToEmail={replyToEmail}
        setReplyToEmail={setReplyToEmail}
        openTestModal={openTestModal}
      />
      <Dialog
        open={testModalOpen}
        fullScreen={fullScreen}
        fullWidth
        maxWidth="sm"
        onClose={closeTestModal}>
        <Box position="absolute" top={0} right={0}>
          <IconButton onClick={closeTestModal}>
            <CloseIcon />
          </IconButton>
        </Box>
        <EmailCampaignTestParent
          testEmailProps={testEmailProps}
          handleClose={closeTestModal}
          sendTestEmail={sendTestEmail}
          isLoading={isLoading}
        />
      </Dialog>
    </PageContainer>
  )
}

export default EmailCampaignSettings
