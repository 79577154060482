import { useEffect, useState, useContext, useRef } from 'react'
import { useParams, useHistory, useLocation } from 'react-router-dom'
import { styled } from '@mui/system'
import { useMediaQuery, Typography } from '@mui/material'

import CodeBuilderQuestion from './CodeBuilderQuestion'
import CodeBuilderNavigation from './CodeBuilderNavigation'
import CodeBuilderPreviewPaneParent from './CodeBuilderPreviewPaneParent'
import CodeBuilderUpgradeNotification from './CodeBuilderUpgradeNotification'
import CodeBuilderAddCodeOnboarding from './CodeBuilderAddCodeOnboarding'
import CodeBuilderQuestionDisabledNotification, {
  FirstTimeToggleEnabledNotification,
} from './CodeBuilderQuestionDisabledNotification'
import PageHeader from '../../common/PageHeader'
import PageContainer from '../../common/PageContainer'
import LoadingSpinner from '../../common/LoadingSpinner'
import ConfirmDialog from '../../common/ConfirmDialog'
import { Notification } from '../../common/Notification'

import { ReactComponent as ArrowRight } from '../../../icons/arrowRight.svg'
import { ReactComponent as DownloadIcon } from '../../../icons/downloadIcon_16x16.svg'

import { AuthenticationContext } from '../../authentication/authenticationContext'
import Api from '../../../services/api'
import { downloadSlideAsPng } from '../../common/helpers'

import { randomCodeString } from '../../../utils/string'
import { humanizeError } from '../../../utils/errorFormatter'

import {
  usePermissionHelper,
  CUSTOMIZATIONS,
} from '../../../utils/permission_helper'

const CodeBuilderContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(5),
  [theme.breakpoints.down('md')]: {
    gap: theme.spacing(0),
  },
  [theme.breakpoints.down('sm')]: {
    margin: theme.spacing(0, -3),
  },
}))

const QuestionContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  height: 'min-content',
  padding: theme.spacing(5, 2.5),
  backgroundColor: theme.palette.base.white,
}))

const PreviewPaneContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'activeTab',
})(({ activeTab }) => ({
  position: 'sticky',
  top: '164px',
  height: 'calc(100vh - 260px)',
  maxHeight: activeTab === 1 ? '655px' : '',
  overflowY: 'hidden',
}))

const MultiQuestionContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(4.5),
}))

const LoadingSpinnerContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  height: '50vh',
  width: '100%',
})

const initialQuestionState = {
  questionType: '',
}

export const codeQuestionState = {
  id: 'code',
  questionType: 'code',
  enabled: true,
  sortable: false,
  canDisable: false,
  hidePreview: true,
  config: {
    previewName: 'General Setup',
    questionTypeDescription: 'Setup Code',
  },
}

export const bonusQuestionState = {
  id: 'bonus',
  questionType: 'bonus',
  enabled: true,
  sortable: false,
  canDisable: false,
  config: {
    previewName: 'Bonus and Socials',
    questionTypeDescription: 'Bonus Uploader',
  },
}

export const downloadSlideQuestionState = {
  id: 'downloadSlide',
  questionType: 'downloadSlide',
  enabled: true,
  sortable: false,
  canDisable: false,
  config: {
    previewName: 'Download Slide',
    questionTypeDescription: 'Download Slide',
  },
}

const bookingLinkQuestionState = {
  id: 'bookingLink',
  questionType: 'bookingLink',
  enabled: true,
  hidePreview: true,
  config: {
    questionTypeDescription: 'Calendar Booking',
  },
}

const socialLinksQuestionState = {
  id: 'socialLinks',
  questionType: 'socialLinks',
  enabled: true,
  hidePreview: true,
  config: {
    questionTypeDescription: 'Social Links',
  },
}

const initialConfirmProps = {
  title: '',
  onConfirm: () => {},
  onCancel: () => {},
  children: '',
}

export const buildModes = {
  QUICK: 'quick',
  ADVANCED: 'advanced',
}

const CodeBuilderParent = () => {
  const [surveyOffer, setSurveyOffer] = useState({})
  const [code, setCode] = useState('')
  const [bonusUrl, setBonusUrl] = useState('')
  const [description, setDescription] = useState('')
  const [questions, setQuestions] = useState([])
  const [availableQuestionTypes, setAvailableQuestionTypes] = useState([])
  const [currentQuestion, setCurrentQuestion] = useState(initialQuestionState)
  const [initialQuestionContent, setInitialQuestionContent] = useState('')
  const [initialAnswersContent, setInitialAnswersContent] = useState([])
  const [currentQuestionId, setCurrentQuestionId] = useState('')
  const [surveyOfferLoading, setSurveyOfferLoading] = useState(false)
  const [questionLoading, setQuestionLoading] = useState(false)
  const [downloadSlideLoading, setDownloadSlideLoading] = useState(false)
  const [slideDownloaded, setSlideDownloaded] = useState(false)
  const [createCodeSkip, setCreateCodeSkip] = useState(false)
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false)
  const [confirmProps, setConfirmProps] = useState(initialConfirmProps)
  const [activeTab, setActiveTab] = useState(1)
  const [buildMode, setBuildMode] = useState('')
  const [uiDisabled, setUiDisabled] = useState(false)
  const [uncaughtError, setUncaughtError] = useState('')
  const { surveyOfferId } = useParams()
  const history = useHistory()
  const location = useLocation()
  const slideRef = useRef()

  const isLargeScreen = useMediaQuery((theme) => theme.breakpoints.up('lg'))
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('md'))
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'))

  const { authData, updateSurveyCustomQuestionCount, updatePermission } =
    useContext(AuthenticationContext)
  const { user, surveyCustomQuestionCount } = authData

  const { findPermission } = usePermissionHelper()
  const customizationPermission = findPermission(CUSTOMIZATIONS)

  const offerBonusUrl = (surveyOffer) => {
    const { selectedBonusType, downloadUrl, link } = surveyOffer
    switch (selectedBonusType) {
      case 'file':
        return downloadUrl
      case 'link':
        return link
      default:
        return ''
    }
  }

  useEffect(() => {
    const getCode = async (surveyOfferId) => {
      try {
        setSurveyOfferLoading(true)
        const res = await Api.getCode(surveyOfferId)

        if (!res.errors) {
          const questions = res.questions

          setSurveyOffer(res)
          setQuestions([
            codeQuestionState,
            ...questions,
            bonusQuestionState,
            downloadSlideQuestionState,
          ])
          setCode(res.code)
          setBonusUrl(offerBonusUrl(res))
          setDescription(res.name)

          if (createCodeSkip) {
            setCurrentQuestionId('bonus')
            setCreateCodeSkip(false)
          } else {
            const idOverride = location.state?.idOverride

            setCurrentQuestionId(idOverride || 'code')
          }

          setSurveyOfferLoading(false)
        } else {
          // handle error
          throw res.errors
        }
      } catch (err) {
        setSurveyOfferLoading(false)
        return setUncaughtError(humanizeError(err))
      }
    }

    // if surveyOfferId is not null, fetch the survey offer
    // this means the user is editing an existing survey offer
    if (surveyOfferId && surveyOffer.id !== surveyOfferId) {
      // fetch survey offer
      getCode(surveyOfferId)
      return
    }

    // if surveyOfferId is null, set currentQuestionId to 'code'
    // this means the user is creating a new survey offer
    setCurrentQuestionId('code')
    setSurveyOffer({})
    setQuestions([codeQuestionState])
    setCode(randomCodeString(4))
    setDescription('')
    setActiveTab(1)
  }, [surveyOfferId])

  useEffect(() => {
    const fetchAvailableQuestionTypes = async () => {
      try {
        const res = await Api.getAvailableQuestionTypes()

        if (!res.errors) {
          setAvailableQuestionTypes(res)
        } else {
          throw res.errors
        }
      } catch (err) {
        return setUncaughtError(humanizeError(err))
      }
    }

    fetchAvailableQuestionTypes()
  }, [])

  useEffect(() => {
    const foundQuestion = questions?.find(
      (question) => question.id === currentQuestionId
    )

    if (foundQuestion) {
      setCurrentQuestion(foundQuestion)
      setInitialContent(foundQuestion)
    }
  }, [questions, currentQuestionId])

  // responsible for controlling SurveyCustomQuestionCount state
  useEffect(() => {
    const customQuestionCount = questions.filter(
      (question) => question.config.isTalkadotQuestion === false
    ).length

    if (customQuestionCount !== surveyCustomQuestionCount) {
      updateSurveyCustomQuestionCount(customQuestionCount)
    }
  }, [questions])

  useEffect(() => {
    setBonusUrl(offerBonusUrl(surveyOffer))
  }, [surveyOffer.selectedBonusType])

  useEffect(() => {
    let timeout

    if (questionLoading) {
      // Delay showing the loading state
      timeout = setTimeout(() => setUiDisabled(true), 300)
    } else {
      // Immediately hide the loading state
      setUiDisabled(false)
      clearTimeout(timeout) // Ensure no delayed setShowLoading(true) happens
    }

    return () => clearTimeout(timeout) // Cleanup if loading changes quickly
  }, [questionLoading])

  useEffect(() => {
    return () => {
      // When unmounting reset the count
      updateSurveyCustomQuestionCount(0)
    }
  }, [])

  useEffect(() => {
    setUncaughtError('')
  }, [currentQuestionId])

  const setInitialContent = (question) => {
    setInitialQuestionContent(question.content)
    const initialAnswersContent = question.possibleAnswers
      ? question.possibleAnswers.map(({ id, content }) => ({
          id,
          content,
        }))
      : []
    setInitialAnswersContent(initialAnswersContent)
  }

  const surveyQuestions = questions.filter((q) => Number.isInteger(q.id))

  const handleRefreshQuestionState = (questionResponse) => {
    const updatedQuestions = questions.map((question) => {
      const updatedQuestion = questionResponse.find((q) => q.id === question.id)
      if (updatedQuestion) {
        return updatedQuestion
      }
      return question
    })

    setQuestions(updatedQuestions)
  }

  const orderedSurveyQuestionIds = () => {
    return surveyQuestions.map((question) => question.id)
  }

  const handleUpdateSurveyQuestionState = (updatedSurveyQuestions) => {
    setQuestions([
      questions[0], // code state
      ...updatedSurveyQuestions,
      ...questions.slice(-2), // booking and download slide state
    ])
  }

  const incrementCodeCount = () => {
    const codePermission = findPermission('OFFER_CODE_LIMIT')
    updatePermission({
      ...codePermission,
      consumed: codePermission.consumed + 1,
    })
  }

  const createNewCode = async () => {
    try {
      setSurveyOfferLoading(true)
      const res = await Api.createCode({
        survey_offer: { code, name: description },
      })

      if (!res.errors) {
        incrementCodeCount()
        history.push({
          pathname: `/codes/edit/${res.id}`,
          state: { idOverride: res.firstQuestionId },
        })
      } else {
        // handle error
        throw res.errors
      }
    } catch (err) {
      setSurveyOfferLoading(false)
      setUncaughtError(humanizeError(err))
    }
  }

  const updateCode = async (data) => {
    if (!surveyOffer.id) return

    try {
      setSurveyOfferLoading(true)

      const params = {
        survey_offer: {
          ...data,
        },
      }

      const res = await Api.updateCode(surveyOffer.id, params)

      if (!res.errors) {
        setSurveyOffer(res)

        if (code !== res.code) {
          setCode(res.code)
        }

        const offerBonusUrl = res.downloadUrl || res.link

        if (bonusUrl !== offerBonusUrl) {
          setBonusUrl(offerBonusUrl)
        }

        setSurveyOfferLoading(false)
      } else {
        // handle error
        throw res.errors
      }
    } catch (err) {
      setSurveyOfferLoading(false)
      return setUncaughtError(humanizeError(err))
    }
  }

  const changeQuestionType = async (questionId, data) => {
    setQuestionLoading(true)

    try {
      const params = {
        question: {
          ...data,
        },
      }

      const res = await Api.changeQuestionType(questionId, params)

      if (!res.errors) {
        handleRefreshQuestionState(res.updatedQuestions)
      } else {
        throw res.errors
      }
    } catch (err) {
      setQuestionLoading(false)
      setUncaughtError(humanizeError(err))
    } finally {
      setQuestionLoading(false)
    }
  }

  const repositionQuestion = async (questionId, newIdx, existingQuestions) => {
    setQuestionLoading(true)

    const params = {
      idx: newIdx,
      ordered_question_ids: orderedSurveyQuestionIds(),
    }

    try {
      const res = await Api.repositionQuestion(questionId, params)

      if (!res.errors) {
        handleUpdateSurveyQuestionState(res.questions)
      } else {
        throw res.errors
      }
    } catch (err) {
      // Since we are optimistically updating the question order on
      // drag and drop, if there are any errors we want to reset the question order
      // state back to what it originally was
      handleUpdateSurveyQuestionState(existingQuestions)
      setUncaughtError(humanizeError(err))
    } finally {
      setQuestionLoading(false)
    }
  }

  const setBookingFlowStartUpdateNotification = (updatedQuestion) => {
    const yesAnswer = updatedQuestion.possibleAnswers.find(
      (a) => a.config?.builderDescription === 'Yes'
    )
    const nextQuestion = questions.find(
      (q) => q.id === yesAnswer.nextQuestionId
    )

    setConfirmProps({
      variant: 'info',
      hideDangerMessage: true,
      title: 'Update Booking Flow',
      buttonText: isMobile ? 'Update' : 'Update the followup question',
      buttonColor: 'primary',
      cancelButtonText: isMobile ? 'Cancel' : 'No changes needed',
      textColor: 'white',
      onConfirm: handleNext,
      onCancel: () => {},
      children: (
        <>
          <Typography variant="body1">
            You have changed the booking flow start question to{' '}
            <strong>"{updatedQuestion.content}"</strong>.
          </Typography>
          <br />
          <Typography variant="body1">
            Does the followup question <strong>"{nextQuestion.content}"</strong>{' '}
            still apply?
          </Typography>
          <br />
          <Typography variant="body1">
            If not, please update the followup question to match the new booking
            flow start question.
          </Typography>
        </>
      ),
    })
    setConfirmDialogOpen(true)
  }

  const updateQuestion = async (questionId, data) => {
    const dataContainsContent =
      data.content !== null && data.content !== undefined
    if (dataContainsContent && data.content === initialQuestionContent) {
      return
    }

    if (!dataContainsContent) {
      // We only set this if we are not updating the content column
      setQuestionLoading(true)
    }

    try {
      const params = {
        question: {
          ...data,
        },
      }

      const res = await Api.updateQuestion(questionId, params)

      if (!res.errors) {
        handleRefreshQuestionState(res.updatedQuestions)

        const updatedQuestion = res.updatedQuestions.find(
          (q) => q.id === questionId
        )

        if (
          updatedQuestion.config?.templateIdentifier === 'BOOKING_FLOW_START'
        ) {
          setBookingFlowStartUpdateNotification(updatedQuestion)
        }
      } else {
        // handle error
        throw res.errors
      }
    } catch (err) {
      return setUncaughtError(humanizeError(err))
    } finally {
      if (!dataContainsContent) {
        setQuestionLoading(false)
      }
    }
  }

  const handleCreatePossibleAnswer = async (question) => {
    setQuestionLoading(true)

    try {
      const params = {
        question_id: question.id,
        possible_answer: {},
      }

      const res = await Api.createPossibleAnswer(params)

      if (!res.errors) {
        handleRefreshQuestionState(res.updatedQuestions)
      } else {
        // handle error
        throw res.errors
      }
    } catch (err) {
      setUncaughtError(humanizeError(err))
    } finally {
      setQuestionLoading(false)
    }
  }

  const handleDeletePossibleAnswer = async (possibleAnswerId) => {
    setQuestionLoading(true)

    try {
      const res = await Api.deletePossibleAnswer(possibleAnswerId)

      if (!res.errors) {
        handleRefreshQuestionState(res.updatedQuestions)
      } else {
        // handle error
        throw res.errors
      }
    } catch (err) {
      setUncaughtError(humanizeError(err))
    } finally {
      setQuestionLoading(false)
    }
  }

  const handleAddQuestion = async (questionTypeId) => {
    setQuestionLoading(true)
    setSurveyOfferLoading(true)

    try {
      const insertingAfter = findQuestionInsertingAfter(currentQuestion)
      const insertion_point =
        1 +
        surveyQuestions.findIndex(
          (question) => question.id === insertingAfter.id
        )

      const params = {
        ordered_question_ids: orderedSurveyQuestionIds(),
        idx: insertion_point,
        survey_template_id: surveyOffer.surveyTemplateId,
        question_type_id: questionTypeId,
      }

      const res = await Api.createQuestion(params)

      if (!res.errors) {
        const updatedQuestions = res.questions

        handleUpdateSurveyQuestionState(updatedQuestions)
        setCurrentQuestionId(res.newQuestionId)
      } else {
        throw res.errors
      }
    } catch (err) {
      setUncaughtError(humanizeError(err))
    } finally {
      setSurveyOfferLoading(false)
      setQuestionLoading(false)
    }
  }

  const handleDeleteQuestion = (questionId) => {
    setConfirmProps({
      variant: 'danger',
      hideDangerMessage: false,
      onCancel: () => {},
      showCancel: true,
      cancelButtonText: 'No',
      title: 'Delete Question?',
      buttonText: 'Yes',
      buttonColor: 'error',
      textColor: 'auto',
      onConfirm: () => {
        confirmDeleteQuestion(questionId)
      },
      children: (
        <Typography variant="body1">
          Are you sure you want to delete this question?
        </Typography>
      ),
    })

    setConfirmDialogOpen(true)
  }

  const forceEnableOver18Check = async (question) => {
    setQuestionLoading(true)
    setSurveyOfferLoading(true)

    try {
      const res = await Api.forceEnableOver18Check({
        survey_template_id: surveyOffer.surveyTemplateId,
      })

      if (!res.errors) {
        handleUpdateSurveyQuestionState(res.questions)
      } else {
        throw res.errors
      }
    } catch (err) {
      setUncaughtError(humanizeError(err))
    } finally {
      setSurveyOfferLoading(false)
      setQuestionLoading(false)
    }
  }

  const handleToggleOver18 = async (question, possibleAnswer, enabled) => {
    if (orderedSurveyQuestionIds()[0] === question.id || !enabled) {
      return await updatePossibleAnswer(possibleAnswer.id, { enabled: enabled })
    } else {
      setConfirmProps({
        variant: 'info',
        hideDangerMessage: true,
        title: 'Set as first Question?',
        buttonText: 'Yes',
        buttonColor: 'primary',
        cancelButtonText: 'No',
        textColor: 'white',
        onConfirm: () => {
          forceEnableOver18Check(question.id)
        },
        onCancel: () => {},
        children: (
          <>
            <Typography variant="body1">
              To enable this option, this question needs to be the first
              question in the survey. Do you want to set it as the first
              question and enable it?
            </Typography>
          </>
        ),
      })
      setConfirmDialogOpen(true)
    }
  }

  const confirmDeleteQuestion = async (questionId) => {
    setQuestionLoading(true)
    setSurveyOfferLoading(true)

    try {
      const params = {
        ordered_question_ids: orderedSurveyQuestionIds(),
      }

      const res = await Api.deleteQuestion(questionId, params)

      if (!res.errors) {
        const updatedQuestions = res.questions

        setCurrentQuestionId(
          // If there's no question ID returned, that means this question was at the end
          // of the survey.  Return the last quesiton in the updated questions array instead
          res.newQuestionId || updatedQuestions[updatedQuestions.length - 1]?.id
        )
        handleUpdateSurveyQuestionState(updatedQuestions)
      } else {
        throw res.errors
      }
    } catch (err) {
      setUncaughtError(humanizeError(err))
    } finally {
      setSurveyOfferLoading(false)
      setQuestionLoading(false)
    }
  }

  const updatePossibleAnswer = async (possibleAnswerId, data) => {
    const dataContainsContent =
      data?.content !== null && data.content !== undefined

    if (dataContainsContent) {
      const initialAnswerContent = initialAnswersContent.find(
        (answer) => answer.id === possibleAnswerId
      )?.content

      if (initialAnswerContent === data.content) return
    }

    if (!dataContainsContent) {
      // We only set this if we are not updating the content column
      setQuestionLoading(true)
    }

    try {
      const params = {
        possible_answer: {
          ...data,
        },
      }

      const res = await Api.updatePossibleAnswer(possibleAnswerId, params)

      if (!res.errors) {
        handleRefreshQuestionState(res.updatedQuestions)
        setQuestionLoading(false)

        // if the possible answer belongs to the first booking question
        // open the notification to update the subsequent booking questions
        const updatedQuestion = res.updatedQuestions.find((q) =>
          q.possibleAnswers?.find((a) => a.id === possibleAnswerId)
        )

        if (
          updatedQuestion.config?.templateIdentifier === 'BOOKING_FLOW_START'
        ) {
          setBookingFlowStartUpdateNotification()
        }
      } else {
        throw res.errors
      }
    } catch (err) {
      setUncaughtError(humanizeError(err))
    } finally {
      if (!dataContainsContent) {
        setQuestionLoading(false)
      }
    }
  }

  const downloadSlideCallback = () => {
    // disable the buttons while the file browser is opening
    setTimeout(() => {
      // set slideDownloaded to true to show the "Finish" button
      setSlideDownloaded(true)
      setDownloadSlideLoading(false)
    }, 500)
  }

  const missingBonus = () => {
    return ['link', 'file'].includes(surveyOffer.selectedBonusType) && !bonusUrl
  }

  const handleBack = () => {
    const currentIndex = questions.findIndex(
      (question) => question.id === currentQuestionId
    )
    // if the question we're about to go back to is not enabled,
    // and it has a groupingIdentifier, skip to the first question
    // with that groupingIdentifier
    const previousQuestion = questions[currentIndex - 1]
    if (
      previousQuestion &&
      !previousQuestion.enabled &&
      Number.isInteger(previousQuestion.config.groupingIdentifier)
    ) {
      const previousQuestions = questions.slice(0, currentIndex)
      const firstQuestionInGroup = previousQuestions.find(
        (question) =>
          question.config.groupingIdentifier ===
          previousQuestion.config.groupingIdentifier
      )

      if (firstQuestionInGroup) {
        setCurrentQuestionId(firstQuestionInGroup.id)
        return
      }
    }

    if (currentIndex > 0) {
      setCurrentQuestionId(questions[currentIndex - 1].id)
    }
  }

  const handleNext = () => {
    // if surveyOffer.id does not exist, create a new survey offer
    if (!surveyOffer.id && currentQuestionId === 'code') {
      if (buildMode === buildModes.QUICK) {
        setCreateCodeSkip(true)
      }
      createNewCode()
    }

    const currentIndex = questions.findIndex(
      (question) => question.id === currentQuestionId
    )

    // if the current question is the bonus question, and the selected bonus type is 'link' or 'file'
    // and the bonusUrl is empty, show an error message
    if (currentQuestionId === 'bonus' && missingBonus()) {
      setConfirmProps({
        variant: 'info',
        hideDangerMessage: true,
        onCancel: () => {},
        showCancel: false,
        title: 'You are missing a bonus',
        buttonText: 'Add bonus',
        buttonColor: 'primary',
        textColor: 'white',
        onConfirm: () => {
          setTimeout(() => {
            window.scrollTo({ top: 0, behavior: 'smooth' })
          }, 200)
        },
        children: (
          <Typography variant="body1">
            You have indicated you want to give away a bonus but have not
            entered a valid link or uploaded a file. Please add a bonus before
            proceeding or select the "I do not want to give away anything"
            option.
          </Typography>
        ),
      })
      setConfirmDialogOpen(true)

      return
    }

    // if currentQuestion is not enabled, and it has a groupingIdentifier
    // skip to the first question where groupingIdentifier does not equal the current question's groupingIdentifier
    if (
      !currentQuestion.enabled &&
      Number.isInteger(currentQuestion.config.groupingIdentifier)
    ) {
      const nextQuestions = questions.slice(currentIndex + 1)
      const nextQuestionNotInGroup = nextQuestions.find(
        (question) =>
          question.config.groupingIdentifier !==
          currentQuestion.config.groupingIdentifier
      )

      if (nextQuestionNotInGroup) {
        setCurrentQuestionId(nextQuestionNotInGroup.id)
        return
      }
    }

    if (currentIndex >= 0 && currentIndex < questions.length - 1) {
      setCurrentQuestionId(questions[currentIndex + 1].id)
    }

    if (currentQuestionId === 'downloadSlide') {
      setDownloadSlideLoading(true)
      downloadSlideAsPng(
        slideRef,
        `Talkadot-Slide-${user.shortlink}-${code}`,
        downloadSlideCallback
      )
    }
  }

  const handleSkip = async () => {
    if (currentQuestionId === 'code' && !surveyOffer.id && buildMode) {
      // go back to the add code onboarding layout
      setBuildMode('')
      return
    }

    // When creating a new code, skip the code question and go straight to the bonus question
    if (currentQuestionId === 'code' && !surveyOffer.id) {
      setCreateCodeSkip(true)
      await createNewCode()
    }

    // When on the download slide question, triggers on "Finish" button click
    if (currentQuestionId === 'downloadSlide') {
      history.push('/codes')
    }
  }

  const questionNumber = () => {
    return (
      questions.findIndex((question) => question.id === currentQuestion.id) + 1
    )
  }

  // Parse the currently selected emoji keywords
  const emojiKeywords = () => {
    const emojiQuestions = questions.filter(
      (question) =>
        question.questionType === 'EMOJI_SCALE' &&
        question.config.isTalkadotQuestion
    )

    return emojiQuestions.map((question) =>
      question.replacementValue.toLowerCase()
    )
  }

  const findQuestionInsertingAfter = (question) => {
    // If this is the first question in a group we treat it like the last question in a group for insertion purposes
    if (
      question.config?.groupingIdentifier !== null &&
      question.config?.groupingIdentifier !== undefined
    ) {
      const grouping = surveyQuestions.filter(
        (q) =>
          q.config.groupingIdentifier === question.config.groupingIdentifier
      )

      if (question.id === grouping[0].id) {
        return grouping[grouping.length - 1]
      }
    }

    return question
  }

  const canInsertQuestion = () => {
    if (!surveyOffer.id) return false

    const insertingAfter = findQuestionInsertingAfter(currentQuestion)

    const surveyQuestionIndex = surveyQuestions.findIndex(
      (q) => q.id === insertingAfter.id
    )

    const insertingAsLastQuestion =
      surveyQuestionIndex === surveyQuestions.length - 1

    const insertingBefore = surveyQuestions[surveyQuestionIndex + 1]

    return (
      insertingAfter?.config?.canQuestionBeInsertedAfter &&
      (insertingAsLastQuestion ||
        insertingBefore?.config?.canQuestionBeInsertedBefore)
    )
  }

  const renderQuestions = () => {
    if (currentQuestionId === 'bonus') {
      return (
        <MultiQuestionContainer>
          <CodeBuilderQuestion
            surveyOffer={surveyOffer}
            question={currentQuestion}
            questionNumber={questionNumber()}
            code={code}
            setCode={setCode}
            updateCode={updateCode}
            setActiveTab={setActiveTab}
          />
          <CodeBuilderQuestion
            surveyOffer={surveyOffer}
            question={bookingLinkQuestionState}
            questionNumber={questionNumber() + 'a'}
            code={code}
            setCode={setCode}
            setActiveTab={setActiveTab}
          />
          <CodeBuilderQuestion
            surveyOffer={surveyOffer}
            question={socialLinksQuestionState}
            questionNumber={questionNumber() + 'b'}
            code={code}
            setCode={setCode}
            setActiveTab={setActiveTab}
          />
        </MultiQuestionContainer>
      )
    }

    return (
      <CodeBuilderQuestion
        surveyOffer={surveyOffer}
        question={currentQuestion}
        questionNumber={questionNumber()}
        updateQuestion={updateQuestion}
        updatePossibleAnswer={updatePossibleAnswer}
        changeQuestionType={changeQuestionType}
        setCurrentQuestion={setCurrentQuestion}
        user={user}
        code={code}
        setCode={setCode}
        description={description}
        setDescription={setDescription}
        updateCode={updateCode}
        surveyOfferLoading={surveyOfferLoading}
        questionLoading={uiDisabled}
        speakerName={user?.first_name}
        emojiKeywords={emojiKeywords()}
        availableQuestionTypes={availableQuestionTypes}
        slideRef={slideRef}
        setActiveTab={setActiveTab}
        canInsertQuestion={canInsertQuestion()}
        handleAddQuestion={handleAddQuestion}
        handleDeleteQuestion={handleDeleteQuestion}
        handleCreatePossibleAnswer={handleCreatePossibleAnswer}
        handleDeletePossibleAnswer={handleDeletePossibleAnswer}
        handleToggleOver18={handleToggleOver18}
      />
    )
  }

  const nextButtonContent = () => {
    if (currentQuestionId === 'downloadSlide') {
      return (
        <>
          <DownloadIcon />
          <span className="button-text">Download</span>
        </>
      )
    }

    return (
      <>
        <span className="button-text">Next</span>
        <ArrowRight />
      </>
    )
  }

  const skipButtonContent = () => {
    if (currentQuestionId === 'downloadSlide') {
      return 'Finish'
    }

    if (currentQuestionId === 'code' && !surveyOffer.id && buildMode) {
      return 'Back'
    }

    if (currentQuestionId === 'code') {
      if (isSmallScreen) {
        return 'Use defaults'
      }

      if (isLargeScreen) {
        return 'I want to use all Talkadot defaults. Go straight to add a bonus'
      }

      return 'Use defaults. Add a bonus'
    }
  }

  const showBuilderSkipButton = () => {
    if (
      (!surveyOffer?.id && currentQuestion.questionType === 'code') ||
      (currentQuestionId === 'downloadSlide' && slideDownloaded)
    ) {
      return true
    }

    return false
  }

  const currentQuestionIsFirstOccuranceOfCanToggleEnabled = () => {
    const firstWithToggleEnabled = questions.find(
      (q) => q.config.canToggleEnable
    )

    return firstWithToggleEnabled.id === currentQuestion.id
  }

  const showFirstTimeToggleEnabledNotification =
    currentQuestion.enabled &&
    currentQuestion.config.canToggleEnable &&
    currentQuestionIsFirstOccuranceOfCanToggleEnabled()

  const renderBuilderContent = () => {
    if (surveyOfferLoading && !currentQuestion?.questionType) {
      return (
        <LoadingSpinnerContainer>
          <LoadingSpinner size={50} />
        </LoadingSpinnerContainer>
      )
    }

    if (currentQuestion?.questionType) {
      return (
        <>
          <QuestionContainer>
            {uncaughtError && (
              <Notification variant="redWarning" maxWidth={true}>
                {uncaughtError}
              </Notification>
            )}
            {showFirstTimeToggleEnabledNotification && (
              <FirstTimeToggleEnabledNotification />
            )}
            {!currentQuestion?.enabled && (
              <CodeBuilderQuestionDisabledNotification
                currentQuestion={currentQuestion}
                questions={questions}
              />
            )}
            {currentQuestion?.config?.alert && (
              <Notification
                variant="subtleWarning"
                hideIcon={true}
                hideClose={true}
                maxWidth={true}>
                {currentQuestion.config.alert}
              </Notification>
            )}
            <CodeBuilderUpgradeNotification
              currentQuestion={currentQuestion}
              customizationPermission={customizationPermission}
            />
            {renderQuestions()}
            <CodeBuilderNavigation
              nextButtonContent={nextButtonContent()}
              skipButtonContent={skipButtonContent()}
              handleBack={handleBack}
              handleNext={handleNext}
              handleSkip={handleSkip}
              showBackButton={currentQuestion.questionType !== 'code'}
              showBuilderSkipButton={showBuilderSkipButton()}
              loading={surveyOfferLoading || uiDisabled || downloadSlideLoading}
            />
          </QuestionContainer>
          <PreviewPaneContainer activeTab={activeTab}>
            <CodeBuilderPreviewPaneParent
              surveyOffer={surveyOffer}
              currentQuestion={currentQuestion}
              setCurrentQuestionId={setCurrentQuestionId}
              currentQuestionNumber={questionNumber()}
              updateQuestion={updateQuestion}
              handleDeleteQuestion={handleDeleteQuestion}
              questions={questions}
              user={user}
              code={code}
              bonusUrl={bonusUrl}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              missingBonus={missingBonus()}
              repositionQuestion={repositionQuestion}
              surveyQuestions={surveyQuestions}
              handleUpdateSurveyQuestionState={handleUpdateSurveyQuestionState}
            />
          </PreviewPaneContainer>
        </>
      )
    }
  }

  const renderPageContent = () => {
    // if the user is creating a new code show the Add Code Onboarding layout
    if (!surveyOfferId && !buildMode) {
      return <CodeBuilderAddCodeOnboarding setBuildMode={setBuildMode} />
    }

    return (
      <>
        <CodeBuilderContainer>{renderBuilderContent()}</CodeBuilderContainer>
        <ConfirmDialog
          open={confirmDialogOpen}
          setOpen={setConfirmDialogOpen}
          onClose={() => {
            setConfirmDialogOpen(false)
          }}
          variant={confirmProps.variant}
          hideDangerMessage={confirmProps.hideDangerMessage}
          onCancel={confirmProps.onCancel}
          showCancel={confirmProps.showCancel}
          cancelButtonText={confirmProps.cancelButtonText}
          title={confirmProps.title}
          buttonText={confirmProps.buttonText}
          buttonColor={confirmProps.buttonColor}
          textColor={confirmProps.textColor}
          onConfirm={confirmProps.onConfirm}>
          {confirmProps.children}
        </ConfirmDialog>
      </>
    )
  }

  return (
    <PageContainer>
      <PageHeader
        header={
          currentQuestionId === 'code' || isMobile || !surveyOffer?.code
            ? 'Your Talkadot Code Setup'
            : `Your Talkadot Code Setup: ${surveyOffer?.code}`
        }
      />
      {renderPageContent()}
    </PageContainer>
  )
}

export default CodeBuilderParent
