import { useState, useCallback } from 'react'

import UploadMediaDropzone, {
  UploadFile,
} from '../../common/UploadMediaDropzone'

import { styled } from '@mui/system'

import { ReactComponent as VideoIcon } from '../../../icons/videoIcon_16x16.svg'

import {
  presignVideoUploadUrl,
  uploadToS3,
  cancelUpload,
} from '../../../utils/fileUpload'

const VideoUploadContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
}))

const UploadFilesContainer = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(2, 1fr)',
  justifyContent: 'center',
  gap: theme.spacing(2),
}))

const videoMimeTypes = {
  'video/*': [],
}

const humanizeFileRejectionError = (errorCode) => {
  switch (errorCode) {
    case 'file-invalid-type':
      return 'Invalid file type. Please upload a valid video file.'
    case 'file-too-large':
      return 'File is too large. Your file has to be less than 10GB.'
    default:
      return 'Sorry, something went wrong uploading your file'
  }
}

const TalkVideoUploader = ({ onUploadComplete, onUploadDelete }) => {
  const [isUploading, setIsUploading] = useState(false)
  const [files, setFiles] = useState([])

  const handleUploadCancel = (fileBeingCancelled) => {
    if (fileBeingCancelled.cancelTokenSource) {
      cancelUpload(fileBeingCancelled.cancelTokenSource)
    }
    setFiles((prevFiles) =>
      prevFiles.filter((file) => file.fileKey !== fileBeingCancelled.fileKey)
    )
  }

  const handleUploadDelete = useCallback(
    (fileBeingDeleted) => {
      setFiles((prevFiles) =>
        prevFiles.filter((file) => file.fileKey !== fileBeingDeleted.fileKey)
      )
      onUploadDelete(fileBeingDeleted)
    },
    [onUploadDelete]
  )

  const uploadFiles = files.map((file) => {
    return (
      <UploadFile
        key={file.fileKey}
        icon={<VideoIcon />}
        fileName={file.file.name}
        fileSize={file.file.size}
        uploading={file.isUploading}
        uploadProgress={file.uploadProgress}
        handleUploadCancel={() => handleUploadCancel(file)}
        handleUploadDelete={() => handleUploadDelete(file)}
      ></UploadFile>
    )
  })

  const handleFileUpload = useCallback(
    async (acceptedFiles) => {
      if (isUploading) {
        return
      }

      if (acceptedFiles.length === 0) {
        return
      }

      setIsUploading(true)

      const uploadPromises = acceptedFiles.map(async (file) => {
        if (!file) {
          return
        }

        try {
          const { name, type } = file
          const presigndRes = await presignVideoUploadUrl({
            fileName: name,
            fileType: type,
          })
          const { presignedUrl, fileKey } = presigndRes
          const fileObj = {
            file,
            fileKey,
            presignedUrl,
            cancelTokenSource: null,
            uploadProgress: 0,
            isUploading: false,
          }

          setFiles((prevFiles) => [...prevFiles, fileObj])

          const setUploadingFileStatus = (isUploading) => {
            setFiles((prevFiles) => {
              // Update state immutably
              return prevFiles.map((fObj) => {
                if (fObj.fileKey === fileKey) {
                  return { ...fObj, isUploading: isUploading } // Create new fileObj
                }
                return fObj // Keep other fileObjs unchanged
              })
            })
          }

          const updateProgress = (progress) => {
            setFiles((prevFiles) => {
              // Update state immutably
              return prevFiles.map((fObj) => {
                if (fObj.fileKey === fileKey) {
                  return { ...fObj, uploadProgress: progress } // Create new fileObj
                }
                return fObj // Keep other fileObjs unchanged
              })
            })
          }

          const setCancelTokenSource = (cancelTokenSource) => {
            setFiles((prevFiles) => {
              // Update state immutably
              return prevFiles.map((fObj) => {
                if (fObj.fileKey === fileKey) {
                  return { ...fObj, cancelTokenSource: cancelTokenSource } // Create new fileObj
                }
                return fObj // Keep other fileObjs unchanged
              })
            })
          }

          await uploadToS3({
            presignedUrl,
            file,
            setUploading: setUploadingFileStatus,
            onUploadProgress: updateProgress,
            setCancelTokenSource,
          })

          onUploadComplete(fileObj)
        } catch (error) {
          console.error('Error uploading file:', error)
          return Promise.resolve()
        }
      })

      // Reset uploading state after all files have their upload states cancelled
      try {
        await Promise.all(uploadPromises)
      } catch (error) {
        console.log(
          'At least one file upload failed, or upload process encountered an error.'
        )
      } finally {
        setIsUploading(false)
      }
    },
    [isUploading, onUploadComplete]
  )
  return (
    <VideoUploadContainer>
      <UploadMediaDropzone
        acceptedMimeTypes={videoMimeTypes}
        isUploading={isUploading}
        maxNumberOfFiles={5}
        maxFileSize={10_737_418_240} // 10 GB limit
        handleFileUpload={handleFileUpload}
        dropzoneHelperContent="Drag and drop a file video file to upload"
        humanizeFileRejectionError={humanizeFileRejectionError}
      />
      <UploadFilesContainer>{uploadFiles}</UploadFilesContainer>
    </VideoUploadContainer>
  )
}

export default TalkVideoUploader
