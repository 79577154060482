import { useState, useEffect, useRef } from 'react'
import { styled } from '@mui/system'

import { useHistory } from 'react-router-dom'

import { ReactComponent as ArrowLeft } from '../../../icons/arrowLeft.svg'

import { InputAdornment } from '@mui/material'
import TitleIcon from '@mui/icons-material/Title'

import SpeechTopicPromptParent from './SpeechTopicPromptParent'
import SpeechTopicOutcomesForm from './SpeechTopicOutcomesForm'
import SpeechTopicVideoUploadParent from './SpeechTopicVideoUploadParent'
import TalkAutocomplete from './TalkAutocomplete'

import { humanizeError } from '../../../utils/errorFormatter'
import { Notification } from '../../common/Notification'

import {
  validateTitle,
  validateDescription,
  validateOutcomes,
  validateDemographics,
  validateSpeechTopic,
  CHARACTER_LIMITS,
} from './speechTopicValidators'
import { addMediaToSignatureTalk } from './helpers'

import {
  MainContainer,
  SecondaryContainer,
  FormContainer,
  NavActionButton,
  NavActionContainer,
  StyledTextField,
  FullFormSection,
  HeaderSubtextContainer,
  CharacterCounter,
} from '../../common/FormComponents'

import Api from '../../../services/api'

const INITIAL_SPEECH_TOPIC_STATE = {
  title: '',
  description: '',
  // Start with 1 empty outcome
  outcomes: [''],
  demographics: [],
}

const INITIAL_ERROR_STATE = {
  title: '',
  description: '',
  outcomes: [],
  demographics: '',
}

const SpeechTopicForm = ({ signatureTalkId, onSuccess }) => {
  const history = useHistory()
  const [speechTopic, setSpeechTopic] = useState(INITIAL_SPEECH_TOPIC_STATE)
  const [isSaving, setIsSaving] = useState(false)
  const [currentStep, setCurrentStep] = useState(1)
  const [errors, setErrors] = useState(INITIAL_ERROR_STATE)
  const [genericError, setGenericError] = useState('')
  const videoUploaderRef = useRef()

  const isEditMode = Boolean(signatureTalkId)

  useEffect(() => {
    const fetchSignatureTalk = async () => {
      try {
        const res = await Api.getSignatureTalk(signatureTalkId)

        if (!res.errors) {
          // If the speech topic is invalid that gets returned
          if (!res.id) {
            return history.push('/talks/speech-topic/new')
          }
          setSpeechTopic(res)
          setCurrentStep(2)

          //TODO: Build UI for res.signature_talk_media
        } else {
          throw res.errors
        }
      } catch (error) {
        setGenericError(humanizeError(error))
      }
    }

    if (signatureTalkId) {
      fetchSignatureTalk()
    }
  }, [history, signatureTalkId])

  const handleSubmit = async () => {
    if (!validateSpeechTopic(speechTopic, errors, setErrors)) {
      return
    }

    setIsSaving(true)
    try {
      const params = {
        signature_talk: speechTopic,
      }

      let res
      if (isEditMode) {
        res = await Api.updateSignatureTalk(signatureTalkId, params)
      } else {
        res = await Api.createSignatureTalk(params)
      }
      if (res.errors) {
        throw res.errors
      }

      const talkId = signatureTalkId || res.id
      // Save any videos we attached to the signature talk
      if (talkId) {
        const mediaUploads = videoUploaderRef.current
          .getFiles()
          .map((fileInfo) => fileInfo.mediaUploadObj)
        if (mediaUploads.length !== 0) {
          try {
            const addMediaRes = await addMediaToSignatureTalk(
              signatureTalkId || res.id,
              mediaUploads
            )
            if (addMediaRes.errors) console.errors(addMediaRes.errors)
          } catch (error) {
            console.errors(error)
          }
        }
      }

      onSuccess?.(res)
    } catch (error) {
      setGenericError(humanizeError(error))
    } finally {
      setIsSaving(false)
    }
  }

  const renderStep = () => {
    switch (currentStep) {
      case 1:
        return (
          <SpeechTopicPromptParent
            speechTopic={speechTopic}
            setSpeechTopic={setSpeechTopic}
            setCurrentStep={setCurrentStep}
            setGenericError={setGenericError}
          />
        )
      case 2:
        return renderMainForm()
      default:
        return renderMainForm()
    }
  }

  const renderTitle = () => (
    <FullFormSection
      title="What is your speech topic title?"
      stepNumber="2"
      caption="This is your public facing title">
      <StyledTextField
        required
        variant="outlined"
        withAdornment={true}
        value={speechTopic.title}
        placeholder="What is the public facing title?"
        fullWidth
        error={!!errors.title}
        helperText={errors.title}
        onBlur={() => validateTitle(speechTopic, errors, setErrors)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <TitleIcon />
            </InputAdornment>
          ),
        }}
        onChange={(e) => {
          setErrors({ ...errors, titleError: '' })
          setSpeechTopic({ ...speechTopic, title: e.target.value })
        }}
      />
    </FullFormSection>
  )

  const renderDescription = () => (
    <FullFormSection title="What is your speech topic about?" stepNumber="3">
      <StyledTextField
        required
        variant="outlined"
        fullWidth
        multiline
        onBlur={() => validateDescription(speechTopic, errors, setErrors)}
        value={speechTopic.description}
        onChange={(e) => {
          setErrors({ ...errors, description: '' })
          setSpeechTopic({ ...speechTopic, description: e.target.value })
        }}
        error={!!errors.description}
        helperText={errors.description}
        minRows={15}
        maxRows={30}
        placeholder="Add a brief description of your talk"
        InputProps={{
          endAdornment: (
            <CharacterCounter
              isError={
                speechTopic.description.length > CHARACTER_LIMITS.DESCRIPTION
              }>
              {speechTopic.description.length}/{CHARACTER_LIMITS.DESCRIPTION}
            </CharacterCounter>
          ),
        }}
      />
    </FullFormSection>
  )

  const renderDemographics = () => (
    <FullFormSection
      title="What audience is this speech topic suitable for?"
      stepNumber="5"
      caption="Select up to 10 demographics that are relevant to this speech topic. You can create your own by typing in the box and pressing enter.">
      <TalkAutocomplete
        speechTopic={speechTopic}
        setSpeechTopic={setSpeechTopic}
        errors={errors}
        setErrors={setErrors}
        validateDemographics={validateDemographics}
      />
    </FullFormSection>
  )

  const renderMainFormActions = () => (
    <NavActionContainer>
      <NavActionButton
        variant="contained"
        onClick={() => setCurrentStep(1)}
        loading={isSaving}>
        <>
          <ArrowLeft />
          <span className="button-text">Back</span>
        </>
      </NavActionButton>
      <NavActionButton
        variant="contained"
        onClick={handleSubmit}
        loading={isSaving}>
        {isEditMode ? 'Update' : 'Create'} Speech Topic
      </NavActionButton>
    </NavActionContainer>
  )

  const renderMainForm = () => (
    <>
      <FormContainer>
        <HeaderSubtextContainer headerSubtext={'Speech topic set up'} />
        {renderTitle()}
        {renderDescription()}
        <SpeechTopicOutcomesForm
          speechTopic={speechTopic}
          setSpeechTopic={setSpeechTopic}
          errors={errors}
          setErrors={setErrors}
          validateOutcomes={validateOutcomes}
        />
        {renderDemographics()}
        <SpeechTopicVideoUploadParent ref={videoUploaderRef} />
      </FormContainer>
      {renderMainFormActions()}
    </>
  )

  return (
    <MainContainer>
      <SecondaryContainer>
        {!!genericError && (
          <Notification variant="error">{genericError}</Notification>
        )}
        {renderStep()}
      </SecondaryContainer>
    </MainContainer>
  )
}

export default SpeechTopicForm
