import TableHeaderSortable from '../common/TableHeaderSortable'

const ProfileEventListHeader = ({ order, orderBy, onRequestSort, loading }) => {
  const headCells = [
    { id: 'date', label: 'Date', paddingLeft: 2 },
    { id: 'eventOrganization', label: 'Organization', displayBreakpoint: 'md' },
    { id: 'talkTitle', label: 'Talk Title' },
    {
      id: 'rating',
      label: 'Valuable',
      align: 'center',
      tooltip: 'Percent who answered yes to if they found your talk valuable.',
      displayBreakpoint: 'lg',
    },
    {
      id: 'responses',
      label: 'Responses',
      align: 'center',
      tooltip:
        'Total number of attendees who answered at least 1 question in the review flow.',
      displayBreakpoint: 'sm',
    },
  ]

  return (
    <TableHeaderSortable
      headCells={headCells}
      order={order}
      orderBy={orderBy}
      onRequestSort={onRequestSort}
      loading={loading}
    />
  )
}

export default ProfileEventListHeader
