import { styled } from '@mui/system'
import { Typography } from '@mui/material'

const HeaderWrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'width',
})(({ width }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: width ? width : 'fit-content',
}))

const HeaderText = styled(Typography)(({ theme }) => ({
  fontWeight: theme.fontWeights.semiBold,
}))

const UnderLine = styled('div')(({ theme }) => ({
  display: 'flex',
  height: '3px',
  background: theme.palette.secondary.main,
}))

export const ProfileSectionHeader = ({ headerText, width }) => {
  return (
    <HeaderWrapper width={width}>
      <HeaderText variant="h5">{headerText}</HeaderText>
      <UnderLine />
    </HeaderWrapper>
  )
}
