import React, { useState, useEffect, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { styled } from '@mui/system'
import { Link } from 'react-router-dom'
import {
  Card,
  CardContent,
  CardActions,
  Typography,
  Button,
  Chip,
  IconButton,
  Box,
} from '@mui/material'

import { AuthenticationContext } from '../../authentication/authenticationContext'
import { Notification } from '../../common/Notification'
import { StyledButton } from '../../common/TableActions'

import PageContainer from '../../common/PageContainer'
import PageHeader from '../../common/PageHeader'
import { ReactComponent as EditIcon } from '../../../icons/editIcon_16x16.svg'
import DeleteIcon from '@mui/icons-material/Delete'
import Api from '../../../services/api'

import ConfirmDialog from '../../common/ConfirmDialog'

const GridContainer = styled('div')(({ theme }) => ({
  padding: theme.spacing(2.5),
  paddingBottom: theme.spacing(6),
  background: theme.palette.base.white,
  borderRadius: theme.shape.borderRadius.xxs,
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  gap: theme.spacing(3),
  justifyContent: 'flex-start',

  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    alignItems: 'center',
  },
}))

const GridItem = styled('div')(({ theme }) => ({
  maxWidth: '400px',
  flexShrink: 0,
  flexGrow: 0,
  width: '100%',

  [theme.breakpoints.down('sm')]: {
    maxWidth: '100%',
  },
}))

const StyledCard = styled(Card)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  transition: 'transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out',
  '&:hover': {
    transform: 'translateY(-4px)',
    boxShadow: theme.shadows[4],
  },
}))

const VideoPlaceholder = styled('div')(({ theme }) => ({
  width: '100%',
  height: '200px',
  backgroundColor: theme.palette.grey[200],
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderBottom: `1px solid ${theme.palette.divider}`,
}))

const TruncatedDescription = styled(Typography)(({ theme }) => ({
  display: '-webkit-box',
  WebkitLineClamp: 3,
  WebkitBoxOrient: 'vertical',
  overflow: 'hidden',
  marginBottom: theme.spacing(2),
}))

const OutcomesContainer = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(2),
}))

const OutcomesList = styled('ul')(({ theme }) => ({
  margin: 0,
  padding: 0,
  paddingLeft: theme.spacing(2),
}))

const OutcomeItem = styled('li')(({ theme }) => ({
  color: theme.palette.text.secondary,
  marginBottom: theme.spacing(0.5),
  '&:last-child': {
    marginBottom: 0,
  },
}))

const OutcomesTitle = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(1),
  color: theme.palette.text.primary,
  fontWeight: theme.fontWeights.medium,
}))

const CardActionsStyled = styled(CardActions)(({ theme }) => ({
  marginTop: 'auto',
  padding: theme.spacing(2),
  justifyContent: 'flex-end',
  gap: theme.spacing(1),
}))

const MainActions = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  marginBottom: theme.spacing(2),
}))

const SpeechTopicList = () => {
  const [talks, setTalks] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [talkToDelete, setTalkToDelete] = useState(null)
  const [showDeleteDialog, setShowDeleteDialog] = useState(false)

  const history = useHistory()
  const { authData } = useContext(AuthenticationContext)
  const {
    user: {
      membership: { shouldShowUpgrades },
    },
  } = authData

  useEffect(() => {
    fetchTalks()
  }, [])

  const fetchTalks = async () => {
    setIsLoading(true)
    try {
      const res = await Api.getSignatureTalks()
      if (!res.errors) {
        setTalks(res)
      }
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }

  const handleCancelDelete = () => {
    setTalkToDelete(null)
    setShowDeleteDialog(false)
  }

  const handleDelete = async () => {
    if (!talkToDelete) return

    try {
      await Api.deleteSignatureTalk(talkToDelete.id)
      fetchTalks() // Refresh the list
      setTalkToDelete(null)
      setShowDeleteDialog(false)
    } catch (error) {
      console.error(error)
    }
  }

  const handleDeleteClick = (talk) => {
    setTalkToDelete(talk)
    setShowDeleteDialog(true)
  }

  return (
    <PageContainer>
      <PageHeader
        header="My Speech Topics"
        showUpgradeButton={shouldShowUpgrades}
      />
      <Notification variant="whiteWarning">
        Speakers often have signature talks or speech topics on particular
        subjects. Set up your speech topics to see grouped insights and so we
        can help cross promote you to relevant events.
      </Notification>
      <MainActions>
        <StyledButton
          variant="contained"
          color="primary"
          onClick={() => history.push('/talks/speech-topic/new')}>
          Add a speech topic
        </StyledButton>
      </MainActions>
      <GridContainer>
        {talks.map((talk) => (
          <GridItem key={talk.id}>
            <StyledCard>
              <VideoPlaceholder>
                <Typography variant="body2" color="textSecondary">
                  Video Coming Soon
                </Typography>
              </VideoPlaceholder>

              <CardContent>
                <Typography variant="h6" gutterBottom>
                  {talk.title}
                </Typography>

                <TruncatedDescription variant="body2" color="textSecondary">
                  {talk.description}
                </TruncatedDescription>

                <OutcomesContainer>
                  <OutcomesTitle variant="body2">
                    Key Learning Outcomes:
                  </OutcomesTitle>
                  <OutcomesList>
                    {talk.outcomes.map((outcome, index) => (
                      <OutcomeItem key={index}>
                        <Typography variant="body2">{outcome}</Typography>
                      </OutcomeItem>
                    ))}
                  </OutcomesList>
                </OutcomesContainer>
              </CardContent>

              <CardActionsStyled>
                <IconButton
                  component={Link}
                  to={`/talks/speech-topic/edit/${talk.id}`}
                  size="small"
                  color="primary">
                  <EditIcon />
                </IconButton>
                <IconButton
                  size="small"
                  color="error"
                  onClick={() => handleDeleteClick(talk)}>
                  <DeleteIcon />
                </IconButton>
              </CardActionsStyled>
            </StyledCard>
          </GridItem>
        ))}
      </GridContainer>

      <ConfirmDialog
        open={showDeleteDialog}
        onClose={() => {
          setShowDeleteDialog(false)
          setTalkToDelete(null)
        }}
        onCancel={handleCancelDelete}
        onConfirm={handleDelete}
        buttonColor="black"
        manualConfirm={true}
        columnLayout={true}
        buttonText="I understand the consequences, delete this speech topic."
        title="Are you sure you want to delete this speech topic?"
        cancelButtonText="Cancel - keep this speech topic"
        confirmText="DELETE SPEECH TOPIC">
        <div>
          You will be deleting {talkToDelete?.title}. Any talks tagged with this
          speech topic will remain the same, but will be untagged from this
          topic.
        </div>
      </ConfirmDialog>
    </PageContainer>
  )
}

export default SpeechTopicList
