import { useState, useContext } from 'react'
import { styled } from '@mui/system'

import {
  Typography,
  Button,
  Dialog,
  useMediaQuery,
  useTheme,
} from '@mui/material'

import UpcomingWebinars, { WEBINAR_TYPES } from './UpcomingWebinars'
import ModalCloseIcon from '../common/ModalCloseIcon'
import { ReactComponent as HeadphoneIcon } from '../../icons/headphoneIcon.svg'
import { ReactComponent as CircleArrowRight } from '../../icons/circledArrowRight.svg'

import { AuthenticationContext } from '../authentication/authenticationContext'

const AlertContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  maxWidth: '850px',
  padding: '16px 24px',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderRadius: theme.shape.borderRadius.sm,
  backgroundColor: theme.palette.tertiary.main,
  marginBottom: theme.spacing(2),
}))

const AlertTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.base.white,
  display: 'flex',
  alignItems: 'center',
}))

const AlertActionButton = styled(Button)(({ theme }) => ({
  height: '32px',
  backgroundColor: theme.palette.base.white,
  borderRadius: theme.shape.borderRadius.xs,
  color: theme.palette.neutral.darkest,
  whiteSpace: 'nowrap',
  '& .MuiButton-startIcon': {
    display: 'none',
    margin: 0,
    svg: {
      path: {
        stroke: theme.palette.neutral.darkest,
      },
    },
  },
  [theme.breakpoints.down('md')]: {
    minWidth: '32px',
    '& .button-text': {
      display: 'none',
    },
    '& .MuiButton-startIcon': {
      display: 'inherit',
    },
  },
}))

const StyledHeadphoneIcon = styled(HeadphoneIcon)(({ theme }) => ({
  marginRight: '8px',
  stroke: theme.palette.base.white,
  path: {
    stroke: theme.palette.base.white,
  },
}))

const AlertContent = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
}))

const AlertDescription = styled(Typography)(({ theme }) => ({
  color: theme.palette.neutral.semiLight,
}))

const WebinarAlert = () => {
  const [open, setOpen] = useState(false)
  const { authData } = useContext(AuthenticationContext)
  const { showWebinarAlert } = authData
  const isMobile = useMediaQuery(useTheme().breakpoints.down('sm'))

  const renderWebinarAlert = () => (
    <AlertContainer>
      <AlertContent>
        <AlertTitle variant="body2">
          <StyledHeadphoneIcon />
          Join an upcoming demo
        </AlertTitle>
        <AlertDescription variant="body1">
          Speakers who join a live demo book more talks. Grab your free spot.
        </AlertDescription>
      </AlertContent>
      <AlertActionButton
        variant="contained"
        startIcon={<CircleArrowRight />}
        onClick={() => setOpen(true)}>
        <span className="button-text">Schedule Now</span>
      </AlertActionButton>
      <Dialog
        fullWidth
        fullScreen={isMobile}
        onClose={() => setOpen(false)}
        open={open}>
        <ModalCloseIcon onClick={() => setOpen(false)} />
        <UpcomingWebinars webinarType={WEBINAR_TYPES.DEMO} />
      </Dialog>
    </AlertContainer>
  )

  return showWebinarAlert && renderWebinarAlert()
}

export default WebinarAlert
