import { useEffect, useContext, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'

import { styled } from '@mui/system'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
  Button,
} from '@mui/material'

import { AuthenticationContext } from '../authentication/authenticationContext'
import Api from '../../services/api'

import { ProfileStatCard } from './ProfileStatCard'
import { ResponsesStatContent } from './ResponsesStatContent'
import { ValuableStatContent } from './ValuableStatContent'
import { ReattendStatContent } from './ReattendStatContent'
import { LocationStatContent } from './LocationStatContent'
import { ProfileAttributes } from './ProfileAttributes'
import ProfileEventList from './ProfileEventList'
import ProfileBiography from './ProfileBiography'
import ProfileSettingsAlert from './ProfileSettingsAlert'
import ProfileHeader from './ProfileHeader'

import PageContainer from '../common/PageContainer'

const ProfileContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(3),
  backgroundColor: theme.palette.base.white,
  [theme.breakpoints.down('sm')]: {
    margin: theme.spacing(0, -3),
  },
}))

const ProfileStatCardsWrapper = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fit, minmax(260px, 1fr))',
  gap: theme.spacing(2),
  marginBottom: theme.spacing(6),
}))

const initialProfileState = {
  events: [],
  totalResponses: {
    total: null,
    positives: null,
    negatives: null,
    id: null,
  },
  reattendBreakdown: {
    total_reattend_answers: null,
    d_yes: null,
    p_yes: null,
    p_no: null,
    d_no: null,
    id: null,
  },
  engagementBreakdown: [],
  speaker: {
    avatar: null,
    id: null,
    isEventPlanner: null,
    name: null,
  },
}

const PublicProfileParent = () => {
  const { authData, setNotification, showFullPageLoader, hideFullPageLoader } =
    useContext(AuthenticationContext)
  const [isLoading, setIsLoading] = useState(true)
  const [profileData, setProfileData] = useState(initialProfileState)
  const [open, setOpen] = useState(true)
  const [formValues, setFormValues] = useState({ password: '' })
  const [authorized, setAuthorized] = useState(false)
  const [profileSettings, setProfileSettings] = useState(null)

  const { user } = authData

  const params = useParams()
  const { shortlink } = params

  useEffect(() => {
    const loadProfileSettings = async () => {
      if (!shortlink) {
        return setNotification('Profile not found')
      }

      const reqParams = {
        public_profile: {
          shortlink: params.shortlink,
        },
      }

      try {
        const res = await Api.getPublicProfileSettings(reqParams)

        if (!res.errors) {
          setProfileSettings(res)
          setIsLoading(false)
        } else {
          throw res.errors
        }
      } catch (err) {
        setIsLoading(false)
        setNotification(err)
      }
    }

    loadProfileSettings()
  }, [shortlink])

  useEffect(() => {
    const loadPublicProfileData = async () => {
      if (!params.shortlink) {
        return setNotification('Profile not found')
      }

      const reqParams = {
        public_profile: {
          shortlink: params.shortlink,
        },
      }

      try {
        setIsLoading(true)
        const res = await Api.getPublicProfile(reqParams)

        if (!res.errors) {
          setProfileData(res)
          setIsLoading(false)
        } else {
          throw res.errors
        }
      } catch (err) {
        setIsLoading(false)
        setNotification(err)
      }
    }
    if (profileSettings && !profileSettings?.password_protected) {
      loadPublicProfileData()
    }
  }, [profileSettings])

  useEffect(() => {
    if (isLoading) {
      showFullPageLoader()
    } else {
      hideFullPageLoader()
    }
  }, [isLoading])

  const handleClose = () => {
    setOpen(false)
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target
    setFormValues({
      ...formValues,
      [name]: value,
    })
  }

  const handlePasswordSubmit = async (e) => {
    e.preventDefault()
    const reqParams = {
      public_profile: {
        shortlink: params.shortlink,
        password: formValues.password,
      },
    }
    try {
      setIsLoading(true)
      const res = await Api.getPublicProtectedProfile(reqParams)
      if (!res.errors) {
        setProfileData(res)
        setIsLoading(false)
        setOpen(false)
        setAuthorized(true)
      } else {
        throw res.errors
      }
    } catch (err) {
      setIsLoading(false)
      setNotification(err)
    }
  }

  const locationBreakdown = () => {
    if (!profileData?.events) return

    const locationData = { totalEvents: 0, inPerson: 0, virtual: 0 }

    profileData.events.forEach((event) => {
      locationData.totalEvents += 1

      if (event.in_person) {
        return (locationData.inPerson += 1)
      }
      if (event.virtual) {
        return (locationData.virtual += 1)
      }
      // use in_person default value if both values are nil
      return (locationData.inPerson += 1)
    })

    return locationData
  }

  const unauthenticated = () =>
    !user?.id || user?.id !== profileData?.speaker?.id

  const renderMetaTags = () => (
    <Helmet>
      <meta name="robots" content="noindex" />
    </Helmet>
  )

  const renderProfilePasswordForm = () => (
    <PageContainer>
      {renderMetaTags()}
      <Dialog open={open} onClose={handleClose}>
        <form onSubmit={handlePasswordSubmit}>
          <DialogTitle>Enter Profile Password</DialogTitle>
          <DialogContent>
            <DialogContentText>
              This Talkadot profile is password protected, please enter the
              password here.
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="password"
              name="password"
              label="Password"
              type="password"
              fullWidth
              variant="standard"
              onChange={handleInputChange}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button variant="contained" type="submit" sx={{ color: 'white' }}>
              Submit
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </PageContainer>
  )

  const renderProfileSettingsHeader = () => {
    if (user?.id !== profileData?.speaker?.id) return null

    const isPublic = user?.profileSettings?.is_public
    const title = 'Public Profile Visibility'
    const severity = isPublic ? 'warning' : 'info'
    const visibilityText = isPublic ? 'public' : 'only visible to you'
    const actionText = isPublic ? 'private' : 'public'

    return (
      <ProfileSettingsAlert title={title} severity={severity}>
        Your profile is currently <strong>{visibilityText}</strong>. To make
        your profile {actionText}, toggle it to{' '}
        <strong>{isPublic ? 'Private' : 'Public'}</strong> in your{' '}
        <strong>Profile Settings</strong> on your{' '}
        <Link to={`/account`}>Settings</Link> page.
      </ProfileSettingsAlert>
    )
  }

  const renderProfileData = () => (
    <PageContainer>
      {!profileSettings.search_indexed && renderMetaTags()}
      {renderProfileSettingsHeader()}
      <ProfileContainer>
        <ProfileHeader speaker={profileData?.speaker} />
        <ProfileStatCardsWrapper>
          <ProfileStatCard type="responses">
            <ResponsesStatContent
              totalResponses={profileData?.totalResponses?.total}
              isLoading={isLoading}
            />
          </ProfileStatCard>
          <ProfileStatCard type="valuable">
            <ValuableStatContent
              ratingsBreakdown={profileData?.totalResponses}
              isLoading={isLoading}
            />
          </ProfileStatCard>
          <ProfileStatCard type="reattend">
            <ReattendStatContent
              reattendBreakdown={profileData?.reattendBreakdown}
              isLoading={isLoading}
              speaker={profileData?.speaker}
            />
          </ProfileStatCard>
          <ProfileStatCard type="location">
            <LocationStatContent
              locationBreakdown={locationBreakdown()}
              isLoading={isLoading}
            />
          </ProfileStatCard>
        </ProfileStatCardsWrapper>
        <ProfileAttributes
          speaker={profileData?.speaker}
          engagementBreakdown={profileData?.engagementBreakdown}
        />
        <ProfileBiography authUser={user} speaker={profileData?.speaker} />
        <ProfileEventList
          authUser={user}
          events={profileData?.events}
          eventsLoading={isLoading}
          shortlink={params.shortlink}
        />
      </ProfileContainer>
    </PageContainer>
  )

  const renderPrivateProfileAlert = () => (
    <PageContainer>
      {renderMetaTags()}
      <ProfileSettingsAlert title="Public Profile Visibility" severity="info">
        This profile is <strong>private</strong>.
      </ProfileSettingsAlert>
    </PageContainer>
  )

  const renderProfile = () => {
    if (isLoading) return null

    if (!profileSettings) return <>{renderMetaTags()}</>

    if (profileSettings.password_protected && !authorized) {
      return renderProfilePasswordForm()
    } else if (!profileSettings.is_public && unauthenticated()) {
      return renderPrivateProfileAlert()
    } else {
      return renderProfileData()
    }
  }

  return renderProfile()
}

export default PublicProfileParent
