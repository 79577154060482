import { useState, useEffect, useContext } from 'react'
import { styled } from '@mui/system'
import { Typography, Switch } from '@mui/material'

import { AuthenticationContext } from '../../authentication/authenticationContext'
import { usePermissionHelper } from '../../../utils/permission_helper'

import {
  FormFieldContainer,
  FormLabel,
  SendTestEmailButton,
} from './sharedStyles'
import ConfirmDialog from '../../common/ConfirmDialog'
import InputSection, { TextInput } from '../../common/InputSection'

import Api from '../../../services/api'
import isEmail from 'validator/lib/isEmail'

const SettingsContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '25px',
  alignItems: 'center',
  backgroundColor: theme.palette.base.white,
  gap: theme.spacing(1.5),
}))

const SettingsContainerInner = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  maxWidth: '700px',
  gap: theme.spacing(1.5),
}))

const ToggleContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
})

const EmailTemplateContainer = styled('div')({
  position: 'relative',
})

const EmailPreviewBlock = styled('div')(({ theme }) => ({
  marginTop: '24px',
  marginLeft: '100px',
  [theme.breakpoints.down('sm')]: {
    marginLeft: '0px',
  },
}))

const EmailPreviewContent = styled('div')({
  padding: '15px',
  fontFamily: 'Figtree, sans-serif, !important',
  fontSize: '0.875rem',
})

const EmailTemplateCaption = styled(Typography)(({ theme }) => ({
  color: theme.palette.neutral.main,
  marginLeft: '100px',
  marginTop: '10px',
  marginBottom: '25px',
  [theme.breakpoints.down('sm')]: {
    marginLeft: '0px',
  },
}))

const ToggledOffOverlay = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  opacity: 0.5,
  backgroundColor: theme.palette.base.white,
  color: 'white',
  display: 'flex',
  zIndex: 10,
}))

const ToggleContent = styled(Typography)(({ theme }) => ({
  fontWeight: theme.fontWeights.semiBold,
}))

const HeaderContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
}))

const ConstrainedInputWrap = styled('div')(({ theme }) => ({
  maxWidth: '276px',
  width: '100%',
  [theme.breakpoints.down('sm')]: {
    maxWidth: 'none',
  },
}))

const HeaderInner = styled('div')(({ theme }) => ({
  width: '100%',
  p: {
    maxWidth: '276px',
  },
}))

const ButtonContainer = styled('div')(({ theme }) => ({
  flexShrink: 0,
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  alignItems: 'center',
  alignSelf: 'flex-start',
  marginLeft: 'auto',
  [theme.breakpoints.down('sm')]: {
    order: -1,
    width: '100%',
  },
}))

const StyledSendTestEmailButton = styled(SendTestEmailButton)(({ theme }) => ({
  flexShrink: 0,
  whiteSpace: 'nowrap',
  marginTop: '10px',
  marginLeft: '10px',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    marginTop: 0,
    marginLeft: 0,
  },
}))

const EmailCampaignParent = ({
  campaign,
  toggleCampaignActive,
  replyToEmail,
  setReplyToEmail,
  openTestModal,
}) => {
  const [newReplyToEmail, setNewReplyToEmail] = useState('')
  const [replyToEmailError, setReplyToEmailError] = useState('')
  const [confirmOpen, setConfirmOpen] = useState(false)

  const { canUseEmailCampaigns } = usePermissionHelper()
  // Uncomment when we want to show their limit and consumption for their billing interval
  // const { findPermission } = usePermissionHelper()
  // const planLimit = permission.limit
  // const consumed = permission.consumed

  const { toggleUpgradeModal, authData } = useContext(AuthenticationContext)
  const { user } = authData

  useEffect(() => {
    setNewReplyToEmail(replyToEmail)
  }, [replyToEmail])

  const handleReplyToEmailBlur = () => {
    if (!newReplyToEmail?.length > 0) {
      setNewReplyToEmail(replyToEmail)
      return
    }

    if (replyToEmail === newReplyToEmail) {
      setNewReplyToEmail(replyToEmail)
      return
    }

    if (!canUseEmailCampaigns()) {
      return toggleUpgradeModal(
        true,
        'Upgrade now to use our automated email campaigns!'
      )
    }

    if (!isEmail(newReplyToEmail)) {
      setReplyToEmailError('Please enter a valid email address')
      return
    }

    setConfirmOpen(true)
  }

  const handleOpenTestModal = async () => {
    if (!canUseEmailCampaigns()) {
      return toggleUpgradeModal(
        true,
        'Upgrade now to use our automated email campaigns!'
      )
    }

    openTestModal()
  }

  const handleCancelUpdateReplyToEmail = () => {
    setNewReplyToEmail(replyToEmail)
    setReplyToEmailError('')
  }

  const updateReplyToEmail = async () => {
    try {
      const params = {
        account_setting: {
          reply_to_email: newReplyToEmail,
        },
      }

      const res = await Api.updateAccountSettings(params)

      if (res.errors) {
        throw res.errors
      } else {
        setReplyToEmail(res.replyToEmail)
      }
    } catch (err) {
      // handle error here
      console.log({ err })
    }
  }

  return (
    <SettingsContainer>
      <SettingsContainerInner>
        <ToggleContainer>
          <ToggleContent variant="body2">
            Followup emails sent to leads is currently{' '}
            {campaign?.isActive ? 'on' : 'off'}
          </ToggleContent>
          <Switch
            checked={campaign?.isActive || false}
            onChange={(e) =>
              toggleCampaignActive(campaign.id, e.target.checked)
            }
          />
        </ToggleContainer>
        <Typography>{campaign?.description}</Typography>
        <EmailTemplateContainer>
          {!campaign?.isActive && <ToggledOffOverlay />}
          <HeaderContainer>
            <HeaderInner>
              <FormFieldContainer>
                <FormLabel label="From:" />
                <ConstrainedInputWrap>
                  <InputSection canEdit={false}>
                    <TextInput
                      disabled
                      id="outlined-disabled"
                      value={user?.first_name}
                      variant="outlined"
                      fullWidth
                    />
                  </InputSection>
                </ConstrainedInputWrap>
              </FormFieldContainer>
              <FormFieldContainer>
                <FormLabel label="Reply To:" />
                <ConstrainedInputWrap>
                  <InputSection
                    canEdit={canUseEmailCampaigns()}
                    showError={replyToEmailError?.length > 0}
                    errorMsg={replyToEmailError}>
                    <TextInput
                      id="outlined-disabled"
                      variant="outlined"
                      value={newReplyToEmail}
                      onChange={(e) => {
                        setReplyToEmailError('')
                        setNewReplyToEmail(e.target.value)
                      }}
                      onBlur={handleReplyToEmailBlur}
                      fullWidth
                    />
                  </InputSection>
                </ConstrainedInputWrap>
              </FormFieldContainer>
            </HeaderInner>
            <ButtonContainer>
              <StyledSendTestEmailButton
                variant="contained"
                onClick={handleOpenTestModal}>
                Send test email
              </StyledSendTestEmailButton>
            </ButtonContainer>
          </HeaderContainer>
          <FormFieldContainer>
            <FormLabel label="Subject:" />
            <InputSection canEdit={false}>
              <TextInput
                disabled
                id="outlined-disabled"
                value={campaign?.emailSubject}
                variant="outlined"
                fullWidth
              />
            </InputSection>
          </FormFieldContainer>
          <EmailPreviewBlock>
            <InputSection canEdit={false}>
              <EmailPreviewContent
                dangerouslySetInnerHTML={{
                  __html: campaign?.emailContent,
                }}
              />
            </InputSection>
          </EmailPreviewBlock>
          <EmailTemplateCaption component="div" variant="caption">
            This template cannot be customized right now as we are performing
            optimizations for everyone. If you want to send your own followup,
            we recommend setting up a Speakerflow or Zapier integration.
            <br />
            <br />
            Your calendar booking link is set based on your Talkadot Code.
          </EmailTemplateCaption>
        </EmailTemplateContainer>
        <ConfirmDialog
          title="Confirm Reply-To Email Change"
          open={confirmOpen}
          setOpen={setConfirmOpen}
          variant="info"
          buttonColor="warning"
          onConfirm={updateReplyToEmail}
          onCancel={handleCancelUpdateReplyToEmail}>
          <p>
            You are about to update your reply to email from{' '}
            <b>{replyToEmail}</b> to <b>{newReplyToEmail}</b>.
          </p>
          <p>
            Updating your reply-to email will update it on <i>all</i> of your
            email campaigns.
          </p>
        </ConfirmDialog>
      </SettingsContainerInner>
    </SettingsContainer>
  )
}

export default EmailCampaignParent
