import { useRef } from 'react'
import { styled } from '@mui/system'
import { Button } from '@mui/material'

import SocialShareTemplate from './SocialShareTemplate'
import CustomQuestionCard from './CustomQuestionCard'

import { renderCustomQuestionContent } from './customQuestionRenderer'
import { downloadComponentAsPng } from '../common/helpers'

const SocialShareContainer = styled('div')({})

const DownloadButton = styled(Button)(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(2),
  right: theme.spacing(9),
  color: theme.palette.base.white,
}))

const CustomQuestionSocialShareContent = ({
  question,
  reportData,
  shareKey,
  isEventOwner,
  handleOpenSocialShare,
  handleToggleVisibility,
}) => {
  const ref = useRef()

  return (
    <SocialShareContainer>
      <SocialShareTemplate
        reportData={reportData}
        shareKey={shareKey}
        downloadRef={ref}
        color="blue"
        downloadId="custom-question-share-image">
        <CustomQuestionCard
          question={question}
          isEventOwner={isEventOwner}
          handleOpenSocialShare={handleOpenSocialShare}
          socialShare={true}
          handleToggleVisibility={handleToggleVisibility}>
          {renderCustomQuestionContent(question, true)}
        </CustomQuestionCard>
      </SocialShareTemplate>
      <DownloadButton
        variant="contained"
        color="primary"
        onClick={() =>
          downloadComponentAsPng(ref, `talkadot-custom-question-share`)
        }>
        Click To Download
      </DownloadButton>
    </SocialShareContainer>
  )
}

export default CustomQuestionSocialShareContent
