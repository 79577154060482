import { useEffect, useState } from 'react'
import { styled } from '@mui/system'
import {
  Paper,
  Typography,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Alert,
  AlertTitle,
} from '@mui/material'

import { ProfileSectionHeader } from './ProfileSectionHeader'
import ProfileEventListHeader from './ProfileEventListHeader'
import ProfileEventListRow from './ProfileEventListRow'

import { getComparator } from '../../utils/sort'

const TableWrapper = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(2.5),
  paddingBottom: theme.spacing(6),
}))

// const StyledTableContainer = styled(TableContainer)({
//   maxHeight: '75vh',
// })

const EventListCriteria = styled(Alert)({
  marginTop: '20px',
  textAlign: 'left',
})

const ProfileEventList = ({
  authUser,
  events,
  eventsLoading,
  speaker,
  shortlink,
}) => {
  const [showEventsCriteria, setShowEventsCriteria] = useState(true)
  const [order, setOrder] = useState('desc')
  const [orderBy, setOrderBy] = useState('createdAt')
  const [sortedEvents, setSortedEvents] = useState([])

  useEffect(() => {
    setSortedEvents(events.slice().sort(getComparator(order, orderBy)))
  }, [order, orderBy])

  const headerText = 'Recent Speaking Engagements'

  const renderSkeleton = () => {
    return [1, 2, 3, 4, 5].map((i) => {
      return (
        <TableRow key={i}>
          <TableCell colSpan={13}>
            <Skeleton />
          </TableCell>
        </TableRow>
      )
    })
  }

  const renderEventList = () => {
    return sortedEvents.map((event, i) => (
      <ProfileEventListRow key={i} event={event} shortlink={shortlink} />
    ))
  }

  const renderPlaceholder = () => {
    return (
      <Typography variant="subtitle1">
        We're still gathering feedback for this profile!
      </Typography>
    )
  }

  const handleAlertClose = () => {
    setShowEventsCriteria(false)
  }

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  return (
    <>
      <ProfileSectionHeader headerText={headerText} />
      {authUser?.id === speaker?.id && showEventsCriteria && (
        <EventListCriteria severity="info" onClose={handleAlertClose}>
          <AlertTitle>Profile Events Criteria</AlertTitle>
          Your profile is currently showing all events that have a{' '}
          <strong>Talk Title</strong> and <strong>5</strong> or more responses
        </EventListCriteria>
      )}
      <TableWrapper>
        {eventsLoading || events?.length > 0 ? (
          <>
            <TableContainer component={Paper} elevation={0}>
              <Table aria-label="profile talks table" size="small" stickyHeader>
                <ProfileEventListHeader
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  loading={eventsLoading}
                />
                <TableBody>
                  {eventsLoading ? renderSkeleton() : renderEventList()}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        ) : (
          renderPlaceholder()
        )}
      </TableWrapper>
    </>
  )
}

export default ProfileEventList
